'use strict';

import { GLOBAL } from './Global';
const PERMISSION_GROUPS = require('../PermissionsGroups');

const search_and_lists = [];

for (const [key, value] of Object.entries(PERMISSION_GROUPS.SEARCH_AND_LISTS)) {
  search_and_lists.push({
    action_list: value,
    element: key,
  });
}

const SEARCH_AND_LISTS = {
  can: [
    ...GLOBAL.can,
    ...search_and_lists,
  ],
};

export {
  SEARCH_AND_LISTS,
};
