'use strict';

import { GLOBAL } from './Global';
const PERMISSION_GROUPS = require('../PermissionsGroups');

const kam_permissions_list = [];

for (const [key, value] of Object.entries(PERMISSION_GROUPS.KAM)) {
  kam_permissions_list.push({
    action_list: value,
    element: key,
  });
}

const KAM = {
  can: [
    ...GLOBAL.can,
    ...kam_permissions_list,
  ],
};

export {
  KAM,
};
