import Axios from 'axios';
import jwt_decode from 'jwt-decode';

import { responseSuccess, responseError } from '@/repositories/ApiInterceptors';
import { axios_nomination } from '@/plugins/axios';
import configuration from '@/configurations';

class AuthenticationRepository {

  /**
   * @param {String} login
   * @param {String} password
   * @returns {Promise}
   */
  async login(login, password) {
    const result = await axios_nomination
      .post(
        `${configuration.api_host}/v1/authentication/auth/local`,
        {
          login,
          password,
        }
      );

    return result.data;
  }

  async resetPassword(login) {
    const result = await axios_nomination
      .post(
        `${configuration.api_host}/v1/authentication/auth/reset-password`,
        {
          login,
        }
      );

    return result.data;
  }

  async updateCurrentPassword(data) {
    let axios_used;

    if (data.password_expired_token) {
      const axios_custom = Axios.create();

      axios_custom.interceptors.response.use(responseSuccess, responseError);
      axios_custom.interceptors.request.use((config) => ({
        ...config,
        headers: {
          Authorization: `Bearer ${data.password_expired_token}`,
        },
      }));

      axios_used = axios_custom;
    } else {
      axios_used = axios_nomination;
    }

    const result = await axios_used
      .put(
        `${configuration.api_host}/v1/authentication/auth/current/password`,
        data
      );

    return result.data;
  }

  async updatePassword(data) {
    const result = await axios_nomination
      .put(
        `${configuration.api_host}/v1/authentication/auth/password`,
        data
      );

    return result.data;
  }

  async logout() {
    const result = await axios_nomination
      .post(
        `${configuration.api_host}/v1/authentication/auth/logout`,
        {}
      );

    return result.data;
  }

  getTokenLocalStorage() {
    return localStorage.token ? localStorage.token : null;
  }

  setTokenLocalStorage(token) {
    localStorage.token = token;
  }

  removeTokenLocalStorage() {
    localStorage.removeItem('token');
  }

  getEmailLocalStorage() {
    return localStorage.user_email
      ? localStorage.user_email
      : '';
  }

  setEmailLocalStorage(user_email) {
    localStorage.user_email = user_email;
  }

  removeEmailLocalStorage() {
    localStorage.removeItem('user_email');
  }

  getPasswordLocalStorage() {
    return localStorage.user_password
      ? localStorage.user_password
      : '';
  }

  setPasswordLocalStorage(user_password) {
    localStorage.user_password = user_password;
  }

  removePasswordLocalStorage() {
    localStorage.removeItem('user_password');
  }

  isValidLocalStorageToken() {
    const local_token = this.getTokenLocalStorage();

    return this.isValidToken(local_token);
  }

  getTokenFromLocalStorageIfIsValid() {
    const local_token = this.getTokenLocalStorage();

    if (this.isValidToken(local_token)) {
      return local_token;
    }

    return null;
  }

  isValidToken(token) {
    if (token == null || token == undefined) {
      return false;
    }

    const now_timestamp = Math.floor(Date.now() / 1000);
    const decoded_token = this.decodeToken(token);

    return (decoded_token.exp > now_timestamp);
  }

  decodeToken(token) {
    return jwt_decode(token);
  }

}

const authentication_repository = new AuthenticationRepository();

export {
  authentication_repository,
};
