import {
  sequence_repository,
} from '@/repositories';

const state = () => ({
  sequence_target_lists: [],
});

const getters = {
  sequenceTargetLists: (state) => state.sequence_target_lists,
};

const actions = {

  async addTargetLists({ commit }, input) {
    const response = await sequence_repository.addTargetLists(input);

    commit('set_sequence_target_list', response);

    return response;
  },

  async searchTargetLists({ commit }, sequence_id) {
    const response = await sequence_repository.searchTargetLists(sequence_id);

    commit('set_sequence_target_list', response);

    return response;
  },
};

const mutations = {
  set_sequence_target_list(state, result_target_lists) {
    result_target_lists.forEach((result_list) => {

      const existing_list = state.sequence_target_lists.find((state_list) => {
        return state_list.sequence_id === result_list.sequence_id
          && state_list.query_id === result_list.query_id;
      });

      if (existing_list !== undefined) {
        state.sequence_target_lists[
          state.sequence_target_lists.indexOf(existing_list)
        ] = result_list;

      } else {
        state.sequence_target_lists.push(result_list);
      }
    });
  },
};

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
