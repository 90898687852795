export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        
      },
      "fr": {
        "contacts": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["contact"]), _normalize(["contact"]), _normalize(["contacts"])])}
      }
    }
  })
}
