<template>
  <quick-search-img-wrapper
    :src="contact_photo"
    srcset
    lazy-src
    width="48"
    height="48"
    @error="onImageError"
  />
</template>

<script>
import QuickSearchImgWrapper from './QuickSearchImgWrapper';

const not_found_default_logo = require("@/assets/images/dummy/avatar.png");
const not_found_default_logo_f = require("@/assets/images/dummy/avatarF.png");

const contact_photo_url_prefix = 'https://storage.googleapis.com/static-portraits-nomination/mini/';
const contact_photo_extention = 'jpg';

export default {
  name: 'QuickSearchContactPhoto',
  components: {
    QuickSearchImgWrapper,
  },
  props: {
    contact_id: Number,
    civility: Number,
  },
  data() {
    return {
      contact_photo: `${contact_photo_url_prefix}${this.contact_id}.${contact_photo_extention}`,
      display_avatar: true,
    };
  },
  methods: {
    onImageError() {
      this.contact_photo = this.civility === 2
        ? not_found_default_logo_f
        : not_found_default_logo;
    },
  },
};
</script>
