import { createVuetify } from 'vuetify';
import { VDatePicker } from 'vuetify/components/VDatePicker';
import { fr, en } from 'vuetify/locale';

import '@fortawesome/fontawesome-free/css/all.css';
import 'vuetify/styles';

export default createVuetify({
  components: {
    VDatePicker,
  },
  icons: {
    iconfont: 'mdiSvg',
  },
  locale: {
    locale: 'fr',
    fallback: 'en',
    messages: { fr, en },
  },
  ssr: true,
});
