import {
  axios_nomination,
} from '@/plugins/axios';
import configuration from '@/configurations';

class NylasRepository {

  /**
   * @param {Object} input
   *
   * @returns {Promise}
   */
  async createCredentialByCode(input) {
    const {
      code,
      state,
    } = input;

    const result = await axios_nomination.post(
      `${configuration.api_host}/v1/nylas/oauth-create-access-token`,
      {
        code,
        state,
      }
    );

    return result.data;
  }

  /**
   * @param {Object} input
   *
   * @returns {Promise}
   */
  async getUrlForAuthentication(input) {
    const {
      redirect_uri,
      scopes,
    } = input;

    const result = await axios_nomination.get(
      `${configuration.api_host}/v1/nylas/oauth-url`,
      {
        params: {
          redirect_uri,
          scopes,
        },
      }
    );

    return result.data;
  }
}

const nylas_repository = new NylasRepository();

export {
  nylas_repository,
};
