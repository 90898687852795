import {
  file_storage_repository,
} from '@/repositories';

const calls_in_progress = {};

const state = () => ({
  amazon_s3_hash: null,
});

const getters = {
  amazonS3Hash: (state) => state.amazon_s3_hash,
};

const actions = {
  searchAmazonS3Hash({commit}, input) {
    const store_key = 'searchAmazonS3Hash';

    if (calls_in_progress[store_key]) {
      return calls_in_progress[store_key];
    }

    calls_in_progress[store_key] = file_storage_repository
      .searchAmazonS3Hash(input)
      .then((amazon_s3_hash) => {
        commit('set_amazon_s3_hash', amazon_s3_hash);
      })
      .finally(() => {
        delete calls_in_progress[store_key];
      });

    return calls_in_progress[store_key];
  },
  uploadFileToAmazonS3(_, input) {
    return file_storage_repository.uploadFileToAmazonS3(input);
  },
};

const mutations = {
  set_amazon_s3_hash(state, amazon_s3_hash) {
    state.amazon_s3_hash = amazon_s3_hash;
  },
};

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
