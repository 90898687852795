import {
  axios_nomination,
} from '@/plugins/axios';
import configuration from '@/configurations';

class SmartMailingRepository {
  constructor() {
    this.calls_in_progress = {};
  }

  /**
   * @param {Object} input
   *
   * @returns {Promise}
   */
  async searchSenders(input) {
    const {
      organization_id,
    } = input;

    const key = `searchSenders:${btoa(JSON.stringify(input))}`;

    if (this.calls_in_progress[key]) {
      return this.calls_in_progress[key];
    }

    this.calls_in_progress[key] = axios_nomination
      .get(
        `${configuration.api_host}/v1/smart-mailing/senders`,
        {
          params: {
            organization_id,
          },
        }
      )
      .then((response) => response.data)
      .finally(() => {
        delete this.calls_in_progress[key];
      });

    return this.calls_in_progress[key];
  }

  /**
   * @param {Object} input
   *
   * @returns {Promise}
   */
  async createSender(input) {
    const {
      is_default,
      organization_id,
      reply_to_email,
      sender_email_domain_name_part,
      sender_email_local_part,
      sender_name,
    } = input;

    const result = await axios_nomination.post(
      `${configuration.api_host}/v1/smart-mailing/senders`,
      {
        is_default,
        organization_id,
        reply_to_email,
        sender_email_domain_name_part,
        sender_email_local_part,
        sender_name,
      }
    );

    return result.data;
  }

  /**
   * @param {Object} input
   *
   * @returns {Promise}
   */
  async updateSender(input) {
    const {
      is_default,
      reply_to_email,
      sender_email_local_part,
      sender_id,
      sender_name,
    } = input;

    const result = await axios_nomination.patch(
      `${configuration.api_host}/v1/smart-mailing/senders/${sender_id}`,
      {
        is_default,
        reply_to_email,
        sender_email_local_part,
        sender_name,
      }
    );

    return result.data;
  }

  /**
   * @param {Number} sender_id
   *
   * @returns {Promise}
   */
  async deleteSender(sender_id) {
    const result = await axios_nomination
      .delete(`${configuration.api_host}/v1/smart-mailing/senders/${sender_id}`);

    return result.data;
  }

  /**
   * @param {Object} input
   *
   * @returns {Promise}
   */
  async searchCampaigns(input) {
    const {
      id_list,
      limit,
      name,
      offset,
      organization_id,
      organization_id_list,
      owner_user_id_list,
      sender_email_list,
      sender_id_list,
      sent_date_lower_boundary,
      sent_date_upper_boundary,
      sort_list,
      status_id_list,
    } = input;

    const result = await axios_nomination.get(
      `${configuration.api_host}/v1/smart-mailing/campaigns`,
      {
        params: {
          id_list,
          limit,
          name,
          offset,
          organization_id,
          organization_id_list,
          owner_user_id_list,
          sender_email_list,
          sender_id_list,
          sent_date_lower_boundary,
          sent_date_upper_boundary,
          sort_list,
          status_id_list,
        },
      }
    );

    return result.data;
  }

  /**
   * @param {Object} input
   *
   * @returns {Promise}
   */
  async createCampaign(input) {
    const {
      email_html_content,
      email_subject,
      mirror_link,
      mirror_link_text,
      name,
      organization_id,
      owner_user_id,
      sender_id,
      sent_date,
      status_id,
      used_query_id,
    } = input;

    const result = await axios_nomination.post(
      `${configuration.api_host}/v1/smart-mailing/campaigns`,
      {
        email_html_content,
        email_subject,
        mirror_link,
        mirror_link_text,
        name,
        organization_id,
        owner_user_id,
        sender_id,
        sent_date,
        status_id,
        used_query_id,
      }
    );

    return result.data;
  }

  /**
   * @param {Object} input
   *
   * @returns {Promise}
   */
  async updateCampaign(input) {
    const {
      campaign_id,
      editor,
      email_footer,
      email_header,
      email_html_content,
      email_pre_header,
      email_subject,
      mirror_link,
      mirror_link_text,
      name,
      owner_user_id,
      sender_id,
      sent_date,
      status_id,
      topol_template,
      used_query_id,
    } = input;

    const result = await axios_nomination.patch(
      `${configuration.api_host}/v1/smart-mailing/campaigns/${campaign_id}`,
      {
        editor,
        email_footer,
        email_header,
        email_html_content,
        email_pre_header,
        email_subject,
        mirror_link,
        mirror_link_text,
        name,
        owner_user_id,
        sender_id,
        sent_date,
        status_id,
        topol_template,
        used_query_id,
      }
    );

    return result.data;
  }

  /**
   * @param {Number} sender_id
   *
   * @returns {Promise}
   */
  async deleteCampaign(campaign_id) {
    const result = await axios_nomination
      .delete(`${configuration.api_host}/v1/smart-mailing/campaigns/${campaign_id}`);

    return result.data;
  }

  /**
   * @param {Number} bat_id
   *
   * @returns {Promise}
   */
  async sendMailTest(input) {
    const {
      bat_email_id_list,
      campaign_id,
    } = input;

    const result = await axios_nomination
      .post(
        `${configuration.api_host}/v1/smart-mailing/campaigns/${campaign_id}/send-test`,
        {
          bat_email_id_list,
        }
      );

    return result.data;
  }

  /**
   * @param {Object} input
   *
   * @returns {Promise}
   */
  async searchCampaignsStatistics(input) {
    const {
      id_list,
      limit,
      name,
      offset,
      organization_id,
      organization_id_list,
      owner_user_id_list,
      sender_email_list,
      sender_id_list,
      sent_date_lower_boundary,
      sent_date_upper_boundary,
      sort_list,
      status_id_list,
    } = input;

    const result = await axios_nomination.get(
      `${configuration.api_host}/v1/smart-mailing/campaigns/statistics`,
      {
        params: {
          id_list,
          limit,
          name,
          offset,
          organization_id,
          organization_id_list,
          owner_user_id_list,
          sender_email_list,
          sender_id_list,
          sent_date_lower_boundary,
          sent_date_upper_boundary,
          sort_list,
          status_id_list,
        },
      });

    return result.data;
  }

  async searchTargets(input) {
    const {
      campaign_id,
      contact_id_list,
      email_event_list,
      limit,
      offset,
    } = input;

    const key = `searchTargets:${btoa(JSON.stringify(input))}`;

    if (this.calls_in_progress[key]) {
      return this.calls_in_progress[key];
    }

    this.calls_in_progress[key] = axios_nomination
      .post(
        `${configuration.api_host}/v1/smart-mailing/campaigns/${campaign_id}/targets`,
        {
          contact_id_list,
          email_event_list,
          limit,
          offset,
        }
      )
      .then((response) => response.data)
      .finally(() => {
        delete this.calls_in_progress[key];
      });

    return this.calls_in_progress[key];
  }

  /**
   * @param {Number} campaign_id
   * @param {Object} target_id_list
   * @returns {Promise}
   */
  async deleteTargets(campaign_id, target_id_list) {
    const result = await axios_nomination.delete(
      `${configuration.api_host}/v1/smart-mailing/campaigns/${campaign_id}/targets`,
      {
        data: {
          id_list: target_id_list,
        },
      }
    );

    return result.data;
  }

  /**
   * @param {Object} input
   * @returns {Promise}
   */
  async searchDomains(input) {
    const {
      organization_id_list,
    } = input;

    const result = await axios_nomination.get(
      `${configuration.api_host}/v1/smart-mailing/domains`,
      {
        params: {
          organization_id_list,
          is_active: true,
        },
      }
    );

    return result.data;
  }

  /**
   * @param {Number} campaign_id
   * @param {Array} contact_id_list
   * @returns {Promise}
   */
  async checkImportTargets(campaign_id, contact_id_list) {
    const result = await axios_nomination
      .post(
        `${configuration.api_host}/v1/smart-mailing/campaigns/${campaign_id}/check-targets`,
        contact_id_list
      );

    return result.data;
  }

  /**
   *
   * @returns {Promise}
   */
  async searchSettings() {
    const result = await axios_nomination.get(
      `${configuration.api_host}/v1/smart-mailing/settings`,
      {}
    );

    return result.data;
  }

  /**
   * @param {Number} campaign_id
   * @param {Array} contact_id_list
   * @returns {Promise}
   */
  async importTargets(campaign_id, contact_id_list) {
    const result = await axios_nomination
      .post(
        `${configuration.api_host}/v1/smart-mailing/campaigns/${campaign_id}/import-targets`,
        contact_id_list
      );

    return result.data;
  }

  /**
   * @param {Object} input
   *
   * @returns {Promise}
   */
  async updateSettings(input) {
    const {
      email_footer,
      email_header,
    } = input;

    const result = await axios_nomination.patch(
      `${configuration.api_host}/v1/smart-mailing/settings`,
      {
        email_footer,
        email_header,
      }
    );

    return result.data;
  }

  /**
   * @param {Number} campaign_id
   *
   * @returns {Promise}
   */
  async duplicateCampaign(campaign_id) {
    const result = await axios_nomination
      .post(`${configuration.api_host}/v1/smart-mailing/campaigns/${campaign_id}/duplicate`);

    return result.data;
  }

  /**
   * @param {Object} input
   *
   * @returns {Promise}
   */
  async getUsedSenders() {
    const result = await axios_nomination.get(
      `${configuration.api_host}/v1/smart-mailing/used-senders`
    );

    return result.data;
  }

  /**
   * @param {Number} campaign_id
   *
   * @returns {Promise}
   */
  async searchTargetLists(campaign_id) {
    const result = await axios_nomination.get(
      `${configuration.api_host}/v1/smart-mailing/campaigns/${campaign_id}/target-lists`
    );

    return result.data;
  }

  /**
   * @param {Object} input
   *
   * @returns {Promise}
   */
  async addTargetLists(input) {
    const {
      campaign_id,
      query_id_list,
    } = input;

    const result = await axios_nomination
      .post(
        `${configuration.api_host}/v1/smart-mailing/campaigns/${campaign_id}/target-lists`,
        {
          query_id_list,
        }
      );

    return result.data;

  }
}

const smart_mailing_repository = new SmartMailingRepository();

export {
  smart_mailing_repository,
};
