import {
  business_alert_repository,
} from '@/repositories';

const state = () => ({
  news_list: [],
});

const getters = {
  newsList: (state) => state.news_list,
};

const actions = {
  async searchNews({ commit }, params) {
    const response = await business_alert_repository.searchNews(params);

    commit('set_news_list', response.news_list);

    return response.news_list;
  },
};

const mutations = {
  set_news_list(state, news_list) {
    state.news_list = news_list;
  },
};

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
