<template>
  <div>
    <component :is="layout">
      <router-view />
    </component>

    <task-element />
  </div>
</template>

<script>
import TaskElement from '@/components/elements/TaskElement.vue';

export default {
  name: 'App',
  components: {
    TaskElement,
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || 'DefaultLayout');
    },
  },
};
</script>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css?family=Fira+Sans:300,300i,400,400i,500,500i,700,700i&subset=latin-ext');
  @import url('https://fonts.googleapis.com/css?family=Fira+Sans:300,300i,400,400i,500,500i,700,700i&subset=latin-ext');
  @import '@/constants/variables.module.scss';

  @font-face {
    font-family: 'GalanoGrotesque-SemiBold';
    src: url('@/assets/fonts/32CE01_1_0.eot');
    src: url('@/assets/fonts/32CE01_1_0.eot?#iefix') format('embedded-opentype'), url('@/assets/fonts/32CE01_1_0.woff2') format('woff2'), url('@/assets/fonts/32CE01_1_0.woff') format('woff'), url('@/assets/fonts/32CE01_1_0.ttf') format('truetype');
  }

  @font-face {
    font-family: 'GalanoGrotesqueAlt-Light';
    src: url('@/assets/fonts/32CE01_3_0.eot');
    src: url('@/assets/fonts/32CE01_3_0.eot?#iefix') format('embedded-opentype'), url('@/assets/fonts/32CE01_3_0.woff2') format('woff2'), url('@/assets/fonts/32CE01_3_0.woff') format('woff'), url('@/assets/fonts/32CE01_3_0.ttf') format('truetype');
  }

  @font-face {
    font-family: 'GalanoGrotesqueAlt-Medium';
    src: url('@/assets/fonts/32CE01_4_0.eot');
    src: url('@/assets/fonts/32CE01_4_0.eot?#iefix') format('embedded-opentype'), url('@/assets/fonts/32CE01_4_0.woff2') format('woff2'), url('@/assets/fonts/32CE01_4_0.woff') format('woff'), url('@/assets/fonts/32CE01_4_0.ttf') format('truetype');
  }

  html {
    overflow-y: visible !important;
  }

  .v-overlay__content,
  .v-overlay-scroll-blocked:not(html) {
    overflow-y: auto !important;
    --v-scrollbar-offset: 0 !important;

    &::-webkit-scrollbar {
      height: 7px;
      width: 7px;
    }

    &::-webkit-scrollbar-track {
      background: $quartz;
    }

    &::-webkit-scrollbar-thumb {
      background: $blue-haze;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: $amethyst-smoke;
    }
  }

  /* NOMI DESIGN SYSTEM */
  @import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

  .nds-text-h1 {
    color: $haiti;
    font-family: 'DM Sans', sans-serif;
    font-size: 40px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.4px;
  }

  .nds-text-h2 {
    color: $haiti;
    font-family: 'DM Sans', sans-serif;
    font-size: 32px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.32px;
  }

  .nds-text-h3 {
    color: $haiti;
    font-family: 'DM Sans', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.24px;
  }

  .nds-text-h4 {
    color: $haiti;
    font-family: 'DM Sans', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.2px;
  }

  .nds-text-link {
    color: $haiti;
    font-family: 'DM Sans', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.16px;
  }

  .nds-text-p-big {
    color: $haiti;
    font-family: 'DM Sans', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.16px;
  }

  .nds-text-p-big-bold {
    color: $haiti;
    font-family: 'DM Sans', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.16px;
  }

  .nds-text-p {
    color: $haiti;
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.14px;
  }

  .nds-text-p-bold {
    color: $haiti;
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.14px;
  }

  .nds-text-p-small {
    color: $haiti;
    font-family: 'DM Sans', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.12px;
  }

  .nds-text-p-small-bold {
    color: $haiti;
    font-family: 'DM Sans', sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.12px;
  }

  .v-slide-group {
    align-items: center;
  }

  .v-slide-group__prev,
  .v-slide-group__next {
    background: $white;
    border: 1px solid $cornflower-blue;
    border-radius: 50%;
    flex: 0 !important;
    height: 26px;
    min-width: 26px !important;
    width: 26px;

    .v-icon {
      color: $cornflower-blue;
    }
  }
  /* END NOMI DESIGN SYSTEM */

  #app,
  .v-overlay-container {
    font-family: 'Fira Sans';
    font-size: 12px;

    a {
      cursor: pointer;
    }

    p {
      margin-bottom: 16px;
    }

    .checkbox {
      cursor: pointer;
      margin: 0 !important;

      &--no-checkbox {
        .v-selection-control,
        .v-input__control,
        &.v-input {
          flex: 0 0 auto;
        }

        .v-selection-control__wrapper {
          display: none;
        }

        .v-label {
          line-height: inherit;
        }

        .v-input__slot {
          margin-bottom: 0;
        }
      }

      .v-selection-control__input {
        margin-right: 8px;

        &:before {
          opacity: 0 !important;
        }
      }

      .v-ripple__container {
        display: none;
      }

      .v-icon {
        color: $blue-haze;
        opacity: 1;
      }

      label {
        color: $amethyst-smoke;
        opacity: 1;
      }

      .v-selection-control {
        min-height: 0;

        &--dirty,
        &:hover {
          .v-icon {
            color: $cornflower-blue;
          }

          label {
            color: $space-cadet;
          }
        }
      }

      .v-input__details {
        display: none;
      }

      &.v-input--selection-controls {
        margin: 0;
        padding: 0;
      }

      .v-input__slot {
        margin: 0;
      }

      .v-input--selection-controls__input {
        svg {
          fill: $blue-haze;
        }
      }

      label {
        color: $amethyst-smoke;
        font-size: 12px;
        font-weight: 500;
      }

      &.v-selection-control--dirty,
      &:not(.v-input--disabled):hover {
        .v-selection-control__input {
          svg {
            fill: $cornflower-blue;
          }
        }

        label {
          color: $space-cadet;
        }
      }

      &.v-input--is-disabled {
        pointer-events: inherit;
      }

      &.v-input--is-disabled.v-input--selection-controls .v-input__slot,
      &.v-input--is-disabled .v-input--selection-controls__input input[role=checkbox],
      &.v-input--is-disabled .v-input--selection-controls__input + .v-label {
        cursor: not-allowed !important;
        color: $grey-disable;
      }

      .v-input--selection-controls__ripple {
        display: none;
      }

      .v-messages {
        display: none;
      }
    }

    .v-menu__content {
      .v-list {
        padding: 0;

        &-item {
          min-height: auto;
        }
      }
    }

    .btn {
      $p_btn: &;

      align-items: center;
      border-radius: 4px;
      cursor: pointer;
      display: flex;
      font-size: 13px;
      font-weight: 600;
      justify-content: center;
      outline: none;
      padding: 5px 10px;

      &--width-80 {
        width: 80px;
      }

      &--width-100 {
        width: 100px;
      }

      &--width-135 {
        width: 135px;
      }

      &--width-140 {
        width: 140px;
      }

      &--blue {
        background-color: $cornflower-blue;
        border: 1px solid $cornflower-blue;
        color: $white;

        i {
          color: $white;
        }

        &:hover {
          background-color: $han-blue;
          border: 1px solid $han-blue;
        }

        &.btn--disabled {
          background-color: $medium-gray !important;
          border: 1px solid $medium-gray !important;
        }
      }

      &--blue-o {
        background-color: $white;
        border: 1px solid $cornflower-blue;
        color: $cornflower-blue;

        i {
          color: $cornflower-blue !important;
        }

        &:hover {
          border: 1px solid $han-blue;
          color: $han-blue;

          i {
            color: $han-blue;
          }
        }

        &.btn--disabled {
          border: 1px solid $medium-gray !important;
          color: $medium-gray !important;

          i {
            color: $medium-gray;
          }
        }
      }

      &--red {
        background-color: $geraldine;
        border: 1px solid $geraldine;
        color: $white;

        i {
          color: $white;
        }

        &:hover {
          background-color: $burnt-sienna;
          border: 1px solid $burnt-sienna;
        }

        &.btn--disabled {
          background-color: $medium-gray !important;
          border: 1px solid $medium-gray !important;
        }
      }

      &--red-o {
        background-color: $white;
        border: 1px solid $geraldine;
        color: $geraldine;

        i {
          color: $geraldine !important;
        }

        &:hover {
          border: 1px solid $burnt-sienna;
          color: $burnt-sienna;

          i {
            color: $burnt-sienna;
          }
        }

        &.btn--disabled {
          border: 1px solid $medium-gray !important;
          color: $medium-gray !important;

          i {
            color: $medium-gray;
          }
        }
      }

      &--disabled {
        cursor: not-allowed !important;
      }
    }

    textarea {
      border: 1px solid $blue-haze;
      border-radius: 4px;
      display: flex;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 12px;
      padding: 10px;
      width: 100%;

      &:hover {
        border: 1px solid $cornflower-blue;
      }

      &:focus {
        border: 1px solid $cornflower-blue;
        outline: none;
      }
    }
  }

  .invalid-field {
    border: 1px solid red !important;
  }

  .invalid-field-message {
    font-size: 12px;
    margin: 5px 0 !important;
    color: red;
  }

  .tooltip {
    &-step-card {
      background-color: transparent !important;
    }

    &-create-task {
      background-color: $small-grey !important;
      max-width: 300px;
      font-size: 12px;
      opacity: 1 !important;
    }

    &-delete-task {
      background-color: $light-red !important;
      max-width: 300px;
      font-size: 12px;
      opacity: 1 !important;
    }

    &-sequence-add-target {
      background-color: $cornflower-blue !important;
      max-width: 300px;
      font-size: 12px;
      opacity: 1 !important;
      &__info {
        display: inline-block;;
      }
    }

    &-sequence-card {
      opacity: 1 !important;
    }

    &-sequence-email-step {
      max-width: 300px;
      font-size: 12px;
      font-weight: 500;
      opacity: 1 !important;

      &-test {
        color: $white !important;
        background-color: $light-red !important;
      }

      &-delete {
        color: $white !important;
        background-color: $light-red !important;
        &-error {
          background-color: $burnt-sienna !important;
        }
      }
    }

    &-sequence-setting {
      background-color: $cornflower-blue !important;
      max-width: 300px !important;
      font-size: 12px !important;
      opacity: 1 !important;
    }

    &-sequence-steps-in-target-list {
      background-color: $white !important;
      border-radius: 4px;
      box-shadow: 0 2px 8px 0 rgba(19,19,54,0.2);
      padding: 20px 10px;

      &__layout {
        display: flex;
        flex-direction: column;
        gap: 5px;
      }

      .tooltip__title {
        height: 16px;
        color: $amethyst-smoke !important;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 16px;
        text-transform: uppercase;
      }
    }

    &-step-interval-card {
      background-color: $white !important;
      border-radius: 4px;
      box-shadow: 0 2px 8px 0 rgba(19,19,54,0.2);
      color: $blue-zodiac !important;
      opacity: 1 !important;
      padding: 20px 10px;
      width: 300px;
    }

    &-sequence-detail-page {
      max-width: 400px;
      font-size: 12px;
    }
  }

  .v-card {
    &-title {
      padding: 16px 24px 10px !important;
    }

    &-text {
      padding: 0 24px 20px !important;
    }
  }

  ul {
    padding-left: 24px;
  }

  .v-picker__header {
    display: none;
  }
</style>
