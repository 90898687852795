<template>
  <input
    v-model="proxy_value"
    type="text"
    :placeholder="placeholder"
    :style="style"
  >
</template>

<script>
export default {
  name: 'InputTextBoxElement',
  props: {
    error: {
      type: Boolean,
    },
    // eslint-disable-next-line vue/prop-name-casing
    modelValue: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    width: {
      default: '240px',
      type: String,
    },
  },
  emits: [
    'change',
    'update:modelValue',
  ],
  computed: {
    style() {
      let style = `width: ${this.width};`;

      if (this.error) {
        style += 'border: 1px solid red;';
      }

      return style;
    },
    proxy_value: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
        this.$emit('change');
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/constants/variables.module.scss';

input {
  padding: 10px 0 10px 8px;
  height: 12px;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 12px;
  height: 32px;
  border: 1px solid $blue-haze;
  border-radius: 4px;
  margin-right: 12px;
  outline: none;

  &:hover,
  &:focus {
    border-color: $cornflower-blue;
  }
}

::-webkit-input-placeholder {
  color:$amethyst-smoke;
}

::-moz-placeholder {
  color:$amethyst-smoke;
}

::-ms-placeholder {
  color:$amethyst-smoke;
}

::placeholder {
  color:$amethyst-smoke;
}
</style>
