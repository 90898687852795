import { axios_nomination } from '@/plugins/axios';
import configuration from '@/configurations';

class QueryRepository {
  /**
   * @param {Number} user_id
   * @param {Object} criteria
   * @returns {Promise}
   */
  async searchUserQueryList(user_id, criteria = {}) {
    const result = await axios_nomination
      .get(
        `${configuration.api_host}/v1/users/${user_id}/queries`,
        {
          params: criteria,
        }
      );

    return result.data;
  }

  /**
   * @param {Number} user_id
   * @param {Object} criteria
   * @returns {Promise}
   */
  async searchUserQueryListLight(user_id, criteria = {}) {
    const result = await axios_nomination
      .get(
        `${configuration.api_host}/v1/users/${user_id}/queries-light`,
        {
          params: criteria,
        }
      );

    return result.data;
  }

  /**
   * @param {Number} user_id
   * @param {Object} criteria
   * @returns {Promise}
   */
  async readQuery(user_id, query_id) {
    const result = await axios_nomination
      .get(
        `${configuration.api_host}/v1/users/${user_id}/queries/${query_id}`
      );

    return result.data;
  }

  /**
   * @param {Number} user_id
   * @param {Object} criteria
   * @returns {Promise}
   */
  async readQueryByIdList(user_id, query_id) {
    const result = await axios_nomination
      .get(
        `${configuration.api_host}/v1/users/${user_id}/queries`,
        {
          params: {
            id_list: [query_id],
          },
        }
      );

    return result.data;
  }

  /**
   * @param {Object} data
   *
   * @returns {Promise}
   */
  async createQuery(data) {
    const response = await axios_nomination.post(
      `${configuration.api_host}/v2/user/queries`,
      data
    );

    return response.data;
  }

  /**
   * @param {Number} query_id
   * @param {Object} data
   *
   * @returns {Promise}
   */
  async updateQuery(query_id, data) {
    const response = await axios_nomination.patch(
      `${configuration.api_host}/v2/user/queries/${query_id}`,
      data
    );

    return response.data;
  }
}

const query_repository = new QueryRepository();

export {
  query_repository,
};
