'use strict';

const STEP = {
  TYPE : {
    TASK: 1,
    MAIL: 2,
  },
};

const STEP_INTERVAL_EVENT_LIST = [
  {
    name: 'La tâche est crée',
    value: 1,
    attribute: 'task_created',
  },
  {
    name: 'La tâche est finalisée',
    value: 2,
    attribute: 'task_done',
  },
];

const STEP_CONSTANTS = {
  STEP,
  STEP_INTERVAL_EVENT_LIST,
};

export {
  STEP_CONSTANTS,
};
