'use strict';

import { SALES } from './Sales';
const PERMISSION_GROUPS = require('../PermissionsGroups');

const sales_pme_permissions_list = [];

for (const [key, value] of Object.entries(PERMISSION_GROUPS.SALES_PME)) {
  sales_pme_permissions_list.push({
    action_list: value,
    element: key,
  });
}

const SALES_PME = {
  can: [
    ...SALES.can,
    ...sales_pme_permissions_list,
  ],
};

export {
  SALES_PME,
};
