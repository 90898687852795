<template>
  <section class="quick-search-news-child-card">
    <div class="icon icon-speaker">
      <i class="fa fa-microphone" />
    </div>

    <h5 class="title">
      {{ $t('speaker') }}
    </h5>

    <a
      :href="getContactUrl()"
      class="name"
    >
      {{ formatContactName() }}
    </a>{{ buildDescription() }}

    <a
      :href="getCompanyUrl()"
      class="company"
    >
      {{ news_piece?.person?.company_name }}
      <span v-if="news_piece?.person?.company_group_name">({{ news_piece.person.company_group_name }})</span>
    </a>

    <div class="date">
      {{ $t('published_on') }}
      {{ $filters.formatDate(news_piece?.publication_date) }}
    </div>
  </section>
</template>

<script>
import configuration from '@/configurations';

import moment from 'moment';

const TRANSLATION_MAPPING = new Map([
  ['Animation', 'feminine_before_vowel'],
  ['Assemblée', 'feminine_before_vowel'],
  ['Assises', 'plural'],
  ['Atelier', 'feminine_before_vowel'],
  ['Colloque', 'masculine'],
  ['Conférence d\'ouverture', 'feminine_before_consonant'],
  ['Conférence', 'feminine_before_consonant'],
  ['Congrès', 'masculine'],
  ['Convention', 'feminine_before_consonant'],
  ['Débat', 'masculine'],
  ['Démonstration', 'feminine_before_consonant'],
  ['Expo', 'feminine_before_vowel'],
  ['Exposition', 'feminine_before_vowel'],
  ['Foire', 'feminine_before_consonant'],
  ['Formation', 'feminine_before_consonant'],
  ['Forum', 'masculine'],
  ['Inauguration', 'feminine_before_vowel'],
  ['Journée', 'feminine_before_consonant'],
  ['Journées', 'plural'],
  ['Meeting', 'masculine'],
  ['Petit déjeuner débat', 'masculine'],
  ['Petit-déjeuner débat', 'masculine'],
  ['Présentation', 'feminine_before_consonant'],
  ['Remise de prix', 'feminine_before_consonant'],
  ['Rencontre', 'feminine_before_consonant'],
  ['Rendez-vous', 'masculine'],
  ['Réunion', 'feminine_before_consonant'],
  ['Salon', 'masculine'],
  ['Séminaire', 'masculine'],
  ['Soirée', 'feminine_before_consonant'],
  ['Table ronde', 'feminine_before_consonant'],
  ['test CDU modifie', 'masculine'],
  ['Web conférence', 'feminine_before_consonant'],
  ['Webinaire', 'masculine'],
  ['Conférence de clôture', 'feminine_before_consonant'],
]);

export default {
  name: 'QuickSearchSpeakerNewsCard',
  props: {
    news_piece: Object,
  },
  data() {
    return {
      configuration,
    };
  },
  methods: {
    formatContactName() {
      if (this.news_piece?.person?.first_name && this.news_piece?.person?.last_name) {
        return `${this.news_piece.person.first_name} ${this.news_piece.person.last_name}`;
      }

      return this.news_piece?.person?.name;
    },
    getCompanyUrl() {
      return `${this.configuration.desk_v1_host}/account/${this.news_piece.person.company_id}`;
    },
    getContactUrl() {
      return `${this.configuration.desk_v1_host}/contact/${this.news_piece.person.id}/profile`;
    },
    buildDescription() {
      let description = `, ${this.news_piece?.job?.job_name}`;

      if (moment(this.news_piece?.highlight?.start_date)
        .isBefore(moment())
      ) {
        description += ', est intervenu(e) dans le cadre';
      }

      const translation_key = TRANSLATION_MAPPING.get(this.news_piece?.highlight?.type_name);

      if (this.news_piece?.highlight?.name && translation_key) {
        description += ` ${this.$t(translation_key)}`;
        description += ` ${this.news_piece?.highlight?.type_name}`;
        description += ` "${this.news_piece?.highlight?.name}"`;
      }

      description += ` ${this.$t('on')} ${this.$filters.formatDate(this.news_piece?.highlight?.start_date)}.`;

      return description;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/constants/variables.module.scss';

.company {
  color: $azure-blue;
  display: block;
  font-family: 'GalanoGrotesqueAlt-Medium';
  font-size: 12px;
  margin-top: 2px;
  margin-bottom: 0px;
  text-decoration: none;
}

.date {
  color: $light-gray-blue;
  font-family: 'Fira Sans';
  font-size: 12px;
  font-style: italic;
  line-height: 12px;
  padding: 10px 0;
  text-transform: none;
}

.icon {
  align-items: center;
  border-radius: 20px;
  color: #fff;
  display: flex;
  width: 30px;
  flex-grow: 0;
  flex-shrink: 0;
  float:left;
  font-size: 14px;
  height: 30px;
  justify-content: center;
  margin-right: 20px;
}

.icon-speaker {
  background: $emerald-green;
}

.name {
  color: $dark-blue;
  font-weight: 500;
  text-decoration: none;
}

.profile-link {
  color: $steel-blue;
  font-size: 13px;
  line-height: 13px;
  text-decoration: none;
  white-space: nowrap;

  &:hover {
    color: $dark-steel-blue;
  }
}

.profile-link-description {
  border-bottom: 1px solid $steel-blue;

  &:hover {
    border-bottom: 1px solid rgba(255,255,255,0);
  }
}

.profile-link-icon {
  margin-right: 5px;
  transition: transform 0.4s linear;

  &:hover {
    color: $dark-steel-blue;
    transform: translate(2px, 0);
  }
}

.quick-search-news-child-card {
  color: $dark-blue;
  font-size: 13px;
  line-height: 18px;
  flex: 1 1 auto;

  a {
      display: inline-block;
  }
}

.title {
  color: $steel-blue;
  font-family: 'GalanoGrotesque-SemiBold';
  font-size: 12px;
  font-weight: 400;
  margin: 0;
  margin-bottom: 10px;
  line-height: 12px;
  padding: 0;
  text-transform: uppercase;
}
</style>

<i18n src="@/locales/common.json"></i18n>

<i18n src="@/locales/components/elements/quick_search/news/quick-search-speaker-news-card.json"></i18n>
