import {
  sequence_repository,
  task_repository,
} from '@/repositories';

const state = () => ({
  task_type_list: [],
});

const getters = {
  taskTypeList: (state) => state.task_type_list,
};

const mutations = {
  set_task_type_list(state, task_type_list) {
    state.task_type_list = task_type_list;
  },
};

const actions = {
  createTaskForSequence(_, {sequence_id, step}) {
    return sequence_repository
      .createStep(sequence_id, step);
  },
  getTaskTypes({ commit }) {
    return task_repository
      .getTaskTypes()
      .then((response) => {
        const {
          task_type_list,
        } = response;

        commit('set_task_type_list', task_type_list);

        return task_type_list;
      });
  },
};

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
