import moment from 'moment';

import {
  sequence_repository,
} from '@/repositories';

import {
  SEQUENCE_STATUS_CONSTANTS,
} from '@/constants';

const state = () => ({
  criteria_id_list: [],
  criteria_organization_id_list: [],
  criteria_user_id_list: [],
  estimated_end_date: null,
  finish_event_mail_replied: true,
  modification_date: null,
  name: null,
  owner_user_id: null,
  sendable_days: [],
  sendable_end_hour: null,
  sendable_start_hour: null,
  sequence: {},
  sequence_steps: [],
  sequence_settings: [],
  start_date: null,
  status_id: null,
});

const getters = {
  criteriaIdList: (state) => state.criteria_id_list,
  criteriaOrganizationIdList: (state) => state.criteria_organization_id_list,
  criteriaUserIdList: (state) => state.criteria_user_id_list,
  estimatedEndDate: (state) => state.estimated_end_date,
  finishEventMailReplied: (state) => state.finish_event_mail_replied,
  modificationDate: (state) => state.modification_date,
  name: (state) => state.name,
  ownerUserId: (state) => state.owner_user_id,
  sendableDays: (state) => state.sendable_days,
  sendableEndHour: (state) => state.sendable_end_hour,
  sendableStartHour: (state) => state.sendable_start_hour,
  sequence: (state) => state.sequence,
  sequenceSteps: (state) => state.sequence_steps,
  sequenceSettings: (state) => state.sequence_settings,
  startDate: (state) => state.start_date,
  statusId: (state) => state.status_id,
};

const actions = {
  getCriteria({ state, rootState }) {
    const user = rootState.user.token_data;

    return {
      organization_id: user.organization_id,
      organization_id_list: state.criteria_organization_id_list.length > 0
        ? state.criteria_organization_id_list
        : undefined,
      user_id_list: state.criteria_user_id_list.length > 0
        ? state.criteria_user_id_list
        : undefined,
      id_list: state.criteria_id_list.length > 0
        ? state.criteria_id_list
        : undefined,
    };
  },

  async processSearch({ commit, dispatch }) {
    const criteria = await dispatch('getCriteria');
    const sequence = await dispatch('searchSequencesById', criteria);
    const steps = await dispatch('searchSequenceStepsStatistics', criteria);
    const settings = await dispatch('searchSequenceSettings', criteria);

    commit('set_sequence', sequence);
    commit('set_name', sequence.name);
    commit('set_status_id', sequence.status_id);
    commit('set_owner_user_id', sequence.owner_user_id);
    commit('set_sendable_days', sequence.sendable_days);
    commit('set_sendable_start_hour', sequence.sendable_start_hour);
    commit('set_sendable_end_hour', sequence.sendable_end_hour);
    commit('set_finish_event_mail_replied', !!sequence.finish_event_mail_replied);
    commit('set_sequence_steps', steps);
    commit('set_sequence_settings', settings);
    commit('set_modification_date', sequence.modification_date);
    commit('set_start_date', sequence.start_date);

    dispatch('getEstimatedEndDate', sequence.start_date);
  },
  async processUpdateLink(_, {step_link, step_link_id}) {
    const result = await sequence_repository.updateStepLinks(step_link, step_link_id);

    return result;
  },
  async processSearchStepsStatistics({ commit, dispatch }) {
    const criteria = await dispatch('getCriteria');
    const steps = await dispatch('searchSequenceStepsStatistics', criteria);

    commit('set_sequence_steps', steps);
  },
  async searchSequencesById(_, criteria) {
    const result = await sequence_repository.searchSequences(criteria);
    const [sequence] = result.sequence_list ? result.sequence_list : [];

    return sequence;
  },
  async searchSequenceStepsStatistics(_, criteria) {
    const result = await sequence_repository.searchSequenceStepsStatistics(criteria);

    return result.step_list;
  },
  async searchSequenceSettings(_, criteria) {
    const {
      organization_id,
      user_id_list,
    } = criteria;

    const settings_params = {};

    if (organization_id !== undefined) {
      settings_params.organization_id_list = [organization_id];
    }

    if (user_id_list !== undefined) {
      settings_params.user_id_list = user_id_list;
    }

    const result = await sequence_repository.getSettings(settings_params);

    return result.setting_list;
  },
  async updateSequenceRaw(_, sequence_data) {
    await sequence_repository.updateSequence(sequence_data);

  },
  async updateSequence({ commit, dispatch }, sequence_data) {
    const sequence_updated = await sequence_repository.updateSequence(sequence_data);

    commit('set_name', sequence_updated.name);
    commit('set_status_id', sequence_updated.status_id);
    commit('set_owner_user_id', sequence_updated.owner_user_id);
    commit('set_sendable_days', sequence_updated.sendable_days);
    commit('set_sendable_start_hour', sequence_updated.sendable_start_hour);
    commit('set_sendable_end_hour', sequence_updated.sendable_end_hour);
    commit('set_finish_event_mail_replied', !!sequence_updated.finish_event_mail_replied);
    commit('set_modification_date', sequence_updated.modification_date);
    commit('set_start_date', sequence_updated.start_date);

    dispatch('getEstimatedEndDate', sequence_updated.start_date);
  },
  async createStep(_, {sequence_id, step}) {
    const result = await sequence_repository.createStep(sequence_id, step);

    return result;
  },
  async updateStep(_, {sequence_id, step, step_id}) {
    const result = await sequence_repository.updateStep(sequence_id, step, step_id);

    return result;
  },
  async deleteStep(_, {sequence_id, step_id}) {
    const result = await sequence_repository.deleteStep(sequence_id, step_id);

    return result;
  },
  async createStepLink(_, step_link) {
    await sequence_repository.createStepLink(step_link);
  },
  async getSequenceNextSteps(_, {sequence_id}) {
    const sequence_next_steps = await sequence_repository.getSequenceNextSteps(sequence_id);

    return sequence_next_steps;
  },
  async getDaysRemaining({ state, rootState }) {
    if (state.sequence.owner_user_id === null) {
      return 0;
    }

    const {
      sequence_count: sequence_in_progress_count,
    } = await sequence_repository.searchSequences({
      organization_id: rootState.user.token_data.organization_id,
      owner_user_id_list: [state.sequence.owner_user_id],
      limit: 0,
      status_id_list: [SEQUENCE_STATUS_CONSTANTS.ONGOING.ID],
    });

    const sequence_count_to_add = (
      state.sequence.status_id === SEQUENCE_STATUS_CONSTANTS.ONGOING.ID
    )
      ? 0
      : 1;

    const sequence_setting = state.sequence_settings
      .find((setting) => setting.user_id === state.sequence.owner_user_id);

    const daily_threshold = (sequence_setting === undefined)
      ? 0
      : sequence_setting.daily_threshold;

    let interval_list = state.sequence_steps
      .map((step) => step?.link_list[0]?.interval)
      .filter((interval) => Number.isInteger(interval));

    if (interval_list.length === 0) {
      interval_list = [1];
    }

    const sendable_days_count = state.sendable_days.split(',').length;

    const total_interval = interval_list.reduce((acc, num) => acc + num, 0);
    const daily_flow = daily_threshold / (sequence_in_progress_count + sequence_count_to_add);
    const weekly_flow = daily_flow * sendable_days_count;
    const real_daily_flow = weekly_flow / 7;
    const remaining_mail_to_send = state.sequence.mail_to_send_count;

    return (total_interval > (remaining_mail_to_send / real_daily_flow))
      ? Math.ceil(total_interval + (remaining_mail_to_send / real_daily_flow))
      : Math.ceil(remaining_mail_to_send / real_daily_flow);
  },
  async getEstimatedEndDate({ commit, dispatch, state }, start_date) {
    const adjusted_start_date = (start_date && moment(start_date).isAfter(moment()))
      ? moment(start_date)
      : moment();

    const days_remaining = await dispatch('getDaysRemaining');

    const sendable_days = state.sendable_days
      .split(',')
      .map((day) => Number(day));

    let estimated_end_date = adjusted_start_date.add(days_remaining, 'days');
    let day_of_the_week = estimated_end_date.day();

    let secure_index = 1;

    while (!sendable_days.includes(day_of_the_week) && secure_index <= 7) {
      estimated_end_date = adjusted_start_date.add(1, 'days');
      day_of_the_week = estimated_end_date.day();
      secure_index++;
    }

    commit('set_estimated_end_date', estimated_end_date);

    return estimated_end_date;
  },
  async switchSteps(_, { sequence_id, data}) {
    await sequence_repository
      .switchSteps(sequence_id, data);
  },
  async executeRunner(_, { owner_user_id_list }) {
    await sequence_repository
      .executeRunner({
        owner_user_id_list,
      });
  },
};

const mutations = {
  set_criteria_id_list(state, criteria_id_list) {
    state.criteria_id_list = criteria_id_list;
  },
  set_criteria_organization_id_list(state, criteria_organization_id_list) {
    state.criteria_organization_id_list = criteria_organization_id_list;
  },
  set_criteria_user_id_list(state, criteria_user_id_list) {
    state.criteria_user_id_list = criteria_user_id_list;
  },
  set_estimated_end_date(state, estimated_end_date) {
    state.estimated_end_date = estimated_end_date;
  },
  set_finish_event_mail_replied(state, finish_event_mail_replied) {
    state.finish_event_mail_replied = finish_event_mail_replied;
  },
  set_modification_date(state, modification_date) {
    state.modification_date = modification_date;
  },
  set_name(state, name) {
    state.name = name;
  },
  set_owner_user_id(state, owner_user_id) {
    state.owner_user_id = owner_user_id;
  },
  set_sendable_days(state, sendable_days) {
    state.sendable_days = sendable_days;
  },
  set_sendable_end_hour(state, sendable_end_hour) {
    state.sendable_end_hour = sendable_end_hour;
  },
  set_sendable_start_hour(state, sendable_start_hour) {
    state.sendable_start_hour = sendable_start_hour;
  },
  set_sequence(state, sequence) {
    state.sequence = sequence;
  },
  set_sequence_settings(state, sequence_settings) {
    state.sequence_settings = sequence_settings;
  },
  set_sequence_steps(state, sequence_steps) {
    state.sequence_steps = sequence_steps
      .sort((a, b) => a.position - b.position);
  },
  set_start_date(state, start_date) {
    state.start_date = start_date;
  },
  set_status_id(state, status_id) {
    state.status_id = status_id;
  },
};

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
