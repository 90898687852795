'use strict';

const STATUS = {
  IN_PAUSE: {
    ID: 1,
    LABEL: 'en pause',
  },
  PLAY: {
    ID: 2,
    LABEL: 'en cours',
  },
  DONE: {
    ID: 3,
    LABEL: 'terminé',
  },
};

const TARGET_CONSTANTS = {
  STATUS,
};

export {
  TARGET_CONSTANTS,
};
