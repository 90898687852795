import {
  ai_repository,
} from '@/repositories';

const actions = {
  async generatePropositionValue(_, data) {
    return await ai_repository.generatePropositionValue(data);
  },
  async analyzeAccount(_, data) {
    return await ai_repository.analyzeAccount(data);
  },
};

export default {
  actions,
  namespaced: true,
};
