<template>
  <v-app>
    <slot />
  </v-app>
</template>

<script>
export default {
  name: 'LoginLayout',
};
</script>
