import { axios_nomination } from '@/plugins/axios';
import configuration from '@/configurations';

class QuickSearchRepository {
  /**
   * @returns {Promise}
   */
  async getQuickSearchResults(criteria) {
    const result = await axios_nomination
      .get(
        `${configuration.api_host}/v1/quick-search`,
        {
          params: criteria,
        }
      );

    return result.data;
  }
}

const quick_search_repository = new QuickSearchRepository();

export {
  quick_search_repository,
};
