<template>
  <section
    v-if="!contactIsBlindedForUser(contact)"
    class="quick-search-contact-card"
  >
    <figure>
      <a :href="getContactUrl()">
        <quick-search-contact-photo
          :contact_id="contact?.id"
          :civility="contact?.civility"
        />
      </a>
    </figure>

    <div>
      <a
        :href="getContactUrl()"
        class="name"
      >
        {{ contact?.first_name }}
        {{ contact?.last_name }}
      </a>
      <a
        :href="getContactUrl()"
        class="job"
      >
        <span v-if="contact?.active === false">{{ $t('ex') }}</span>
        {{ contact?.job_name }}
      </a>

      <a
        v-if="getCompanyUrl()"
        :href="getCompanyUrl()"
        class="company"
      >
        {{ contact?.company_name }}
        <span v-if="contact?.company_group_name">({{ company_group_name }})</span>
      </a>
    </div>
  </section>

  <section
    v-if="contactIsBlindedForUser(contact)"
    class="quick-search-contact-card"
  >
    <figure>
      <quick-search-contact-photo
        :contact_id="contact?.id"
      />
    </figure>

    <div>
      <span class="name-blinded">
        <span class="name">{{ contact?.first_name }}</span>
        <blinded-row-element width="70px" />
      </span>

      <span class="job">
        <span v-if="contact?.active === false">{{ $t('ex') }}</span>
        {{ contact?.job_name }}
      </span>

      <span
        v-if="getCompanyUrl()"
        class="company"
      >
        {{ contact?.company_name }}
        <span v-if="contact?.company_group_name">({{ company_group_name }})</span>
      </span>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import configuration from '@/configurations';
import BlindedRowElement from '@/components/elements/BlindedRowElement.vue';
import QuickSearchContactPhoto from '@/components/elements/quick_search/cards/images/QuickSearchContactPhoto.vue';

export default {
  name: 'QuickSearchContactCardElement',
  components: {
    BlindedRowElement,
    QuickSearchContactPhoto,
  },
  props: {
    contact: Object,
  },
  data() {
    return {
      configuration,
    };
  },
  computed : {
    ...mapGetters({
      user_has_license_sales_pme: 'user/userHasSalesPmeLicense',
    }),
  },
  methods: {
    contactIsBlindedForUser(contact) {
      return this.user_has_license_sales_pme && !contact?.is_visible_pme;
    },
    getCompanyUrl() {
      if (this.contact?.company_id) {
        return `${this.configuration.desk_v1_host}/account/${this.contact.company_id}`;
      }

      return null;
    },
    getContactUrl() {
      return `${this.configuration.desk_v1_host}/contact/${this.contact.id}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/constants/variables.module.scss';

.company {
  color: $azure-blue;
  display: block;
  font-family: 'GalanoGrotesqueAlt-Medium';
  font-size: 12px;
  line-height: 12px;
  margin-bottom: 0px;
  text-decoration: none;
}

.job {
  color: $dark-blue;
  display: block;
  margin-bottom: 4px;
  font-family: 'GalanoGrotesqueAlt-Light';
  font-size: 14px;
  line-height: 14px;
  text-decoration: none;
}

.name {
  align-items: center;
  color: $dark-blue;
  display: flex;
  font-family: 'GalanoGrotesqueAlt-Light';
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 4px;
  text-decoration: none;
}

.name-blinded {
  display: flex;
  gap: 20px;
  align-items: baseline;
}

.quick-search-contact-card {
  border: 1px solid $light-capuccino;
  border-radius: 3px;
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
  padding: 15px;
  width: 100%;
}
</style>

<i18n src="@/locales/common.json"></i18n>

<i18n src="@/locales/components/elements/quick_search/quick-search-contact-card-element.json"></i18n>
