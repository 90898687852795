import { axios_nomination } from '@/plugins/axios';
import configuration from '@/configurations';

class BusinessAlertRepository {

  /**
   * @param {Object} input
   *
   * @returns {Promise}
   */
  async searchNews(input) {
    const response = await axios_nomination.post(
      `${configuration.api_host}/v1/business-alert/news`,
      input
    );

    return response.data;
  }
}

const business_alert_repository = new BusinessAlertRepository();

export {
  business_alert_repository,
};
