'use strict';

const NEWS_TYPES = {
  ALL: {
    ID: -1,
  },
  NOMINATION: {
    ID: 2,
  },
  DEPARTURE: {
    ID: 3,
  },
  BIRTHDAY: {
    ID: 4,
  },
  FUNDRAISER: {
    ID: 5,
  },
  CONFERENCE: {
    ID: 6,
  },
  SPEAKER: {
    ID: 7,
  },
  BUSINESS_SIGNAL: {
    ID: 11,
  },
  PRESS: {
    ID: 12,
  },
  NEW_CONTACT: {
    ID: 13,
  },
};

const NEWS_CONSTANTS = {
  NEWS_TYPES,
};

export {
  NEWS_CONSTANTS,
};
