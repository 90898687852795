import {
  defineAbility,
} from '@casl/ability';
import {
  get,
  uniq,
} from 'lodash';

import {
  LICENSE_CONSTANTS,
} from '@/constants';

import {
  CONNECTOR,
  GLOBAL,
  INSIGHT,
  INTRANET,
  KAM,
  MARKETING,
  SALES,
  SALES_AUTOMATION,
  SALES_AND_MARKETING,
  SALES_PME,
  SEARCH_AND_LISTS,
  SENDER,
  SMART_DATA,
} from '@/constants/abilities';

const capacities = {
  0: GLOBAL,
  [LICENSE_CONSTANTS.SALES.ID]: SALES,
  [LICENSE_CONSTANTS.MARKETING.ID]: MARKETING,
  [LICENSE_CONSTANTS.SALES_AND_MARKETING.ID]: SALES_AND_MARKETING,
  [LICENSE_CONSTANTS.INTRANET.ID]: INTRANET,
  [LICENSE_CONSTANTS.KAM.ID]: KAM,
  [LICENSE_CONSTANTS.CONNECTOR.ID]: CONNECTOR,
  [LICENSE_CONSTANTS.SEARCH_AND_LISTS.ID]: SEARCH_AND_LISTS,
  [LICENSE_CONSTANTS.SALES_PME.ID]: SALES_PME,
  [LICENSE_CONSTANTS.INSIGHT.ID]: INSIGHT,
  [LICENSE_CONSTANTS.SENDER.ID]: SENDER,
  [LICENSE_CONSTANTS.SALES_AUTOMATION.ID]: SALES_AUTOMATION,
  [LICENSE_CONSTANTS.SMART_DATA.ID]: SMART_DATA,
};

const getCapacities = (capacity_list) => {
  const uniq_capacity_list = {};

  capacity_list.can.forEach((capacity) => {
    uniq_capacity_list[capacity.element] = uniq_capacity_list[capacity.element]
      ? uniq(uniq_capacity_list[capacity.element].concat(capacity.action_list))
      : capacity.action_list;
  });

  return (can) => {
    Object
      .entries(uniq_capacity_list)
      .forEach(([element, action_list]) => {
        can(action_list, element);
      });
  };
};

export default function defineAbilitiesFor(user) {
  const license_id = get(user, 'organization_user_license_list[0].license.id', 0);

  return defineAbility(getCapacities(capacities[license_id]));
}
