import {
  email_repository,
} from '@/repositories';

const actions = {
  async getEmailSignature(_, data) {
    const result = await email_repository.getEmailSignature(data);

    return result.signature_list;
  },

  async createEmailSignature(_, data) {
    const result = await email_repository.createEmailSignature(data);

    return result.data;
  },

  async updateEmailSignature(_, data) {
    const result = await email_repository.updateEmailSignature(data);

    return result.data;
  },
};

export default {
  actions,
  namespaced: true,
};
