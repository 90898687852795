'use strict';

const GLOBAL_CONSTANTS = {
  ERROR_TYPE_LIST: [
    'default',
    'sequences',
    'campaigns',
  ],
  SEARCH_TIMEOUT: 1000,
  TEST_MODE: {
    TEMPLATE: 'Template',
  },
  TOPOL_API_KEY: 'OPgNyhWm4CONCLD5n2ToGMHikq00M337aPaGkyWuY93aIUZ9xj8gHJdHH3Aj',
};

export {
  GLOBAL_CONSTANTS,
};
