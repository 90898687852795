import {
  axios_nomination,
} from '@/plugins/axios';
import configuration from '@/configurations';

class HistoryRepository {

  /**
   * @param {Object} criteria
   *
   * @returns {Promise}
   */
  async searchHistory(criteria) {
    const result = await axios_nomination.post(
      `${configuration.api_host}/v2/histories/search`,
      criteria
    );

    return result.data;
  }
}

const history_repository = new HistoryRepository();

export {
  history_repository,
};
