'use strict';

import Axios from 'axios';
import { responseSuccess, responseError, requestAuthenticate } from '@/repositories/ApiInterceptors';

const axios = Axios.create();
const axios_nomination = Axios.create();

axios_nomination.interceptors.response.use(responseSuccess, responseError);
axios_nomination.interceptors.request.use(requestAuthenticate);

export {
  axios,
  axios_nomination,
};
