import http_status from 'http-status';
import moment from 'moment';

import store from '@/store';

const requestAuthenticate = async (config) => {
  const token_data = store.getters['user/tokenData'];

  if (
    !token_data
        || (
          token_data
            && moment(token_data.exp * 1000).isSameOrBefore(moment())
        )
  ) {
    store.dispatch['user/logout'];

    return config;
  }

  const token = store.getters['user/token'];

  const is_connected = true;

  if (is_connected) {
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
  }

  return config;
};

const responseSuccess = function(response) {
  return response;
};

const responseError = async function(error) {
  console.error('From interceptor error', error?.response?.status, error);

  // to avoid type error when that error is not a AxiosError
  if (error?.response?.status === http_status.UNAUTHORIZED) {
    await store.dispatch('user/logout');
  }

  return Promise.reject(error);
};

export {
  requestAuthenticate,
  responseError,
  responseSuccess,
};
