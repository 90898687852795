'use strict';

import { MARKETING } from './Marketing';
import { SALES } from './Sales';

const SALES_AND_MARKETING = {
  can: [
    ...MARKETING.can,
    ...SALES.can,
  ],
};

export {
  SALES_AND_MARKETING,
};
