'use strict';

import { GLOBAL } from './Global';
const PERMISSION_GROUPS = require('../PermissionsGroups');

const smart_data_permissions_list = [];

for (const [key, value] of Object.entries(PERMISSION_GROUPS.SMART_DATA)) {
  smart_data_permissions_list.push({
    action_list: value,
    element: key,
  });
}

const SMART_DATA = {
  can: [
    ...GLOBAL.can,
    ...smart_data_permissions_list,
  ],
};

export {
  SMART_DATA,
};
