'use strict';

const STATUS = {
  ACTIVE: {
    ID: 1,
    LABEL: 'actif',
  },
  INACTIVE: {
    ID: 2,
    LABEL: 'inactif',
  },
  SUSPENDED: {
    ID: 3,
    LABEL: 'suspendu',
  },
};

const USER_CONSTANTS = {
  STATUS,
};

export {
  USER_CONSTANTS,
};
