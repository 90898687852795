import {
  email_repository,
  nylas_repository,
} from '@/repositories';

const state = () => ({
  nylas_credentials: null,
  waiting_for_nylas_response: false,
});

const getters = {
  nylasCredentials: (state) => state.nylas_credentials,
  waitingForNylasResponse: (state) => state.waiting_for_nylas_response,
};

const actions = {
  async createCredentialByCode({commit}, data) {
    commit('set_waiting_for_nylas_response', true);

    const credentials = await nylas_repository.createCredentialByCode(data);

    commit('set_nylas_credentials', credentials);
    commit('set_waiting_for_nylas_response', false);
  },
  async disconnectEmailFromNylas({commit, dispatch}, user_id) {
    commit('set_waiting_for_nylas_response', true);

    await email_repository.deleteNylasCredentials(user_id);

    await dispatch('getCredentialByUserId', user_id);

    commit('set_waiting_for_nylas_response', false);
  },
  async getCredentialByUserId({commit}, user_id) {
    const criteria = {
      user_id,
    };

    commit('set_waiting_for_nylas_response', true);

    const credentials = await email_repository.getNylasCredentials(criteria);

    commit('set_nylas_credentials', credentials);

    commit('set_waiting_for_nylas_response', false);

    return credentials;
  },
  async getUrlForAuthentication({commit}, data) {
    commit('set_waiting_for_nylas_response', true);

    const {
      url_for_authentication,
    } = await nylas_repository.getUrlForAuthentication(data);

    commit('set_waiting_for_nylas_response', false);

    return url_for_authentication;
  },
};

const mutations = {
  set_nylas_credentials(state, nylas_credentials) {
    state.nylas_credentials = nylas_credentials;
  },
  set_waiting_for_nylas_response(state, waiting_for_nylas_response) {
    state.waiting_for_nylas_response = waiting_for_nylas_response;
  },
};

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
