export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        
      },
      "fr": {
        "nomination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomination"])},
        "published_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publié le"])},
        "see_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir son profil"])}
      }
    }
  })
}
