'use strict';

import { GLOBAL } from './Global';
const PERMISSION_GROUPS = require('../PermissionsGroups');

const marketing_permissions_list = [];

for (const [key, value] of Object.entries(PERMISSION_GROUPS.MARKETING)) {
  marketing_permissions_list.push({
    action_list: value,
    element: key,
  });
}

const MARKETING = {
  can: [
    ...GLOBAL.can,
    ...marketing_permissions_list,
  ],
};

export {
  MARKETING,
};
