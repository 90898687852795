const DEFAULT_TIMEOUT = 500;

const FEATURE = {
  FUNCTION_COMPLETE_TASK: 'function_completetask',
  FUNCTION_CREATE_TASK: 'function_createtask',
  FUNCTION_ENGAGE_MAIL: 'function_engagemail',
  FUNCTION_ENGAGE_PHONE: 'function_engagephone',
  FUNCTION_SAVE_ALERT: 'function_savealert',
  FUNCTION_SAVE_LIST: 'function_savelist',
  FUNCTION_SAVE_SEARCH: 'function_savesearch',
  FUNCTION_SAVE_TEMPLATE: 'function_savetemplate',
  FUNCTION_SEND_CAMPAIGN: 'function_sendcampaign',
  PAGE_ADVANCED_SEARCH: 'page_advsearch',
  PAGE_BUSINESS_ALERTS: 'page_alert',
  PAGE_CONTACT: 'page_contact',
  PAGE_COMPANY: 'page_company',
  POPUP_QUICKSEARCH: 'popup_quicksearch',
};

const actions = {
  identify({ rootState }) {
    if (!rootState?.user?.is_connected) {
      return;
    }

    // eslint-disable-next-line no-undef
    ska((skalin) => {
      skalin.identity({
        email: rootState?.user?.user_data?.email,
      });
    });
  },
  sendFeature(feature_name) {
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      ska((skalin) => {
        skalin.feature({
          name: feature_name,
        });
      });
    }, DEFAULT_TIMEOUT);
  },
  completeTaskForSkalin({ dispatch }) {
    dispatch('sendFeature', FEATURE.FUNCTION_COMPLETE_TASK);
  },
  createTaskForSkalin({ dispatch }) {
    dispatch('sendFeature', FEATURE.FUNCTION_CREATE_TASK);
  },
  engageByEmailForSkalin({ dispatch }) {
    dispatch('sendFeature', FEATURE.FUNCTION_ENGAGE_MAIL);
  },
  engageByPhoneForSkalin({ dispatch }) {
    dispatch('sendFeature', FEATURE.FUNCTION_ENGAGE_PHONE);
  },
  saveAlertForSkalin({ dispatch }) {
    dispatch('sendFeature', FEATURE.FUNCTION_SAVE_ALERT);
  },
  saveListForSkalin({ dispatch }) {
    dispatch('sendFeature', FEATURE.FUNCTION_SAVE_LIST);
  },
  saveSearchForSkalin({ dispatch }) {
    dispatch('sendFeature', FEATURE.FUNCTION_SAVE_SEARCH);
  },
  saveTemplateForSkalin({ dispatch }) {
    dispatch('sendFeature', FEATURE.FUNCTION_SAVE_TEMPLATE);
  },
  sendCampaignForSkalin({ dispatch }) {
    dispatch('sendFeature', FEATURE.FUNCTION_SEND_CAMPAIGN);
  },
  consultPageAdvancedSearchForSkalin({ dispatch }) {
    dispatch('sendFeature', FEATURE.PAGE_ADVANCED_SEARCH);
  },
  consultPageBusinessAlertsForSkalin({ dispatch }) {
    dispatch('sendFeature', FEATURE.PAGE_BUSINESS_ALERTS);
  },
  consultPageContactForSkalin({ dispatch }) {
    dispatch('sendFeature', FEATURE.PAGE_CONTACT);
  },
  consultPageCompanyForSkalin({ dispatch }) {
    dispatch('sendFeature', FEATURE.PAGE_COMPANY);
  },
  makeAQuickSearchForSkalin({ dispatch }) {
    dispatch('sendFeature', FEATURE.POPUP_QUICKSEARCH);
  },
};

export default {
  actions,
  namespaced: true,
};
