'use strict';

import { GLOBAL } from './Global';
const PERMISSION_GROUPS = require('../PermissionsGroups');

const sender_permissions_list = [];

for (const [key, value] of Object.entries(PERMISSION_GROUPS.SENDER)) {
  sender_permissions_list.push({
    action_list: value,
    element: key,
  });
}

const SENDER = {
  can: [
    ...GLOBAL.can,
    ...sender_permissions_list,
  ],
};

export {
  SENDER,
};
