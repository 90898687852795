<template>
  <section class="quick-search-news-child-card">
    <div class="icon icon-press">
      <i class="fa fa-pencil" />
    </div>

    <h5 class="title">
      {{ $t('press') }}
    </h5>

    <span>{{ buildDescription() }}</span>

    <a
      :href="getCompanyUrl()"
      class="company"
    >
      {{ news_piece?.company?.name }}
      <span v-if="news_piece?.company?.group_name">({{ news_piece.company.group_name }})</span>
    </a>

    <div class="date">
      {{ $t('published_on') }}
      {{ $filters.formatDate(news_piece?.publication_date) }}
    </div>
  </section>
</template>

<script>
import configuration from '@/configurations';

const NEWS_SOURCE_MELTWATER = 'Meltwater';

export default {
  name: 'QuickSearchPressNewsCard',
  props: {
    news_piece: Object,
  },
  data() {
    return {
      configuration,
    };
  },
  methods: {
    buildDescription() {
      let description = this.$t('press_article');

      if (this.news_piece?.source?.headline) {
        description += ` "${this.news_piece?.source?.headline}"`;
      } else {
        description += ` "${this.news_piece?.title}"`;
      }

      description += `, ${this.$t('regarding')} ${this.news_piece?.company?.name}`;

      description += `, ${this.$t('was_published_by')}`;

      if (this.news_piece?.source?.origin?.name === NEWS_SOURCE_MELTWATER) {
        description += ` ${this.news_piece?.source?.name}. `;
      } else {
        description += ` ${this.news_piece?.source}. `;
      }

      return description;
    },
    getCompanyUrl() {
      return `${this.configuration.desk_v1_host}/account/${this.news_piece?.company?.id}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/constants/variables.module.scss';

.company {
  color: $azure-blue;
  display: block;
  font-family: 'GalanoGrotesqueAlt-Medium';
  font-size: 12px;
  margin-top: 2px;
  margin-bottom: 0px;
  text-decoration: none;
}

.date {
  color: $light-gray-blue;
  font-family: 'Fira Sans';
  font-size: 12px;
  font-style: italic;
  line-height: 12px;
  padding: 10px 0;
  text-transform: none;
}

.icon {
  align-items: center;
  border-radius: 20px;
  color: #fff;
  display: flex;
  width: 30px;
  flex-grow: 0;
  flex-shrink: 0;
  float:left;
  font-size: 14px;
  height: 30px;
  justify-content: center;
  margin-right: 20px;
}

.icon-press {
  background: $yellow-orange;
}

.name {
  color: $dark-blue;
  font-weight: 500;
  text-decoration: none;
}

.quick-search-news-child-card {
  color: $dark-blue;
  font-size: 13px;
  line-height: 18px;
  flex: 1 1 auto;

  a {
      display: inline-block;
  }
}

.title {
  color: $steel-blue;
  font-family: 'GalanoGrotesque-SemiBold';
  font-size: 12px;
  font-weight: 400;
  margin: 0;
  margin-bottom: 10px;
  line-height: 12px;
  padding: 0;
  text-transform: uppercase;
}
</style>

<i18n src="@/locales/common.json"></i18n>

<i18n src="@/locales/components/elements/quick_search/news/quick-search-press-news-card.json"></i18n>
