import {
  axios_nomination,
} from '@/plugins/axios';

import configuration from '@/configurations';

class TaskRepository {

  /**
   * @param {Array} task_id_list
   * @returns {Promise}
   */
  async getTasks(task_id_list) {
    const result = await axios_nomination.post(
      `${configuration.api_host}/v1/task/tasks`,
      {
        params: task_id_list,
      }
    );

    return result.data;
  }

  /**
   * @returns {Promise}
   */
  async getTaskTypes() {
    const result = await axios_nomination.get(
      `${configuration.api_host}/v1/task/tasks/types`
    );

    return result.data;
  }

}

const task_repository = new TaskRepository();

export {
  task_repository,
};
