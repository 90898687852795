<template>
  <button
    :class="{
      [color_class]: true,
      'button--big': big,
      'button--small': small,
      'button--icon-only': !text,
      [disabled_class]: disabled
    }"
    :disabled="disabled"
    class="button"
  >
    <svg-element
      v-if="icon"
      :icon="icon"
      :size="small ? 12 : 16"
    />

    <div v-if="text">
      {{ text }}
    </div>
  </button>
</template>

<script>
import SvgElement from '@/components/elements/SvgElement.vue';

export default {
  name: 'ButtonElement',
  components: {
    SvgElement,
  },
  props: {
    big: {
      default: false,
      type: Boolean,
    },
    color: String,
    disabled: {
      default: false,
      type: Boolean,
    },
    icon: String,
    small: {
      default: false,
      type: Boolean,
    },
    text: String,
  },
  computed: {
    color_class() {
      switch (this.color) {
      case 'blue-o':
        return 'button--blue-o';

      case 'red':
        return 'button--red';

      case 'green-o':
        return 'button--green-o';

      default:
        return 'button--blue';
      }
    },
    disabled_class() {
      switch (this.color) {
      case 'blue-o':
        return 'button--disabled-o';

      case 'red':
        return 'button--disabled';

      case 'green-o':
        return 'button--disabled-o';

      default:
        return 'button--disabled';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/constants/variables.module.scss';

.button {
  $p: &;

  align-items: center;
  border-radius: 10px;
  display: inline-flex;
  font-family: 'DM Sans', sans-serif;
  font-size: 14px;
  font-weight: 700;
  gap: 8px;
  letter-spacing: -0.14px;
  line-height: normal;
  justify-content: center;
  padding: 6px 10px;

  &--big {
    border-radius: 12px;
    padding: 8px 12px;
  }

  &--small {
    border-radius: 8px;
    font-size: 10px;
    letter-spacing: -0.1px;
    line-height: 12px;
    padding: 4px 6px;
  }

  &--icon-only {
    border-radius: 50%;
    font-size: 0;
    height: 32px;
    justify-content: center;
    padding: 0;
    width: 32px;

    &#{$p}--small {
      height: 20px;
      width: 20px;
    }
  }

  &--blue {
    background: $cornflower-blue;
    border: 1px solid $cornflower-blue;
    color: $white;

    svg {
      --color: #{$white};
    }

    &:not(:disabled):hover {
      background: $indigo;
      border: 1px solid $indigo;
    }
  }

  &--blue-o {
    border: 1px solid $cornflower-blue;
    color: $cornflower-blue;

    svg {
      --color: #{$cornflower-blue};
    }

    &:not(:disabled):hover {
      border: 1px solid $indigo;
      color: $indigo;
      svg {
        --color: #{$indigo};
      }
    }
  }

  &--red {
    background: $geraldine;
    border: 1px solid $geraldine;
    color: $white;

    svg {
      --color: #{$white};
    }

    &:not(:disabled):hover {
      background: $deep-blush;
      border: 1px solid $deep-blush;
    }
  }

  &--green-o {
    border: 1px solid $jade;
    color: $jade;

    svg {
      --color: #{$jade};
    }

    &:not(:disabled):hover {
      border: 1px solid $salem;
      color: $salem;

      svg {
        --color: #{$salem};
      }
    }
  }

  &--disabled {
    background: $medium-gray;
    border: 1px solid $medium-gray;
    color: $white;
    cursor: not-allowed;

    svg {
      --color: #{$white};
    }

    &:hover {
      background: $medium-gray;
      border: 1px solid $medium-gray;
      color: $white;

      svg {
        --color: #{$white};
      }
    }
    background-color: $medium-gray;
    border: 1px solid $medium-gray;
    color: $white;
    cursor: not-allowed !important;
  }

  &--disabled-o {
    border: 1px solid $medium-gray;
    background-color: $white;
    color: $medium-gray;
    cursor: not-allowed !important;
  }
}
</style>
