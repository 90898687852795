import {
  axios_nomination,
} from '@/plugins/axios';

import configuration from '@/configurations';

class SequenceRepository {
  constructor() {
    this.calls_in_progress = {};
  }

  /**
   * @param {Object} data
   * @returns {Promise}
   */
  async createSequence(data) {
    const result = await axios_nomination
      .post(
        `${configuration.api_host}/v1/sequence/sequences`,
        data
      );

    return result.data ;
  }

  /**
   * @returns {Promise}
   */
  async getSettings(params) {
    const key = `getSettings:${btoa(JSON.stringify(params))}`;

    if (this.calls_in_progress[key]) {
      return this.calls_in_progress[key];
    }

    this.calls_in_progress[key] = axios_nomination
      .get(
        `${configuration.api_host}/v1/sequence/sequences/settings`,
        {
          params,
        }
      )
      .then((response) => response.data)
      .finally(() => {
        delete this.calls_in_progress[key];
      });

    return this.calls_in_progress[key];
  }

  /**
   * @param {Number} sequence_id
   * @param {Object} data
   * @returns {Promise}
   */
  async createTask(sequence_id, data) {
    const result = await axios_nomination
      .post(
        `${configuration.api_host}/v1/sequences/${sequence_id}/steps`,
        {
          data,
        }
      );

    return result.data;
  }

  /**
   * @param {Number} sequence_id
   * @returns {Promise}
   */
  async deleteSequence(sequence_id) {
    const result = await axios_nomination
      .delete(
        `${configuration.api_host}/v1/sequence/sequences/${sequence_id}`
      );

    return result.data;
  }

  /**
   * @param {Object} step_link
   * @param {Number} step_link_id
   * @returns {Promise}
   */
  async updateStepLinks(step_link, step_link_id) {
    const result = await axios_nomination
      .patch(
        `${configuration.api_host}/v1/sequence/step-links/${step_link_id}`, step_link
      );

    return result.data;
  }

  /**
   * @param {Number} sequence_id
   * @param {Array} contact_id_list
   * @returns {Promise}
   */
  async checkImportTargets(sequence_id, contact_id_list) {
    const result = await axios_nomination
      .post(
        `${configuration.api_host}/v1/sequence/sequences/${sequence_id}/check-import-targets`,
        contact_id_list
      );

    return result.data;
  }

  /**
   * @param {Number} sequence_id
   * @param {Array} contact_id_list
   * @returns {Promise}
   */
  async importTargets(sequence_id, contact_id_list) {
    const result = await axios_nomination
      .post(
        `${configuration.api_host}/v1/sequence/sequences/${sequence_id}/import-targets`,
        contact_id_list
      );

    return result.data;
  }

  /**
   * @param {Object} data
   * @returns {Promise}
   */
  async searchScheduledEmails(data) {
    const key = `searchScheduledEmails:${btoa(JSON.stringify(data))}`;

    if (this.calls_in_progress[key]) {
      return this.calls_in_progress[key];
    }

    this.calls_in_progress[key] = axios_nomination
      .post(
        `${configuration.api_host}/v1/sequence/sequences/scheduled-emails`,
        data
      )
      .then((response) => response.data)
      .finally(() => {
        delete this.calls_in_progress[key];
      });

    return this.calls_in_progress[key];
  }

  /**
   * @param {Object} criteria
   * @returns {Promise}
   */
  async searchSequences(criteria) {
    const {
      id_list,
      limit,
      name,
      offset,
      organization_id,
      owner_user_id_list,
      sort_list,
      status_id_list,
    } = criteria;

    const params = {
      id_list,
      limit,
      offset,
      organization_id,
      owner_user_id_list,
      sort_list,
      status_id_list,
    };

    if (name) {
      params.name = name;
    }

    const key = `searchSequences:${btoa(JSON.stringify(params))}`;

    if (this.calls_in_progress[key]) {
      return this.calls_in_progress[key];
    }

    this.calls_in_progress[key] = axios_nomination
      .get(
        `${configuration.api_host}/v1/sequence/sequences`,
        {
          params,
        }
      )
      .then((response) => response.data)
      .finally(() => {
        delete this.calls_in_progress[key];
      });

    return this.calls_in_progress[key];
  }

  /**
   * @param {Number} sequence_id
   * @returns {Promise}
   */
  async searchSequenceSteps(sequence_id) {
    const result = await axios_nomination
      .get(
        `${configuration.api_host}/v1/sequence/sequences/${sequence_id}/steps`
      );

    return result.data;
  }

  /**
   * @param {Object} criteria
   * @returns {Promise}
   */
  async searchSequenceStepsStatistics(criteria) {
    const {
      id_list,
    } = criteria;
    const [sequence_id] = id_list;

    const result = await axios_nomination
      .get(
        `${configuration.api_host}/v1/sequence/sequences/${sequence_id}/steps-statistics`,
        {
          params: {
            id_list,
          },
        }
      );

    return result.data;
  }

  /**
   * @param {Number} sequence_id
   * @param {Object} criteria
   * @returns {Promise}
   */
  async getTargets(sequence_id, criteria) {
    const key = `getTargets:${btoa(`${sequence_id}-${JSON.stringify(criteria)}`)}`;

    if (this.calls_in_progress[key]) {
      return this.calls_in_progress[key];
    }

    this.calls_in_progress[key] = axios_nomination
      .post(
        `${configuration.api_host}/v1/sequence/sequences/${sequence_id}/targets`,
        criteria
      )
      .then((response) => response.data)
      .finally(() => {
        delete this.calls_in_progress[key];
      });

    return this.calls_in_progress[key];
  }

  /**
   * @param {Number} sequence_id
   * @param {Object} criteria
   * @returns {Promise}
   */
  async deleteTargets(sequence_id, target_id_list) {
    const result = await axios_nomination.delete(
      `${configuration.api_host}/v1/sequence/sequences/${sequence_id}/targets`,
      {
        data: {
          id_list: target_id_list,
        },
      }
    );

    return result.data;
  }

  /**
   * @param {Number} sequence_id
   * @param {Number} target_id
   * @param {Object} data
   * @returns {Promise}
   */
  async updateTarget(sequence_id, target_id, data) {
    const result = await axios_nomination.patch(
      `${configuration.api_host}/v1/sequence/sequences/${sequence_id}/targets/${target_id}`,
      data
    );

    return result.data;
  }

  /**
   * @param {Number} sequence_id
   * @returns {Promise}
   */
  async updateSetting(setting_id, updated_data) {
    const result = await axios_nomination.patch(
      `${configuration.api_host}/v1/sequence/sequences/settings/${setting_id}`,
      updated_data
    );

    return result.data;
  }

  /**
   *
   * @param {Object} sequence
   * @returns {Promise}
   */
  async updateSequence(sequence) {
    const {
      sequence_id,
      sequence_data,
    } = sequence;

    const result = await axios_nomination
      .patch(
        `${configuration.api_host}/v1/sequence/sequences/${sequence_id}`, sequence_data
      );

    return result.data;
  }

  /**
   * @param {Number} company_id
   * @param {Object} step
   * @returns {Promise}
   */
  async createStep(sequence_id, step) {
    const result = await axios_nomination
      .post(
        `${configuration.api_host}/v1/sequence/sequences/${sequence_id}/steps`,
        step
      );

    return result.data;
  }

  /**
   * @param {Number} sequence_id
   * @param {Number} step_id
   * @returns {Promise}
   */
  async deleteStep(sequence_id, step_id) {
    const result = await axios_nomination
      .delete(
        `${configuration.api_host}/v1/sequence/sequences/${sequence_id}/steps/${step_id}`
      );

    return result.data;
  }

  /**
   * @param {Object} step
   * @param {Number} step_id
   */
  async updateStep(sequence_id, step, step_id) {
    const result = await axios_nomination
      .patch(
        `${configuration.api_host}/v1/sequence/sequences/${sequence_id}/steps/${step_id}`,
        step
      );

    return result.data;
  }

  /**
   * @param {Object} step_link
   * @returns {Promise}
   */
  async createStepLink(step_link) {
    const result = await axios_nomination
      .post(
        `${configuration.api_host}/v1/sequence/step-links`,
        step_link
      );

    return result.data;
  }

  /**
   * @param {Object} step_link
   * @returns {Promise}
   */
  async searchSequencesIncludingContacts(data) {
    const key = `searchSequencesIncludingContacts:${btoa(JSON.stringify(data))}`;

    if (this.calls_in_progress[key]) {
      return this.calls_in_progress[key];
    }

    this.calls_in_progress[key] = axios_nomination
      .post(
        `${configuration.api_host}/v1/sequence/sequences/search-by-contact-id`,
        data
      )
      .then((response) => response.data)
      .finally(() => {
        delete this.calls_in_progress[key];
      });

    return this.calls_in_progress[key];
  }

  /**
   * @param {Object} step_link
   * @returns {Promise}
   */
  async getSequenceNextSteps(sequence_id) {
    const result = await axios_nomination
      .get(
        `${configuration.api_host}/v1/sequence/sequences/${sequence_id}/next-steps`,
        {
          params: {
            sequence_id,
          },
        }
      );

    return result.data;
  }

  /**
   * @param {Object} data
   * @returns {Promise}
   */
  async duplicateSequence(sequence_id, data) {
    const result = await axios_nomination
      .post(
        `${configuration.api_host}/v1/sequence/sequences/${sequence_id}/duplicate`,
        data
      );

    return result.data ;
  }

  /**
   * @param {Number} sequence_id
   * @param {Object} data
   * @returns {Promise}
   */
  async switchSteps(sequence_id, data) {
    const result = await axios_nomination
      .post(
        `${configuration.api_host}/v1/sequence/sequences/${sequence_id}/switch-steps`,
        data
      );

    return result.data;
  }

  /**
   * @param {Object} data
   * @returns {Promise}
   */
  async executeRunner(data) {
    const result = await axios_nomination
      .post(
        `${configuration.api_host}/v1/sequence/sequences/runner`,
        data
      );

    return result.data ;
  }

  /**
   * @returns {Promise}
   */
  async getTemplates() {
    const result = await axios_nomination
      .get(
        `${configuration.api_host}/v1/sequence/templates`
      );

    return result.data;
  }

  /**
   * @param {Number} sequence_id
   *
   * @returns {Promise}
   */
  async searchTargetLists(sequence_id) {
    const result = await axios_nomination.get(
      `${configuration.api_host}/v1/sequence/sequences/${sequence_id}/target-lists`
    );

    return result.data;
  }

  /**
   * @param {Object} input
   *
   * @returns {Promise}
   */
  async addTargetLists(input) {
    const {
      sequence_id,
      query_id_list,
    } = input;

    const result = await axios_nomination
      .post(
        `${configuration.api_host}/v1/sequence/sequences/${sequence_id}/target-lists`,
        {
          query_id_list,
        }
      );

    return result.data;
  }

  /**
   * @param {Object} mail_data
   *
   * @returns {Promise}
   */
  async sendMailTest(mail_data) {
    const result = await axios_nomination
      .post(`${configuration.api_host}/v1/sequence/sequences/send-email-test`, mail_data);

    return result.data;
  }

}

const sequence_repository = new SequenceRepository();

export {
  sequence_repository,
};
