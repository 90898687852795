import { axios_nomination } from '@/plugins/axios';
import configuration from '@/configurations';

class EmailRepository {
  constructor() {
    this.calls_in_progress = {};
  }

  /**
   * @param {Object} mail_data
   * @returns {Promise}
   */
  async sendMailTest(mail_data) {
    const result = await axios_nomination
      .post(`${configuration.api_host}/v1/email/engage`, mail_data);

    return result.data;
  }

  /**
   * @returns {Promise}
   */
  async searchEmailTemplates() {
    const result = await axios_nomination
      .get(`${configuration.api_host}/v1/email/templates`);

    return result.data;
  }

  /**
   * @param {Object} mail_data
   * @returns {Promise}
   */
  async createTemplate(input) {
    const result = await axios_nomination
      .post(`${configuration.api_host}/v1/email/templates`, input);

    return result.data;
  }

  /**
   * @param {Object} mail_data
   * @returns {Promise}
   */
  async updateTemplate(input, template_id) {
    const result = await axios_nomination
      .patch(`${configuration.api_host}/v1/email/template/${template_id}`, input);

    return result.data;
  }

  /**
   * @param {Object} mail_data
   * @returns {Promise}
   */
  async getNylasCredentials(criteria) {
    const key = `getNylasCredentials:${btoa(JSON.stringify(criteria))}`;

    if (this.calls_in_progress[key]) {
      return this.calls_in_progress[key];
    }

    this.calls_in_progress[key] = axios_nomination
      .get(
        `${configuration.api_host}/v1/email/nylas/credentials`,
        {
          params: criteria,
        }
      )
      .then((response) => response.data)
      .finally(() => {
        delete this.calls_in_progress[key];
      });

    return this.calls_in_progress[key];
  }

  /**
   * @param {number} user_id
   * @returns {Promise}
   */
  async deleteNylasCredentials(user_id) {
    const key = `deleteNylasCredentials:${btoa(JSON.stringify(user_id))}`;

    if (this.calls_in_progress[key]) {
      return this.calls_in_progress[key];
    }

    this.calls_in_progress[key] = axios_nomination
      .delete(`${configuration.api_host}/v1/email/nylas/credentials/${user_id}`)
      .then((response) => response.data)
      .finally(() => {
        delete this.calls_in_progress[key];
      });

    return this.calls_in_progress[key];
  }

  /**
   * @returns {Promise}
   */
  async searchEmailTemplatesTags(criteria) {
    const key = `searchEmailTemplatesTags:${btoa(JSON.stringify(criteria))}`;

    if (this.calls_in_progress[key]) {
      return this.calls_in_progress[key];
    }

    this.calls_in_progress[key] = axios_nomination
      .get(
        `${configuration.api_host}/v1/email/templates/tags`,
        {
          params: criteria,
        }
      )
      .then((response) => response.data)
      .finally(() => {
        delete this.calls_in_progress[key];
      });

    return this.calls_in_progress[key];
  }

  /**
   *
   * @param {Object} params
   *
   * @returns {Promise}
   */
  async getBats(params) {
    const result = await axios_nomination.get(
      `${configuration.api_host}/v1/email/bat-emails`,
      {
        params,
      }
    );

    return result.data.bat_email_list;
  }

  /**
   * @param {Object} mail_data
   *
   * @returns {Promise}
   */
  async updateBats(input) {
    const result = await axios_nomination
      .put(`${configuration.api_host}/v1/email/bat-emails`, input);

    return result.data.bat_email_list;
  }

  /**
   * @param {Object} criteria
   * @returns {Promise}
   */
  async getEmailSignature(criteria) {
    const result = await axios_nomination
      .get(
        `${configuration.api_host}/v1/email/signatures`,
        {
          params: criteria,
        }
      );

    return result.data;
  }

  /**
   * @param {Object} data
   * @returns {Promise}
   */
  async createEmailSignature(data) {
    const result = await axios_nomination
      .post(`${configuration.api_host}/v1/email/signatures`, data);

    return result.data;
  }

  /**
   * @param {Object} data
   * @returns {Promise}
   */
  async updateEmailSignature(data) {
    const {
      html_content,
      name,
      plain_content,
      signature_id,
      user_id,
    } = data;

    const result = await axios_nomination
      .put(`${configuration.api_host}/v1/email/signature/${signature_id}`, {
        html_content,
        name,
        plain_content,
        user_id,
      });

    return result.data;
  }

  /**
   * @param {Object} data
   * @returns {Promise}
   */
  async sendHelpMessageToSupport(data) {
    const result = await axios_nomination
      .post(`${configuration.api_host}/v1/email/help-request`, data);

    return result.data;
  }
}

const email_repository = new EmailRepository();

export {
  email_repository,
};
