import configuration from '@/configurations';

const state = () => ({
  is_loading: false,
  has_drawer: false,
  is_opened_drawer: false,
  app_version: configuration.app_version,
  showing_quick_search_results: false,
});

const getters = {
  isLoading: (state) => state.is_loading,
  hasDrawer: (state) => state.has_drawer,
  isOpenedDrawer: (state) => state.is_opened_drawer,
  getAppVersion: (state) => state.app_version,
  showingQuickSearchResults: (state) => state.showing_quick_search_results,
};

const actions = {
  setLoading({ commit }, is_loading) {
    return new Promise((resolve) => {
      commit('set_loading', is_loading);

      return resolve();
    });
  },
  setOpenedDrawer({ commit }, is_open) {
    return commit('is_opened_drawer', is_open);
  },
  setHasDrawer({ commit }, has_drawer) {
    return commit('has_drawer', has_drawer);
  },
  setShowingQuickSearchResults({ commit }, showing_quick_search_results) {
    return commit('showing_quick_search_results', showing_quick_search_results);
  },
};

const mutations = {
  set_loading(state, is_loading) {
    state.is_loading = is_loading;
  },
  has_drawer(state, has_drawer) {
    state.has_drawer = has_drawer;
  },
  is_opened_drawer(state, is_open) {
    state.is_opened_drawer = is_open;
  },
  showing_quick_search_results(state, showing_quick_search_results) {
    state.showing_quick_search_results = showing_quick_search_results;
  },
};

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
