export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        
      },
      "fr": {
        "quick_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher un contact, une entreprise..."])},
        "companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprises"])},
        "conferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conférences"])},
        "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
        "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualités"])},
        "no_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun compte"])},
        "no_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun contact"])},
        "no_conference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune conférence"])},
        "no_news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune actualité"])},
        "quick_search_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il y a eu une erreur lors de l'extraction des résultats. Veuillez réessayer plus tard."])},
        "see_more_companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir plus d'entreprises"])},
        "see_more_conferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir plus de conférences"])},
        "see_more_contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir plus de contacts"])},
        "see_more_news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir plus d'alertes"])}
      }
    }
  })
}
