export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "fr": {
        "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactez-nous"])},
        "help_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centre d'aide"])}
      },
      "en": {
        "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
        "help_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help center"])}
      }
    }
  })
}
