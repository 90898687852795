<template>
  <section class="quick-search-news-card">
    <quick-search-business-signal-news-card
      v-if="news_piece?.type === NEWS_CONSTANTS.NEWS_TYPES.BUSINESS_SIGNAL.ID"
      :news_piece="news_piece"
    />

    <quick-search-departure-news-card
      v-if="news_piece?.type === NEWS_CONSTANTS.NEWS_TYPES.DEPARTURE.ID"
      :news_piece="news_piece"
    />

    <quick-search-nomination-news-card
      v-if="news_piece?.type === NEWS_CONSTANTS.NEWS_TYPES.NOMINATION.ID"
      :news_piece="news_piece"
    />

    <quick-search-press-news-card
      v-if="news_piece?.type === NEWS_CONSTANTS.NEWS_TYPES.PRESS.ID"
      :news_piece="news_piece"
    />

    <quick-search-speaker-news-card
      v-if="news_piece?.type === NEWS_CONSTANTS.NEWS_TYPES.SPEAKER.ID"
      :news_piece="news_piece"
    />
  </section>
</template>

<script>
import {
  NEWS_CONSTANTS,
} from '@/constants';

import QuickSearchBusinessSignalNewsCard from '@/components/elements/quick_search/cards/news/QuickSearchBusinessSignalNewsCard.vue';
import QuickSearchDepartureNewsCard from '@/components/elements/quick_search/cards/news/QuickSearchDepartureNewsCard.vue';
import QuickSearchNominationNewsCard from '@/components/elements/quick_search/cards/news/QuickSearchNominationNewsCard.vue';
import QuickSearchPressNewsCard from '@/components/elements/quick_search/cards/news/QuickSearchPressNewsCard.vue';
import QuickSearchSpeakerNewsCard from '@/components/elements/quick_search/cards/news/QuickSearchSpeakerNewsCard.vue';

export default {
  name: 'QuickSearchNewsCardElement',
  components: {
    QuickSearchBusinessSignalNewsCard,
    QuickSearchDepartureNewsCard,
    QuickSearchNominationNewsCard,
    QuickSearchPressNewsCard,
    QuickSearchSpeakerNewsCard,
  },
  props: {
    news_piece: Object,
  },
  data() {
    return {
      NEWS_CONSTANTS,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/constants/variables.module.scss';

.quick-search-news-card {
  border: 1px solid $light-capuccino;
  border-radius: 3px;
  margin-bottom: 10px;
  padding: 20px;
}
</style>

<i18n src="@/locales/common.json"></i18n>
