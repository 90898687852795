import {
  email_repository,
} from '@/repositories';

const state = () => ({
  bat_list: [],
  email_templates: [],
  email_templates_tags: [],
});

const getters = {
  batList: (state) => state.bat_list,
  emailTemplates: (state) => state.email_templates,
  emailTemplatesTags: (state) => state.email_templates_tags,
};

const actions = {
  async searchEmailTemplates({commit}) {
    const result = await email_repository.searchEmailTemplates();
    let template_list = result.template_list.reverse();

    template_list.map((template) => {
      template.first_tag_list = [];
      template.last_tag_list = [];
    });
    commit('set_email_templates', template_list);

    return result;
  },
  async searchEmailTemplatesTags({commit, rootState}) {
    const result = await email_repository.searchEmailTemplatesTags({
      user_id_list: [ rootState.user.token_data.user_id ],
    });

    const templates_tags_list = result.tag_list
      .map((tag) => ({
        ...tag,
        checked: false,
      }));

    commit('set_email_templates_tags', templates_tags_list);

    return templates_tags_list;
  },
  async sendHelpMessageToSupport(_, data) {
    const result = await email_repository.sendHelpMessageToSupport(data);

    return result;
  },
  async sendMailTest(_, mail_data) {
    const result = await email_repository.sendMailTest(mail_data);

    return result;
  },
  async createTemplate({dispatch}, input) {
    const template = await email_repository.createTemplate(input);

    await dispatch('searchEmailTemplates');

    return template;
  },
  async updateTemplate({dispatch}, input) {
    await email_repository.updateTemplate({
      html_content: input.html_content,
      name: input.name,
      plain_content:input.plain_content,
      subject: input.subject,
    }, input.id
    );

    return await dispatch('searchEmailTemplates');
  },
  async getBats({ commit, dispatch, rootState }) {
    const bat_list = await email_repository.getBats({
      user_id_list: [rootState.user.token_data.user_id],
    });

    const personnal_email = await dispatch('getPersonnalEmail');

    bat_list.unshift({
      email: `${personnal_email} (moi)`,
      id: 0,
    });

    commit('set_bat_list', bat_list);

    return bat_list;
  },
  async updateBats({ commit, dispatch }, input) {
    const new_bat_list = input.bat_list.map((bat) => ({
      email: bat,
    }));

    const bat_list = await email_repository.updateBats(new_bat_list);

    const personnal_email = await dispatch('getPersonnalEmail');

    bat_list.unshift({
      email: `${personnal_email} (moi)`,
      id: null,
    });

    commit('set_bat_list', bat_list);

    return bat_list;
  },
  getPersonnalEmail({ rootState }) {
    const {
      nylas_credentials,
    } = rootState.nylas;

    return (
      nylas_credentials !== null
      && nylas_credentials !== undefined
      && Object.keys(nylas_credentials).length > 0
    )
      ? nylas_credentials.email
      : rootState.user.user_data.email;
  },
};

const mutations = {
  set_bat_list(state, bat_list) {
    state.bat_list = bat_list;
  },
  set_email_templates(state, email_templates) {
    state.email_templates = email_templates;
  },
  set_email_templates_tags(state, email_templates_tags) {
    state.email_templates_tags = email_templates_tags;
  },
};

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
