import {
  sequence_repository,
} from '@/repositories';

import {
  SEQUENCE_STATUS_CONSTANTS,
  SEQUENCE_PER_PAGE_COUNT,
} from '@/constants';

const DEFAULT_TIME_ZONE = 'Europe/Paris';

const state = () => ({
  sequence_list: [],

  criteria_sort_list: ['-updated_at'],
  criteria_status_id_list: [],
  criteria_user_id_list: [],
  criteria_name: null,
  check_import_targets: {
    existing_contact_count: 0,
    new_contact_count: 0,
    no_direct_mail_count: 0,
  },

  limit: SEQUENCE_PER_PAGE_COUNT,
  offset: 0,
  page: 1,
  sequence_count: null,
});

const getters = {
  criteriaSortList: (state) => state.criteria_sort_list,
  criteriaStatusIdList: (state) => state.criteria_status_id_list,
  criteriaUserIdList: (state) => state.criteria_user_id_list,
  criteriaName: (state) => state.criteria_name,
  sequenceList: (state) => state.sequence_list,
  settings: (state) => state.settings,
  checkImportTargets: (state) => state.check_import_targets,

  limit: (state) => state.limit,
  page: (state) => state.page,
  offset: (state) => state.offset,
  sequenceCount: (state) => state.sequence_count,
};

const actions = {
  getCriteria({ state, rootState }) {
    const user = rootState.user.token_data;

    return {
      organization_id: user.organization_id,
      status_id_list: state.criteria_status_id_list.length > 0
        ? state.criteria_status_id_list
        : undefined,
      owner_user_id_list: state.criteria_user_id_list.length > 0
        ? state.criteria_user_id_list
        : undefined,
      name: state ? state.criteria_name : undefined,
      limit: state.limit,
      offset: state.offset,
      sort_list: state.criteria_sort_list,
    };
  },

  async processSearch({ commit, dispatch }) {
    const criteria = await dispatch('getCriteria');
    const result = await dispatch('search', criteria);
    const settings = await dispatch('getSettings');

    commit('set_sequence_list', result.sequence_list);
    commit('set_sequence_count', result.sequence_count);
    commit('set_settings', settings);
  },

  // Here is the wrapper around repository search method
  async search(_, criteria) {
    const result = await sequence_repository.searchSequences(criteria);

    return result;
  },

  async createSequence({ rootState, state }, input) {
    const {
      sequence_name,
      sequence_template_id,
    } = input;

    const organization_id = rootState.user.token_data.organization_id;

    const data = {
      finish_event_mail_replied: true,
      name: sequence_name,
      organization_id,
      sendable_timezone: DEFAULT_TIME_ZONE,
      template_id: sequence_template_id,
    };

    const created_sequence = await sequence_repository
      .createSequence(data);

    if (state.criteria_status_id_list.includes(SEQUENCE_STATUS_CONSTANTS.DRAFT.ID)) {
      state.sequence_list.splice(0, 0, created_sequence);
    }

    return created_sequence;
  },

  async deleteSequence({ state }, sequence_id) {
    await sequence_repository.deleteSequence(sequence_id);

    const deleted_sequence_index = state.sequence_list
      .findIndex((sequence) => sequence.id === sequence_id);

    if (deleted_sequence_index >= 0) {
      state.sequence_list.splice(deleted_sequence_index, 1);
    }
  },

  async duplicateSequence(_, { sequence_id, duplicate_data}) {
    await sequence_repository.duplicateSequence(sequence_id, duplicate_data);
  },

  async getSettings({ rootState }) {
    const result = await sequence_repository.getSettings({
      organization_id_list: [rootState.user.token_data.organization_id],
    });

    return result.setting_list;
  },

  async updateSetting(_, { id, params }) {
    const updatted_setting = await sequence_repository.updateSetting(id, params);

    return updatted_setting;
  },

  async userHasConnectedEmail({ dispatch }, owner_user_id) {
    if (!owner_user_id) {
      return false;
    }

    const settings = await dispatch('getSettings');
    const [ user_setting ] = settings.filter((setting) => setting.user_id === owner_user_id);

    return (user_setting?.email ? true : false);
  },

  async checkImportTargets({ commit }, { sequence_id, contact_id_list }) {
    const check_import_targets = await sequence_repository
      .checkImportTargets(sequence_id, {
        contact_id_list,
      });

    commit('set_check_import_targets', check_import_targets);
  },

  async importTargets(_, { sequence_id, contact_id_list }) {
    await sequence_repository
      .importTargets(sequence_id, {
        contact_id_list,
      });
  },

  async getTemplates() {
    const result = await sequence_repository.getTemplates();

    return result.template_list;
  },

  async sendMailTest(_, mail_data) {
    const result = await sequence_repository.sendMailTest(mail_data);

    return result;
  },
};

const mutations = {
  set_criteria_status_id_list(state, criteria_status_id_list) {
    state.criteria_status_id_list = criteria_status_id_list;
  },
  set_criteria_user_id_list(state, criteria_user_id_list) {
    state.criteria_user_id_list = criteria_user_id_list;
  },
  set_criteria_name(state, criteria_name) {
    state.criteria_name = criteria_name;
  },
  set_limit(state, limit) {
    state.limit = limit;
  },
  set_sequence_list(state, sequence_list) {
    state.sequence_list = sequence_list;
  },
  set_settings(state, settings) {
    state.settings = settings;
  },
  set_offset(state, offset) {
    state.offset = offset;
  },
  set_page(state, page) {
    state.page = page;
  },
  set_sequence_count(state, sequence_count) {
    state.sequence_count = sequence_count;
  },
  set_check_import_targets(state, check_import_targets) {
    state.check_import_targets = check_import_targets;
  },
};

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
