export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        
      },
      "fr": {
        "sentence_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre licence ne vous permet pas d'accéder à ce contenu."])},
        "sentence_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour changer de licence, veuillez prendre contact avec votre référent commercial ou contacter notre Service Client au :"])}
      }
    }
  })
}
