'use strict';

const REGEX_CONSTANTS = {
  AT_LEAST_ONE_BIG_AND_SMALL: '(.*[a-z]+.*[A-Z]+|.*[A-Z]+.*[a-z]+)',
  AT_LEAST_ONE_NUMBER: '[0-9]',
  PASSWORD: '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d).{8,}$',
  PHONE_NUMBER: '^[0-9 +.-]{10,}$',
};

export {
  REGEX_CONSTANTS,
};
