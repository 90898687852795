'use strict';

const EXPORT_DURATION_DAYS = 7;
const EXPORT_LIMIT = 10;

const EXPORT_STATUS = {
  PENDING: 'pending',
  PROCESSING: 'processing',
  SUCCESS: 'success',
  FAILURE: 'failure',
  CLICKED: 'clicked',
};

export {
  EXPORT_DURATION_DAYS,
  EXPORT_LIMIT,
  EXPORT_STATUS,
};
