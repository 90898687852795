import {
  axios,
  axios_nomination,
} from '@/plugins/axios';
import configuration from '@/configurations';

class FileStorageRepository {

  /**
   * @param {Object} criteria
   *
   * @returns {Promise}
   */
  async searchAmazonS3Hash(criteria) {
    const {
      organization_id,
    } = criteria;

    const result = await axios_nomination.get(
      `${configuration.api_host}/v1/file-storage/amazon-s3/hash`,
      {
        params: {
          organization_id,
        },
      }
    );

    return result.data;
  }

  /**
   * @param {Object} input
   *
   * @returns {Promise}
   */
  async uploadFileToAmazonS3(input) {
    const {
      bucket_name,
      image_name,
      params,
    } = input;

    await axios.post(
      `https://s3-eu-west-3.amazonaws.com/${bucket_name}`,
      params,
      {
        headers: {
          'Accept': 'application/json',
        },
      }
    );

    return `https://s3-eu-west-3.amazonaws.com/${bucket_name}/${image_name}`;
  }
}

const file_storage_repository = new FileStorageRepository();

export {
  file_storage_repository,
};
