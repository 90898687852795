import {
  axios_nomination,
} from '@/plugins/axios';
import configuration from '@/configurations';

class InteractionRepository {

  /**
   * @param {Object} criteria
   *
   * @returns {Promise}
   */
  async searchInteractions(criteria) {
    const {
      company_id_list,
      contact_id_list,
      id_list,
      user_id_list,
      organization_id_list,
      type_id_list,
      date_upper_boundary,
      date_lower_boundary,
    } = criteria;

    const result = await axios_nomination.post(
      `${configuration.api_host}/v1/interaction/search-interactions`,
      {
        company_id_list,
        contact_id_list,
        id_list,
        user_id_list,
        organization_id_list,
        type_id_list,
        date_upper_boundary,
        date_lower_boundary,
      }
    );

    return result.data;
  }
}

const interaction_repository = new InteractionRepository();

export {
  interaction_repository,
};
