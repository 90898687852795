'use strict';

const INTERACTION_CONSTANTS = {
  MAIL_SENT: 1,
  MAIL_CLICKED: 2,
  MAIL_OPENED: 3,
  CALLED: 4,
  NO_POTENTIAL: 5,
  APPOINTMENT: 6,
  MAIL_REPLIED: 7,
  MAIL_BOUNCED: 8,
};

export {
  INTERACTION_CONSTANTS,
};
