import {
  axios_nomination,
} from '@/plugins/axios';

import configuration from '@/configurations';

class CustomerInquiryRepository {

  /**
   * @param {Object} criteria
   *
   * @returns {Promise}
   */
  async searchCustomersInquiry(criteria) {
    const response = await axios_nomination.get(
      `${configuration.api_host}/v1/customer-inquiry/customer-inquiries`,
      {
        params: criteria,
      }
    );

    return response.data;
  }
}

const customer_inquiry_repository = new CustomerInquiryRepository();

export {
  customer_inquiry_repository,
};
