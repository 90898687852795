<template>
  <quick-search-img-wrapper
    :src="company_logo"
    srcset
    lazy-src
    width="48"
    height="48"
    @error="onImageError"
  />
</template>

<script>
import QuickSearchImgWrapper from './QuickSearchImgWrapper';

const not_found_default_logo = require("@/assets/images/dummy/company-logo.jpg");
const company_logo_url_prefix = 'https://storage.googleapis.com/static-logos-nomination/';
const company_logo_extention = 'jpg';

export default {
  name: 'QuickSearchCompanyLogo',
  components: {
    QuickSearchImgWrapper,
  },
  props: {
    company_id: Number,
  },
  data() {
    return {
      company_logo: `${company_logo_url_prefix}${this.company_id}.${company_logo_extention}`,
      display_avatar: true,
    };
  },
  methods: {
    onImageError() {
      this.company_logo = not_found_default_logo;
    },
  },
};
</script>
