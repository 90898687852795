<template>
  <section class="quick-search-conference-card">
    <div class="date">
      <div class="day">
        {{ $filters.moment(conference?.date, 'DD') }}
      </div>

      <div class="month">
        {{ $filters.moment(conference?.date, 'MMM') }}
      </div>

      <div class="year">
        {{ $filters.moment(conference?.date, 'YYYY') }}
      </div>
    </div>

    <div>
      <div>
        <a
          :href="getConferenceUrl()"
          class="name"
        >
          {{ conference?.name }}
        </a>
      </div>

      <div
        v-if="conference?.organisers?.length > 0"
        class="organizers"
      >
        {{ $t('by') }}
        <template
          v-for="(organizer, index) in conference.organisers"
          :key="index"
        >
          <span>{{ organizer.name }}</span>
          <span v-if="index < conference.organisers.length - 1">, </span>
        </template>
      </div>

      <div
        v-if="conference?.city_name"
        class="location"
      >
        {{ conference.city_name }} {{ getFirstTwoDigitsOfZipCode() }}
      </div>
    </div>
  </section>
</template>

<script>
import configuration from '@/configurations';

export default {
  name: 'QuickSearchConferenceCardElement',
  props: {
    conference: Object,
  },
  data() {
    return {
      configuration,
    };
  },
  methods: {
    getConferenceUrl() {
      return `${this.configuration.desk_v1_host}/conference/${this.conference.id}/program`;
    },
    getFirstTwoDigitsOfZipCode() {
      return this.conference?.zip_code?.slice(0, 2);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/constants/variables.module.scss';

.date {
  background-color: $light-creme;
  padding: 7px 10px;
  text-align: center;
}

.day {
  color: $emerald-green;
  font-family: 'GalanoGrotesque-SemiBold';
  font-weight: 400;
  font-style: normal;
  font-size: 21px;
  line-height: 21px;
}

.location {
  color: $nepal;
  font-size: 12px;
  font-style: italic;
  margin-top: 6px;
}

.month {
  color: $emerald-green;
  font-family: 'GalanoGrotesque-SemiBold';
  font-weight: 400;
  font-style: normal;
  font-size: 10px;
  line-height: 10px;
  text-transform: uppercase;
}

.name {
  color: $dark-blue;
  font-family: 'GalanoGrotesqueAlt-Medium';
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: none;
}

.organizers {
  color: $azure-blue;
  font-family: 'GalanoGrotesqueAlt-Medium';
  font-size: 12px;
  line-height: 12px;
  margin-top: 6px;
}

.year {
  color: $light-taupe;
  font-family: 'GalanoGrotesque-Medium';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
}

.quick-search-conference-card {
  border: 1px solid $light-capuccino;
  border-radius: 3px;
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
  padding: 15px;
  width: 100%;
}
</style>

<i18n src="@/locales/common.json"></i18n>

<i18n src="@/locales/components/elements/quick_search/quick-search-conference-card-element.json"></i18n>
