import {
  smart_mailing_repository,
  search_repository,
} from '@/repositories';

import {
  TAG_CONSTANTS,
} from '@/constants';

const state = () => ({
  contact_target_list: [],
  target_list: [],
  selected_target_list: [],
  selected_target_id_list: [],
  query_id_list_loaded: [],

  criteria_email_event_list: [],
  criteria_campaign_id: null,
  criteria_name: '',

  limit: 20,
  offset: 0,
  page: 1,
  target_count: null,

  process_search_loader: false,
});

const getters = {
  contactTargetList: (state) => state.contact_target_list,
  selectedTargetList: (state) => state.selected_target_list,
  selectedTargetIdList: (state) => state.selected_target_id_list,
  targetCount: (state) => state.target_count,
  targetTotalCount: (state) => state.target_total_count,
  targetList: (state) => state.target_list,
  queryIdListLoaded: (state) => state.query_id_list_loaded,

  criteriaEmailEventList: (state) => state.criteria_email_event_list,
  criteriaCampaignId: (state) => state.criteria_campaign_id,
  criteriaName: (state) => state.criteria_name,

  limit: (state) => state.limit,
  page: (state) => state.page,
  offset: (state) => state.offset,

  processSearchLoader: (state) => state.process_search_loader,
};

const actions = {
  getCriteria({ state }) {
    return {
      campaign_id: state.criteria_campaign_id,
      email_event_list: state.criteria_email_event_list.length > 0
        ? state.criteria_email_event_list
        : undefined,
      limit: state.limit,
      name: state.criteria_name,
      offset: state.offset,
    };
  },

  async processSearch({ commit, dispatch }) {
    commit('set_contact_target_list', []);
    commit('set_process_search_loader', true);

    const criteria = await dispatch('getCriteria');
    const target_list = await dispatch('search', criteria);
    const enriched_contact_target_list = await dispatch('enrichTargets', { target_list });

    commit('set_contact_target_list', enriched_contact_target_list);
    commit('set_process_search_loader', false);
  },

  async enrichTargets({ dispatch }, { target_list }) {
    const contact_id_list = target_list.map((target) => (target.contact_id));

    let contact_list = [];

    if (contact_id_list.length > 0) {
      contact_list = await dispatch('getContactDetails', { contact_id_list });
    }

    return target_list
      .map((target) => {
        const contact_details = contact_list.find((contact) => contact.id === target.contact_id);

        if (contact_details === undefined) {
          return {
            id: target.id,
            contact_details: {
              id: target.contact_id,
            },
            is_contact_inactive: true,
          };
        }

        const job_tag_list = contact_details.job_type_list
          .map((item) => item.name)
          .flat();

        const contact_tag_list = contact_details.tag_list;
        const company_tag_list = contact_details.company.tag_list;

        const orderTagsForContact = (tag_list) => tag_list
          .sort((tag_1, tag_2) => {
            const position_1 = TAG_CONSTANTS.FOR_CONTACT.find((tag) => tag.id === tag_1.id)
              ?.position;
            const position_2 = TAG_CONSTANTS.FOR_CONTACT.find((tag) => tag.id === tag_2.id)
              ?.position;

            return position_1 - position_2;
          });

        const ordered_tags = orderTagsForContact([...contact_tag_list, ...company_tag_list])
          .map((item) => item.list.map((sub_item) => sub_item.name))
          .flat();

        const new_contact_details = {
          ...contact_details,
          tag_list: [...job_tag_list, ...ordered_tags],
        };

        return {
          ...target,
          contact_details: new_contact_details,
          is_contact_inactive: false,
        };

      });
  },

  async selectAllTargets({ commit, dispatch, state }) {
    const criteria = await dispatch('getCriteria');

    const special_criteria = Object.assign(
      {},
      criteria
    );

    delete special_criteria.limit;
    delete special_criteria.offset;

    // DELETE name from criteria ?
    const all_target_list = await dispatch('search', special_criteria);
    const all_target_id_list = all_target_list.map((target) => target.id);

    const target_ids_to_select = all_target_id_list
      .filter((target_id) => !state.selected_target_id_list.includes(target_id));

    for (let target of target_ids_to_select) {
      commit('add_target_id_to_selected_target_id_list', target);
    }

    commit('set_selected_target_list', all_target_list);
  },

  async deselectAllTargets({ commit }) {
    commit('set_selected_target_id_list', []);
    commit('set_selected_target_list', []);
  },

  async deleteSelectedTargets({ state, commit }, campaign_id) {
    await smart_mailing_repository.deleteTargets(campaign_id, state.selected_target_id_list);
    commit('set_selected_target_id_list', []);
  },

  async updateSelectedTargets({ commit, dispatch, state }, data) {
    const promise_list = state.selected_target_id_list.map((target_id) => dispatch(
      'updateTarget',
      {
        target_id,
        data,
      }
    ));

    await Promise.allSettled(promise_list);
    commit('set_selected_target_id_list', []);
  },

  // Here is the wrapper around repository search method
  async search({ commit, dispatch, rootState }, criteria) {
    let response;

    if (criteria.name.length >= 3) {
      const filtered_contact_response = await search_repository.getContacts({
        contact_quicksearch_list: [criteria.name],
        query_id_list: [rootState.smart_mailing.campaign_data.target_query_id],
      });

      const filtered_contact_id_list = filtered_contact_response.contact_list
        .map((contact) => contact.id);

      response = await smart_mailing_repository.searchTargets(Object.assign(
        {},
        criteria,
        {
          contact_id_list: filtered_contact_id_list,
        }
      ));
    } else {
      response = await smart_mailing_repository.searchTargets(criteria);
    }

    const target_list = response.target_list;

    await dispatch('getTargetTotalCount');

    commit('set_target_list', response.target_list);
    commit('set_target_count', response.target_count);

    return target_list;
  },

  async searchTargets({ commit }, criteria) {
    const response = await smart_mailing_repository.searchTargets(criteria);

    commit('set_target_list', response.target_list);
    commit('set_target_count', response.target_count);

    return response.target_list;
  },

  async getContactDetails(_, criteria) {
    const result = await search_repository.getContacts(criteria);

    return result.contact_list;
  },

  async updateTarget({ state, commit }, { target_id, data }) {
    const campaign_id = state.criteria_campaign_id;
    const result = await smart_mailing_repository.updateTarget(campaign_id, target_id, data);

    commit('set_target_status', { target_id, status_id: data.status_id });

    return result;
  },

  async getQueryIdListActive(_, id) {
    const result = await search_repository.getWtfContactsActive({
      value_list: [id],
    });

    return result.facet_list.query_id_list;
  },

  async getTargetTotalCount({ commit, state }) {
    const response = await smart_mailing_repository.searchTargets({
      campaign_id: state.criteria_campaign_id,
      limit: 0,
    });

    commit('set_target_total_count', response.target_count);
  },
};

const mutations = {
  add_target_id_to_selected_target_id_list(state, target_id) {
    state.selected_target_id_list.push(target_id);
  },
  set_contact_target_list(state, contact_target_list) {
    state.contact_target_list = contact_target_list;
  },
  set_target_list(state, target_list) {
    state.target_list = target_list;
  },

  set_criteria_email_event_list(state, criteria_email_event_list) {
    state.criteria_email_event_list = criteria_email_event_list;
  },
  set_criteria_campaign_id(state, criteria_campaign_id) {
    state.criteria_campaign_id = criteria_campaign_id;
  },
  set_criteria_name(state, criteria_name) {
    state.criteria_name = criteria_name;
  },

  set_limit(state, limit) {
    state.limit = limit;
  },
  set_offset(state, offset) {
    state.offset = offset;
  },
  set_page(state, page) {
    state.page = page;
  },

  set_process_search_loader(state, process_search_loader) {
    state.process_search_loader = process_search_loader;
  },
  set_query_id_list_loaded(state, query_id_list_loaded) {
    state.query_id_list_loaded = query_id_list_loaded;
  },
  set_selected_target_id_list(state, selected_target_id_list) {
    state.selected_target_id_list = selected_target_id_list;
  },
  set_selected_target_list(state, selected_target_list) {
    state.selected_target_list = selected_target_list;
  },
  set_target_count(state, target_count) {
    state.target_count = target_count;
  },
  set_target_total_count(state, target_total_count) {
    state.target_total_count = target_total_count;
  },
};

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
