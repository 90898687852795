'use strict';

const ADVANCED_SEARCH_FOR_COMPANY = [
  {
    id: 1,
    type: 'TYPE_ID_SECTOR',
    position: 2,
    link: false,
  },
  {
    id: 2,
    type: 'TYPE_ID_COMPANY_SIZE',
    position: 3,
    link: false,
  },
  {
    id: 3,
    type: 'TYPE_ID_SALES_REVENUE',
    position: 4,
    link: false,
  },
  {
    id: 4,
    type: 'TYPE_ID_WORKFORCE',
    position: 5,
    link: false,
  },
  {
    id: 5,
    type: 'TYPE_ID_STRUCTURE_TYPE',
    position: 6,
    link: false,
  },
  {
    id: 6,
    type: 'TYPE_ID_QUOTATION',
    position: 7,
    link: false,
  },
  {
    id: 7,
    type: 'TYPE_ID_ADVERTISING_INVESTMENT',
    position: 8,
    link: false,
  },
  {
    id: 9,
    type: 'TYPE_ID_BIRTHDAY',
    position: 9,
    link: false,
  },
  {
    id: 8,
    type: 'TYPE_ID_AWARD',
    position: 10,
    link: false,
  },
  {
    id: 10,
    type: 'TYPE_ID_GROW',
    position: 1,
    link: false,
    color: false,
  },
  {
    id: 12,
    type: 'TYPE_ID_SEGMENT_ACCOUNT',
    position: 1,
    link: false,
    color: true,
  },
];

const ADVANCED_SEARCH_FOR_CONTACT = [
  {
    id: 10,
    type: 'TYPE_ID_HIERARCHICAL_LEVEL',
    position: 3,
    link: false,
  },
  {
    id: 11,
    type: 'TYPE_ID_HOBBIES',
    position: 4,
    link: false,
  },
  {
    id: 12,
    type: 'TYPE_ID_SEGMENT_ACCOUNT',
    position: 1,
    link: false,
    color: true,
  },
  {
    id: 13,
    type: 'TYPE_ID_SEGMENT_CONTACT',
    position: 2,
    link: false,
    color: true,
  },
];

const FOR_COMPANY = [
  {
    id: 1,
    type: 'TYPE_ID_SECTOR',
    position: 1,
    link: false,
  },
  {
    id: 2,
    type: 'TYPE_ID_COMPANY_SIZE',
    position: 2,
    link: false,
  },
  {
    id: 3,
    type: 'TYPE_ID_SALES_REVENUE',
    position: 3,
    link: false,
  },
  {
    id: 4,
    type: 'TYPE_ID_WORKFORCE',
    position: 4,
    link: false,
  },
  {
    id: 5,
    type: 'TYPE_ID_STRUCTURE_TYPE',
    position: 5,
    link: false,
  }, {
    id: 6,
    type: 'TYPE_ID_QUOTATION',
    position: 6,
    link: false,
  },
  {
    id: 7,
    type: 'TYPE_ID_ADVERTISING_INVESTMENT',
    position: 7,
    link: false,
  },
  {
    id: 9,
    type: 'TYPE_ID_BIRTHDAY',
    position: 8,
    link: false,
  },
  {
    id: 8,
    type: 'TYPE_ID_AWARD',
    position: 9,
    link: false,
  },
  {
    id: 10,
    type: 'TYPE_ID_GROW',
    position: 1,
    link: false,
    color: false,
  },
  {
    id: 12,
    type: 'TYPE_ID_SEGMENT_ACCOUNT',
    position: 1,
    link: false,
    color: true,
  },
  {
    id: 11,
    type: 'TYPE_ID_REGIONAL_LEADER',
    position: 20,
    link: false,
    color: false,
  },
];

const FOR_CONFERENCE = [
  {
    id: 14,
    type: 'TYPE_ID_THEMATIC',
    position: 1,
    link: false,
  },
];

const FOR_CONTACT = [
  {
    id: 1,
    type: 'TYPE_ID_SECTOR',
    position: 3,
    link: false,
  },
  {
    id: 2,
    type: 'TYPE_ID_COMPANY_SIZE',
    position: 4,
    link: false,
  },
  {
    id: 3,
    type: 'TYPE_ID_SALES_REVENUE',
    position: 5,
    link: false,
  },
  {
    id: 4,
    type: 'TYPE_ID_WORKFORCE',
    position: 6,
    link: false,
  },
  {
    id: 5,
    type: 'TYPE_ID_STRUCTURE_TYPE',
    position: 7,
    link: false,
  },
  {
    id: 6,
    type: 'TYPE_ID_QUOTATION',
    position: 8,
    link: false,
  },
  {
    id: 7,
    type: 'TYPE_ID_ADVERTISING_INVESTMENT',
    position: 9,
    link: false,
  },
  {
    id: 9,
    type: 'TYPE_ID_BIRTHDAY',
    position: 10,
    link: false,
  },
  {
    id: 8,
    type: 'TYPE_ID_AWARD',
    position: 11,
    link: false,
  },
  {
    id: 10,
    type: 'TYPE_ID_HIERARCHICAL_LEVEL',
    position: 1,
    link: false,
  },
  {
    id: 11,
    type: 'TYPE_ID_HOBBIES',
    position: 2,
    link: false,
  },
];

const FOR_LEAD_SUGGESTION_CARD = [
  {
    id: 1,
    type: 'TYPE_ID_SECTOR',
    position: 5,
    link: false,
  },
  {
    id: 2,
    type: 'TYPE_ID_COMPANY_SIZE',
    position: 6,
    link: false,
  },
  {
    id: 3,
    type: 'TYPE_ID_SALES_REVENUE',
    position: 7,
    link: false,
  },
  {
    id: 4,
    type: 'TYPE_ID_WORKFORCE',
    position: 8,
    link: false,
  },
  {
    id: 5,
    type: 'TYPE_ID_STRUCTURE_TYPE',
    position: 9,
    link: false,
  },
  {
    id: 6,
    type: 'TYPE_ID_QUOTATION',
    position: 10,
    link: false,
  },
  {
    id: 7,
    type: 'TYPE_ID_ADVERTISING_INVESTMENT',
    position: 11,
    link: false,
  },
  {
    id: 9,
    type: 'TYPE_ID_BIRTHDAY',
    position: 12,
    link: false,
  },
  {
    id: 8,
    type: 'TYPE_ID_AWARD',
    position: 13,
    link: false,
  },
  {
    id: 10,
    type: 'TYPE_ID_HIERARCHICAL_LEVEL',
    position: 4,
    link: false,
  },
  {
    id: 11,
    type: 'TYPE_ID_HOBBIES',
    position: 3,
    link: false,
  },
  {
    id: 12,
    type: 'TYPE_ID_SEGMENT_ACCOUNT',
    position: 2,
    link: false,
    color: true,
  },
  {
    id: 13,
    type: 'TYPE_ID_SEGMENT_CONTACT',
    position: 1,
    link: false,
    color: true,
  },
];

const TAG_CONSTANTS = {
  ADVANCED_SEARCH_FOR_COMPANY,
  ADVANCED_SEARCH_FOR_CONTACT,
  FOR_COMPANY,
  FOR_CONFERENCE,
  FOR_CONTACT,
  FOR_LEAD_SUGGESTION_CARD,
};

export {
  TAG_CONSTANTS,
};
