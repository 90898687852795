'use strict';

import { PERMISSIONS } from './Permissions';
import PermissionsTypes from './PermissionsTypes';

const { CREATE, READ, UPDATE, DELETE } = PermissionsTypes;

const INTRANET = {
  [PERMISSIONS.ADVANCED_SEARCH]: [READ],
  [PERMISSIONS.BUSINESS_ALERTS]: [READ],
  [PERMISSIONS.CONFERENCES]: [READ],
  [PERMISSIONS.MY_PROSPECTING_SMART_SCORING]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_ACTIVITY]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_SAVED_SEARCHES]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_CREDIT_CONSUMPTION]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_CREDIT_CONSUMPTION_CONSULTATION]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_REQUESTS]: [READ],
  [PERMISSIONS.QUICK_SEARCH]: [READ],
  [PERMISSIONS.QUICK_SEARCH_ALL_RESULTS]: [READ],
  [PERMISSIONS.QUICK_SEARCH_COMPANIES]: [READ],
  [PERMISSIONS.QUICK_SEARCH_CONTACTS]: [READ],
  [PERMISSIONS.QUICK_SEARCH_NEWS]: [READ],
};

// à renommer en smart campaign
const MARKETING = {
  [PERMISSIONS.ADVANCED_SEARCH]: [READ],
  [PERMISSIONS.CONFERENCES]: [READ],
  [PERMISSIONS.HOME_HELP]: [READ],
  [PERMISSIONS.HOME_PROFILE]: [READ],
  [PERMISSIONS.HOME_PROSPECT]: [READ],
  [PERMISSIONS.MY_PROSPECTING_MANAGER_ACCESS]: [READ],
  [PERMISSIONS.MY_PROSPECTING_SMART_SCORING]: [READ],
  [PERMISSIONS.NAVBAR_PROSPECTS]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_SAVED_SEARCHES]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_PARAMETERS]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_MY_ACCOUNT]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_PROFILE]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_CHANGE_PASSWORD]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_ACTIVITY]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_CONTACT_CUSTOMER_SERVICE]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_CREDIT_CONSUMPTION]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_CREDIT_CONSUMPTION_EXPORT]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_CREDIT_CONSUMPTION_LOCATION]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_EXPORT]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_LOGOUT]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_TEMPLATES]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_NOTIFICATIONS_SETTINGS]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_EMAIL_CONNECTION]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_REQUESTS]: [READ],
  [PERMISSIONS.QUICK_SEARCH]: [READ],
  [PERMISSIONS.QUICK_SEARCH_ALL_RESULTS]: [READ],
  [PERMISSIONS.QUICK_SEARCH_COMPANIES]: [READ],
  [PERMISSIONS.QUICK_SEARCH_CONFERENCES]: [READ],
  [PERMISSIONS.QUICK_SEARCH_CONTACTS]: [READ],
  [PERMISSIONS.QUICK_SEARCH_NEWS]: [READ],
  [PERMISSIONS.CAMPAIGNS]: [CREATE, READ, UPDATE, DELETE],
  [PERMISSIONS.CAMPAIGNS_DRAFT]: [READ, UPDATE],
  [PERMISSIONS.CAMPAIGNS_SCHEDULED]: [READ, UPDATE],
  [PERMISSIONS.CAMPAIGNS_SENT]: [READ, UPDATE],
  [PERMISSIONS.CAMPAIGNS_SETTINGS]: [CREATE, READ, UPDATE, DELETE],
  [PERMISSIONS.SEQUENCES]: [CREATE, READ, UPDATE, DELETE],
  [PERMISSIONS.SEQUENCES_DRAFT]: [READ, UPDATE],
  [PERMISSIONS.SEQUENCES_PROGRAMMED]: [READ, UPDATE],
  [PERMISSIONS.SEQUENCES_ONGOING]: [READ, UPDATE],
  [PERMISSIONS.SEQUENCES_PAUSED]: [READ, UPDATE],
  [PERMISSIONS.SEQUENCES_FINISHED]: [READ, UPDATE],
  [PERMISSIONS.SEQUENCES_ARCHIVED]: [READ],
  [PERMISSIONS.SEQUENCES_SETTINGS]: [CREATE, READ, UPDATE, DELETE],
};

// à renommer en sales intelligence
const SALES = {
  [PERMISSIONS.ADVANCED_SEARCH]: [READ],
  [PERMISSIONS.BUSINESS_ALERTS]: [READ],
  [PERMISSIONS.CAMPAIGNS]: [READ],
  [PERMISSIONS.CAMPAIGNS_SCHEDULED]: [READ],
  [PERMISSIONS.CAMPAIGNS_SENT]: [READ],
  [PERMISSIONS.CONFERENCES]: [READ],
  [PERMISSIONS.HOME_HELP]: [READ],
  [PERMISSIONS.HOME_NEWS]: [READ],
  [PERMISSIONS.HOME_PROFILE]: [READ],
  [PERMISSIONS.HOME_PROSPECT]: [READ],
  [PERMISSIONS.MY_PROSPECTING_MANAGER_ACCESS]: [READ],
  [PERMISSIONS.MY_PROSPECTING_MY_PROSPECTS]: [READ],
  [PERMISSIONS.MY_PROSPECTING_SMART_SCORING]: [READ],
  [PERMISSIONS.NAVBAR_PROSPECTS]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_MY_NETWORK]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_SAVED_SEARCHES]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_ACCOUNT_SEGMENTS]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_CONTACT_SEGMENTS]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_MY_ALERTS]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_NOTIFICATIONS]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_NOTIFICATIONS_SETTINGS]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_NOTIFICATIONS_SETTINGS_ALL]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_DASHBOARD]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_PARAMETERS]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_MY_ACCOUNT]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_PROFILE]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_CHANGE_PASSWORD]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_ACTIVITY]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_CONTACT_CUSTOMER_SERVICE]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_CREDIT_CONSUMPTION]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_CREDIT_CONSUMPTION_EXPORT]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_CREDIT_CONSUMPTION_LOCATION]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_EXPORT]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_LOGOUT]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_TEMPLATES]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_EMAIL_CONNECTION]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_REQUESTS]: [READ],
  [PERMISSIONS.QUICK_SEARCH]: [READ],
  [PERMISSIONS.QUICK_SEARCH_ALL_RESULTS]: [READ],
  [PERMISSIONS.QUICK_SEARCH_COMPANIES]: [READ],
  [PERMISSIONS.QUICK_SEARCH_CONFERENCES]: [READ],
  [PERMISSIONS.QUICK_SEARCH_CONTACTS]: [READ],
  [PERMISSIONS.QUICK_SEARCH_NEWS]: [READ],
  [PERMISSIONS.SEQUENCES]: [READ],
  [PERMISSIONS.SEQUENCES_PROGRAMMED]: [READ],
  [PERMISSIONS.SEQUENCES_ONGOING]: [READ],
  [PERMISSIONS.SEQUENCES_PAUSED]: [READ],
  [PERMISSIONS.SEQUENCES_FINISHED]: [READ],
  [PERMISSIONS.SEQUENCES_ARCHIVED]: [READ],
};

const SALES_AUTOMATION = {
  [PERMISSIONS.ADVANCED_SEARCH]: [READ],
  [PERMISSIONS.BUSINESS_ALERTS]: [READ],
  [PERMISSIONS.CAMPAIGNS]: [READ],
  [PERMISSIONS.CAMPAIGNS_SCHEDULED]: [READ],
  [PERMISSIONS.CAMPAIGNS_SENT]: [READ],
  [PERMISSIONS.CONFERENCES]: [READ],
  [PERMISSIONS.HOME_HELP]: [READ],
  [PERMISSIONS.HOME_NEWS]: [READ],
  [PERMISSIONS.HOME_PROFILE]: [READ],
  [PERMISSIONS.HOME_PROSPECT]: [READ],
  [PERMISSIONS.MY_PROSPECTING_MANAGER_ACCESS]: [READ],
  [PERMISSIONS.MY_PROSPECTING_MY_PROSPECTS]: [READ],
  [PERMISSIONS.MY_PROSPECTING_SMART_SCORING]: [READ],
  [PERMISSIONS.NAVBAR_PROSPECTS]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_MY_NETWORK]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_SAVED_SEARCHES]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_ACCOUNT_SEGMENTS]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_CONTACT_SEGMENTS]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_MY_ALERTS]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_NOTIFICATIONS]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_NOTIFICATIONS_SETTINGS]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_NOTIFICATIONS_SETTINGS_ALL]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_DASHBOARD]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_PARAMETERS]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_MY_ACCOUNT]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_PROFILE]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_CHANGE_PASSWORD]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_ACTIVITY]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_CONTACT_CUSTOMER_SERVICE]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_CREDIT_CONSUMPTION]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_CREDIT_CONSUMPTION_EXPORT]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_CREDIT_CONSUMPTION_LOCATION]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_EXPORT]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_LOGOUT]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_TEMPLATES]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_EMAIL_CONNECTION]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_REQUESTS]: [READ],
  [PERMISSIONS.QUICK_SEARCH]: [READ],
  [PERMISSIONS.QUICK_SEARCH_ALL_RESULTS]: [READ],
  [PERMISSIONS.QUICK_SEARCH_COMPANIES]: [READ],
  [PERMISSIONS.QUICK_SEARCH_CONFERENCES]: [READ],
  [PERMISSIONS.QUICK_SEARCH_CONTACTS]: [READ],
  [PERMISSIONS.QUICK_SEARCH_NEWS]: [READ],
  [PERMISSIONS.SEQUENCES]: [CREATE, READ, UPDATE, DELETE],
  [PERMISSIONS.SEQUENCES_DRAFT]: [READ, UPDATE],
  [PERMISSIONS.SEQUENCES_PROGRAMMED]: [READ, UPDATE],
  [PERMISSIONS.SEQUENCES_ONGOING]: [READ, UPDATE],
  [PERMISSIONS.SEQUENCES_PAUSED]: [READ, UPDATE],
  [PERMISSIONS.SEQUENCES_FINISHED]: [READ, UPDATE],
  [PERMISSIONS.SEQUENCES_ARCHIVED]: [READ],
  [PERMISSIONS.SEQUENCES_SETTINGS]: [CREATE, READ, UPDATE, DELETE],
};

const KAM = {
  [PERMISSIONS.BUSINESS_ALERTS]: [READ],
  [PERMISSIONS.CAMPAIGNS]: [READ],
  [PERMISSIONS.CAMPAIGNS_DRAFT]: [READ],
  [PERMISSIONS.CAMPAIGNS_SCHEDULED]: [READ],
  [PERMISSIONS.CAMPAIGNS_SENT]: [READ],
  [PERMISSIONS.CONFERENCES]: [READ],
  [PERMISSIONS.HOME_HELP]: [READ],
  [PERMISSIONS.HOME_NEWS]: [READ],
  [PERMISSIONS.HOME_PROFILE]: [READ],
  [PERMISSIONS.PERSONAL_SPACE]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_ACCOUNT_SEGMENTS]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_ACTIVITY]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_CHANGE_PASSWORD]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_CONTACT_SEGMENTS]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_CONTACT_CUSTOMER_SERVICE]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_CREDIT_CONSUMPTION]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_CREDIT_CONSUMPTION_CONSULTATION]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_CREDIT_CONSUMPTION_EXPORT]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_CREDIT_CONSUMPTION_LOCATION]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_DASHBOARD]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_EMAIL_CONNECTION]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_EXPORT]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_LOGOUT]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_MY_ACCOUNT]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_MY_ALERTS]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_MY_ACTIVITY]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_MY_NETWORK]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_NOTIFICATIONS]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_NOTIFICATIONS_SETTINGS]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_NOTIFICATIONS_SETTINGS_ALL]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_PARAMETERS]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_PROFILE]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_REQUEST_HELP]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_SAVED_SEARCHES]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_TEMPLATES]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_REQUESTS]: [READ],
  [PERMISSIONS.QUICK_SEARCH]: [READ],
  [PERMISSIONS.QUICK_SEARCH_ALL_RESULTS]: [READ],
  [PERMISSIONS.QUICK_SEARCH_COMPANIES]: [READ],
  [PERMISSIONS.QUICK_SEARCH_CONFERENCES]: [READ],
  [PERMISSIONS.QUICK_SEARCH_CONTACTS]: [READ],
  [PERMISSIONS.QUICK_SEARCH_NEWS]: [READ],
  [PERMISSIONS.SEQUENCES]: [READ],
  [PERMISSIONS.SEQUENCES_DRAFT]: [READ],
  [PERMISSIONS.SEQUENCES_PROGRAMMED]: [READ],
  [PERMISSIONS.SEQUENCES_ONGOING]: [READ],
  [PERMISSIONS.SEQUENCES_PAUSED]: [READ],
  [PERMISSIONS.SEQUENCES_FINISHED]: [READ],
  [PERMISSIONS.SEQUENCES_ARCHIVED]: [READ],
};

const SEARCH_AND_LISTS = {
  [PERMISSIONS.ADVANCED_SEARCH]: [READ],
  [PERMISSIONS.HOME_PROFILE]: [READ],
  [PERMISSIONS.HOME_PROSPECT]: [READ],
  [PERMISSIONS.MY_PROSPECTING_MANAGER_ACCESS]: [READ],
  [PERMISSIONS.MY_PROSPECTING_SMART_SCORING]: [READ],
  [PERMISSIONS.NAVBAR_PROSPECTS]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_ACTIVITY]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_DASHBOARD]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_SAVED_SEARCHES]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_NOTIFICATIONS]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_REQUESTS]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_EXPORT]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_CREDIT_CONSUMPTION]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_MY_ACCOUNT]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_NOTIFICATIONS_SETTINGS]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_NOTIFICATIONS_SETTINGS_ALL]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_CHANGE_PASSWORD]: [READ],
};

const SALES_PME = {
  [PERMISSIONS.ADVANCED_SEARCH]: [READ],
  [PERMISSIONS.BUSINESS_ALERTS]: [READ],
  [PERMISSIONS.CAMPAIGNS]: [READ],
  [PERMISSIONS.CAMPAIGNS_SCHEDULED]: [READ],
  [PERMISSIONS.CAMPAIGNS_SENT]: [READ],
  [PERMISSIONS.CONFERENCES]: [READ],
  [PERMISSIONS.MY_PROSPECTING_MY_PROSPECTS]: [READ],
  [PERMISSIONS.MY_PROSPECTING_SMART_SCORING]: [READ],
  [PERMISSIONS.NAVBAR_PROSPECTS]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_MY_NETWORK]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_SAVED_SEARCHES]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_ACCOUNT_SEGMENTS]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_CONTACT_SEGMENTS]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_MY_ALERTS]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_NOTIFICATIONS]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_NOTIFICATIONS_SETTINGS]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_NOTIFICATIONS_SETTINGS_ALL]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_DASHBOARD]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_PARAMETERS]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_MY_ACCOUNT]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_PROFILE]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_CHANGE_PASSWORD]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_ACTIVITY]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_CONTACT_CUSTOMER_SERVICE]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_CREDIT_CONSUMPTION]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_CREDIT_CONSUMPTION_EXPORT]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_CREDIT_CONSUMPTION_LOCATION]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_EXPORT]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_LOGOUT]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_TEMPLATES]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_EMAIL_CONNECTION]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_REQUESTS]: [READ],
  [PERMISSIONS.QUICK_SEARCH]: [READ],
  [PERMISSIONS.QUICK_SEARCH_ALL_RESULTS]: [READ],
  [PERMISSIONS.QUICK_SEARCH_COMPANIES]: [READ],
  [PERMISSIONS.QUICK_SEARCH_CONFERENCES]: [READ],
  [PERMISSIONS.QUICK_SEARCH_CONTACTS]: [READ],
  [PERMISSIONS.QUICK_SEARCH_NEWS]: [READ],
  [PERMISSIONS.SEQUENCES]: [READ],
  [PERMISSIONS.SEQUENCES_PROGRAMMED]: [READ],
  [PERMISSIONS.SEQUENCES_ONGOING]: [READ],
  [PERMISSIONS.SEQUENCES_PAUSED]: [READ],
  [PERMISSIONS.SEQUENCES_FINISHED]: [READ],
  [PERMISSIONS.SEQUENCES_ARCHIVED]: [READ],
};

const INSIGHT = {
  [PERMISSIONS.ADVANCED_SEARCH]: [READ],
  [PERMISSIONS.CONFERENCES]: [READ],
  [PERMISSIONS.HOME_HELP]: [READ],
  [PERMISSIONS.HOME_PROSPECT]: [READ],
  [PERMISSIONS.MY_PROSPECTING_SMART_SCORING]: [READ],
  [PERMISSIONS.NAVBAR_PROSPECTS]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_ACTIVITY]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_DASHBOARD]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_SAVED_SEARCHES]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_EXPORT]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_CREDIT_CONSUMPTION]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_EMAIL_CONNECTION]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_NOTIFICATIONS_SETTINGS]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_CHANGE_PASSWORD]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_REQUESTS]: [READ],
  [PERMISSIONS.QUICK_SEARCH]: [READ],
  [PERMISSIONS.QUICK_SEARCH_ALL_RESULTS]: [READ],
  [PERMISSIONS.QUICK_SEARCH_COMPANIES]: [READ],
  [PERMISSIONS.QUICK_SEARCH_CONFERENCES]: [READ],
  [PERMISSIONS.QUICK_SEARCH_CONTACTS]: [READ],
  [PERMISSIONS.QUICK_SEARCH_NEWS]: [READ],
};

const SENDER = {
  [PERMISSIONS.PERSONAL_SPACE_CHANGE_PASSWORD]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_EMAIL_CONNECTION]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_MY_ACCOUNT]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_PARAMETERS]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_PROFILE]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_REQUESTS]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_TEMPLATES]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_LOGOUT]: [READ],
};

const SMART_DATA = {
  [PERMISSIONS.HOME_NEWS]: [READ],
  [PERMISSIONS.HOME_PROSPECT]: [READ],
  [PERMISSIONS.HOME_HELP]: [READ],

  [PERMISSIONS.QUICK_SEARCH]: [READ],
  [PERMISSIONS.QUICK_SEARCH_ALL_RESULTS]: [READ],
  [PERMISSIONS.QUICK_SEARCH_COMPANIES]: [READ],
  [PERMISSIONS.QUICK_SEARCH_CONFERENCES]: [READ],
  [PERMISSIONS.QUICK_SEARCH_CONTACTS]: [READ],
  [PERMISSIONS.QUICK_SEARCH_NEWS]: [READ],

  [PERMISSIONS.ADVANCED_SEARCH]: [READ],

  [PERMISSIONS.BUSINESS_ALERTS]: [READ],

  [PERMISSIONS.CONFERENCES]: [READ],

  [PERMISSIONS.NAVBAR_PROSPECTS]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_DASHBOARD]: [READ],

  [PERMISSIONS.CAMPAIGNS]: [READ],
  [PERMISSIONS.CAMPAIGNS_SCHEDULED]: [READ],
  [PERMISSIONS.CAMPAIGNS_SENT]: [READ],

  [PERMISSIONS.SEQUENCES]: [READ],
  [PERMISSIONS.SEQUENCES_PROGRAMMED]: [READ],
  [PERMISSIONS.SEQUENCES_ONGOING]: [READ],
  [PERMISSIONS.SEQUENCES_PAUSED]: [READ],
  [PERMISSIONS.SEQUENCES_FINISHED]: [READ],
  [PERMISSIONS.SEQUENCES_ARCHIVED]: [READ],

  [PERMISSIONS.PERSONAL_SPACE_SAVED_SEARCHES]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_ACTIVITY]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_NOTIFICATION]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_EXPORT]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_CREDIT_CONSUMPTION]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_PARAMETERS]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_ACCOUNT_SEGMENTS]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_CONTACT_SEGMENTS]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_MY_ALERTS]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_MY_ACCOUNT]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_NOTIFICATIONS_SETTINGS]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_CHANGE_PASSWORD]: [READ],
  [PERMISSIONS.PERSONAL_SPACE_LOGOUT]: [READ],
};

export {
  INSIGHT,
  INTRANET,
  KAM,
  MARKETING,
  SALES,
  SALES_AUTOMATION,
  SALES_PME,
  SEARCH_AND_LISTS,
  SENDER,
  SMART_DATA,
};
