export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        
      },
      "fr": {
        "feminine_before_consonant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de la"])},
        "feminine_before_vowel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de l'"])},
        "masculine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["du"])},
        "plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["des"])},
        "speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speaker"])},
        "published_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publié le"])},
        "on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["le"])}
      }
    }
  })
}
