<template>
  <section class="quick-search-news-child-card">
    <div class="icon icon-departure">
      <i class="fa fa-sign-out" />
    </div>

    <h5 class="title">
      {{ $t('departure') }}
    </h5>

    <a
      :href="getContactUrl()"
      class="name"
    >
      {{ formatContactName() }}
    </a>

    {{ $t('left_his_position_as') }}

    {{ news_piece?.person?.job_name }}

    <a
      :href="getCompanyUrl()"
      class="company"
    >
      {{ news_piece?.person?.company_name }}
      <span v-if="news_piece?.person?.company_group_name">({{ news_piece.person.company_group_name }})</span>
    </a>

    <div class="date">
      {{ $t('published_on') }}
      {{ $filters.formatDate(news_piece?.publication_date) }}
    </div>

    <a
      :href="getContactUrl()"
      class="profile-link"
    >
      <i class="fa fa-angle-right profile-link-icon" />
      <span class="profile-link-description">{{ $t('see_profile') }}</span>
    </a>
  </section>
</template>

<script>
import configuration from '@/configurations';

export default {
  name: 'QuickSearchDepartureNewsCard',
  props: {
    news_piece: Object,
  },
  data() {
    return {
      configuration,
    };
  },
  methods: {
    formatContactName() {
      if (this.news_piece?.person?.first_name && this.news_piece?.person?.last_name) {
        return `${this.news_piece.person.first_name} ${this.news_piece.person.last_name}`;
      }

      return this.news_piece?.person?.name;
    },
    getCompanyUrl() {
      return `${this.configuration.desk_v1_host}/account/${this.news_piece.person.company_id}`;
    },
    getContactUrl() {
      return `${this.configuration.desk_v1_host}/contact/${this.news_piece.person.id}/profile`;
    },
    getJobName() {
      return this.news_piece?.person?.job_name
        ? this.news_piece.person.job_name
        : this.news_piece?.job?.job_name;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/constants/variables.module.scss';

.company {
  color: $azure-blue;
  display: block;
  font-family: 'GalanoGrotesqueAlt-Medium';
  font-size: 12px;
  margin-top: 2px;
  margin-bottom: 0px;
  text-decoration: none;
}

.date {
  color: $light-gray-blue;
  font-family: 'Fira Sans';
  font-size: 12px;
  font-style: italic;
  line-height: 12px;
  padding: 10px 0;
  text-transform: none;
}

.icon {
  align-items: center;
  border-radius: 20px;
  color: #fff;
  display: flex;
  width: 30px;
  flex-grow: 0;
  flex-shrink: 0;
  float:left;
  font-size: 14px;
  height: 30px;
  justify-content: center;
  margin-right: 20px;
}

.icon-departure {
  background: $apple-green;
}

.name {
  color: $dark-blue;
  font-weight: 500;
  text-decoration: none;
}

.profile-link {
  color: $steel-blue;
  font-size: 13px;
  line-height: 13px;
  text-decoration: none;
  white-space: nowrap;

  &:hover {
    color: $dark-steel-blue;
  }
}

.profile-link-description {
  border-bottom: 1px solid $steel-blue;

  &:hover {
    border-bottom: 1px solid rgba(255,255,255,0);
  }
}

.profile-link-icon {
  margin-right: 5px;
  transition: transform 0.4s linear;

  &:hover {
    color: $dark-steel-blue;
    transform: translate(2px, 0);
  }
}

.quick-search-news-child-card {
  color: $dark-blue;
  font-size: 13px;
  line-height: 18px;
  flex: 1 1 auto;

  a {
      display: inline-block;
  }
}

.title {
  color: $steel-blue;
  font-family: 'GalanoGrotesque-SemiBold';
  font-size: 12px;
  font-weight: 400;
  margin: 0;
  margin-bottom: 10px;
  line-height: 12px;
  padding: 0;
  text-transform: uppercase;
}
</style>

<i18n src="@/locales/common.json"></i18n>

<i18n src="@/locales/components/elements/quick_search/news/quick-search-departure-news-card.json"></i18n>
