'use strict';

import { GLOBAL } from './Global';
const PERMISSION_GROUPS = require('../PermissionsGroups');

const intranet_permissions_list = [];

for (const [key, value] of Object.entries(PERMISSION_GROUPS.INTRANET)) {
  intranet_permissions_list.push({
    action_list: value,
    element: key,
  });
}

const INTRANET = {
  can: [
    ...GLOBAL.can,
    ...intranet_permissions_list,
  ],
};

export {
  INTRANET,
};
