import {
  export_repository,
} from '@/repositories';

import {
  EXPORT_LIMIT,
} from '@/constants';

const state = () => ({
  export_list: null,
});

const getters = {
  exportList: (state) => state.export_list,
};

const actions = {
  async getDownloadLink(_, export_id) {
    const response = await export_repository.getDownloadLink(export_id);

    return response;
  },
  async searchExports({ rootState, commit, state }, input) {
    const {
      offset,
    } = input;

    const response = await export_repository.searchExports({
      limit: EXPORT_LIMIT,
      offset,
      sort_list: ['-start_date'],
      user_id_list: [rootState.user.token_data.user_id],
    });

    if (offset === 0) {
      commit('set_export_list', response.export_list);

      return response.export_list;
    }

    commit('set_export_list', state.export_list.concat(response.export_list));

    return state.export_list.concat(response.export_list);
  },
};

const mutations = {
  set_export_list(state, export_list) {
    state.export_list = export_list;
  },
};

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
