import {
  axios_nomination,
} from '@/plugins/axios';

import configuration from '@/configurations';

class AiRepository {

  /**
   *
   * @param {Object} data
   * @param {String} data.homepage_url
   * @returns
   */
  async generatePropositionValue({
    homepage_url,
  }) {
    const res = await axios_nomination.post(
      `${configuration.api_host}/v1/ai/chat-gpt/generate-proposition-value`,
      {
        homepage_url,
      }
    );

    return res.data;
  }

  /**
   * TODO: this function will be used in feature when migrate analyze account on V2
   * @param {Object} data
   * @param {Number} data.account_id
   * @param {Number} data.user_id
   * @returns
   */
  async analyzeAccount({
    account_id,
    user_id,
  }) {
    const res = await axios_nomination.post(
      `${configuration.api_host}/v1/ai/chat-gpt/analyze-account`,
      {
        account_id,
        user_id,
      }
    );

    return res.data;
  }
}

const ai_repository = new AiRepository();

export {
  ai_repository,
};
