import {
  smart_mailing_repository,
} from '@/repositories';

const state = () => ({
  campaign_target_lists: [],
});

const getters = {
  campaignTargetLists: (state) => state.campaign_target_lists,
};

const actions = {

  async addTargetLists({ commit }, input) {
    const response = await smart_mailing_repository.addTargetLists(input);

    commit('set_campaign_target_list', response || []);

    return response;
  },

  async searchTargetLists({ commit }, campaign_id) {
    const response = await smart_mailing_repository.searchTargetLists(campaign_id);

    commit('set_campaign_target_list', response);

    return response;
  },
};

const mutations = {
  set_campaign_target_list(state, result_target_lists) {
    result_target_lists.forEach((result_list) => {

      const existing_list = state.campaign_target_lists.find((state_list) => {
        return state_list.campaign_id === result_list.campaign_id
          && state_list.query_id === result_list.query_id;
      });

      if (existing_list !== undefined) {
        state.campaign_target_lists[
          state.campaign_target_lists.indexOf(existing_list)
        ] = result_list;

      } else {
        state.campaign_target_lists.push(result_list);
      }
    });
  },
};

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
