export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        
      },
      "fr": {
        "business_opportunity_type_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Croissance externe"])},
        "business_opportunity_type_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Développement de l\"activité"])},
        "business_opportunity_type_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau site"])},
        "business_opportunity_type_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diversification"])},
        "business_opportunity_type_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projet marketing"])},
        "business_opportunity_type_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Développement international"])},
        "business_opportunity_type_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projet digital"])},
        "business_opportunity_type_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projet IT"])},
        "business_opportunity_type_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investissement R&D"])},
        "business_opportunity_type_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprises en difficulté"])},
        "business_opportunity_type_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recrutement et projet RH"])},
        "business_opportunity_type_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levée de fonds"])},
        "business_opportunity_type_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appel d\"offres IT"])},
        "business_opportunity_type_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appel d\"offres marketing"])},
        "business_opportunity_type_26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appel d\"offres formation"])},
        "published_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publié le"])}
      }
    }
  })
}
