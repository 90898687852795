import moment from 'moment';

import {
  search_repository,
  smart_mailing_repository,
} from '@/repositories';

import {
  CAMPAIGN_PER_PAGE_COUNT,
  CAMPAIGN_STATUS,
} from '@/constants';

const state = () => ({
  campaign_count: 0,
  campaign_data: null,
  campaign_list: [],
  campaign_statistics: null,
  check_import_targets: {
    existing_contact_count: 0,
    new_contact_count: 0,
    no_direct_mail_count: 0,
  },
  company_size_chart_data: null,
  domain_list: [],
  job_type_chart_data: null,
  sector_chart_data: null,
  sender_count: 0,
  sender_list: [],
  settings: null,

  criteria_campaign_name: '',
  criteria_date_lower_boundary: undefined,
  criteria_date_upper_boundary: undefined,
  criteria_sender_email_list: [],
  criteria_sender_id_list: [],

  limit: CAMPAIGN_PER_PAGE_COUNT,
  offset: 0,
  page: 1,
  page_type: null,
});

const getters = {
  campaignCount: (state) => state.campaign_count,
  campaignData: (state) => state.campaign_data,
  campaignList: (state) => state.campaign_list,
  campaignStatistics: (state) => state.campaign_statistics,
  checkImportTargets: (state) => state.check_import_targets,
  companySizeChartData: (state) => state.company_size_chart_data,
  domainList: (state) => state.domain_list,
  jobTypeChartData: (state) => state.job_type_chart_data,
  sectorChartData: (state) => state.sector_chart_data,
  senderCount: (state) => state.sender_count,
  senderList: (state) => state.sender_list,
  settings: (state) => state.settings,
  usedSenderList: (state) => state.used_sender_list,

  criteriaCampaignName: (state) => state.criteria_campaign_name,
  criteriaDateLowerBoundary: (state) => state.criteria_date_lower_boundary,
  criteriaDateUpperBoundary: (state) => state.criteria_date_upper_boundary,
  criteriaSenderEmailList: (state) => state.criteria_sender_email_list,
  criteriaSenderIdList: (state) => state.criteria_sender_id_list,

  limit: (state) => state.limit,
  offset: (state) => state.offset,
  page: (state) => state.page,
  pageType: (state) => state.page_type,
};

const actions = {
  async searchSettings({ commit }) {
    const result = await smart_mailing_repository.searchSettings();

    const [settings] = result.settings_list;

    commit('set_settings', settings);
  },
  async updateSettings(_, input) {
    const {
      email_footer,
      email_header,
    } = input;

    const settings = await smart_mailing_repository.updateSettings({
      email_footer,
      email_header,
    });

    return settings;
  },
  async searchSenders({ commit, rootState }) {
    const result = await smart_mailing_repository.searchSenders({
      organization_id: rootState.user.token_data.organization_id,
    });

    commit('set_sender_count', result.sender_count);
    commit('set_sender_list', result.sender_list);

    return {
      sender_count: result.sender_count,
      sender_list: result.sender_list,
    };
  },
  async createSender(_, input) {
    const {
      is_default,
      organization_id,
      reply_to_email,
      sender_email_domain_name_part,
      sender_email_local_part,
      sender_name,
    } = input;

    const sender = await smart_mailing_repository.createSender({
      is_default,
      organization_id,
      reply_to_email,
      sender_email_domain_name_part,
      sender_email_local_part,
      sender_name,
    });

    return sender;
  },
  async updateSender(_, input) {
    const {
      is_default,
      reply_to_email,
      sender_email_local_part,
      sender_id,
      sender_name,
    } = input;

    const sender = await smart_mailing_repository.updateSender({
      is_default,
      reply_to_email,
      sender_email_local_part,
      sender_id,
      sender_name,
    });

    return sender;
  },
  async deleteSender(_, input) {
    const {
      sender_id,
    } = input;

    await smart_mailing_repository.deleteSender(sender_id);
  },
  async searchDomains({ commit, rootState }) {
    const organization_id = rootState.user.token_data.organization_id;

    const result = await smart_mailing_repository.searchDomains({
      organization_id_list: [organization_id],
    });

    commit('set_domain_list', result.domain_list);
  },
  async searchCampaigns({ commit, rootState }, input) {
    const {
      id_list,
      limit,
      name,
      offset,
      owner_user_id_list,
      sender_email_list,
      sender_id_list,
      sent_date_lower_boundary,
      sent_date_upper_boundary,
      sort_list,
      status_id_list,
    } = input;

    const result = await smart_mailing_repository.searchCampaigns({
      id_list,
      limit,
      name,
      offset,
      organization_id: rootState.user.token_data.organization_id,
      owner_user_id_list,
      sender_email_list,
      sender_id_list,
      sent_date_lower_boundary,
      sent_date_upper_boundary,
      sort_list,
      status_id_list,
    });

    commit('set_campaign_count', result.campaign_count);
    commit('set_campaign_list', result.campaign_list);

    return {
      campaign_count: result.campaign_count,
      campaign_list: result.campaign_list,
    };
  },
  async createCampaign({ rootState }, input) {
    const {
      email_html_content,
      email_subject,
      mirror_link,
      mirror_link_text,
      name,
      owner_user_id,
      sender_id,
      sent_date,
      used_query_id,
    } = input;

    const campaign = await smart_mailing_repository.createCampaign({
      email_html_content,
      email_subject,
      mirror_link,
      mirror_link_text,
      name,
      organization_id: rootState.user.token_data.organization_id,
      owner_user_id,
      sender_id,
      sent_date,
      status_id: CAMPAIGN_STATUS.DRAFT.ID,
      used_query_id,
    });

    return campaign;
  },
  async updateCampaign({ commit }, input) {
    const {
      campaign_id,
      editor,
      email_footer,
      email_header,
      email_html_content,
      email_pre_header,
      email_subject,
      mirror_link,
      mirror_link_text,
      name,
      owner_user_id,
      sender_id,
      sent_date,
      status_id,
      topol_template,
      used_query_id,
    } = input;

    const campaign = await smart_mailing_repository.updateCampaign({
      campaign_id,
      editor,
      email_footer,
      email_header,
      email_html_content,
      email_pre_header,
      email_subject,
      mirror_link,
      mirror_link_text,
      name,
      owner_user_id,
      sender_id,
      sent_date,
      status_id,
      topol_template,
      used_query_id,
    });

    commit('set_campaign_data', campaign);

    return campaign;
  },
  async deleteCampaign(_, input) {
    const {
      campaign_id,
    } = input;

    await smart_mailing_repository.deleteCampaign(campaign_id);
  },
  async duplicateCampaign(_, input) {
    const {
      campaign_id,
    } = input;

    const campaign = await smart_mailing_repository.duplicateCampaign(campaign_id);

    return campaign;
  },
  async processSearch({dispatch, state }) {
    const status_id_list = [];

    if (state.page_type === 'draft') {
      status_id_list.push(CAMPAIGN_STATUS.DRAFT.ID);
    } else if (state.page_type === 'scheduled') {
      status_id_list.push(CAMPAIGN_STATUS.SCHEDULED.ID);
    } else if (state.page_type === 'sent') {
      status_id_list.push(CAMPAIGN_STATUS.SENT.ID);
    }

    const criteria = {
      limit: state.limit,
      name: state.criteria_campaign_name.length > 0
        ? state.criteria_campaign_name
        : undefined,
      offset: state.offset,
      sender_email_list: state.criteria_sender_email_list.length > 0
        ? state.criteria_sender_email_list
        : undefined,
      sender_id_list: state.criteria_sender_id_list.length > 0
        ? state.criteria_sender_id_list
        : undefined,
      sent_date_lower_boundary: state.criteria_date_lower_boundary === undefined
        ? undefined
        : moment(state.criteria_date_lower_boundary).startOf('day'),
      sent_date_upper_boundary: state.criteria_date_upper_boundary === undefined
        ? undefined
        : moment(state.criteria_date_upper_boundary).endOf('day'),
      sort_list: (state.page_type === 'sent')
        ? ['-sent_date']
        : ['-updated_at'],
      status_id_list,
    };

    return await dispatch('searchCampaigns', criteria);
  },
  async getCampaignById({ commit, dispatch }, campaign_id) {
    const criteria = {
      id_list: [campaign_id],
    };

    const result = await dispatch('searchCampaigns', criteria);

    if (result.campaign_list[0] !== undefined) {
      commit('set_campaign_data', result.campaign_list[0]);

      return result.campaign_list[0];
    }

    return null;
  },
  async getCampaignByStatusIdList({ dispatch }, status_id_list) {
    const criteria = {
      status_id_list,
      limit: 1000,
    };

    const result = await dispatch('searchCampaigns', criteria);

    return result.campaign_list;
  },
  async sendMailTest(_, input) {
    const {
      bat_email_id_list,
      campaign_id,
    } = input;

    const result = await smart_mailing_repository.sendMailTest({
      bat_email_id_list,
      campaign_id,
    });

    return result;
  },
  async searchCampaignsStatistics({ commit, rootState }, input) {
    const {
      id_list,
      limit,
      name,
      offset,
      owner_user_id_list,
      sender_email_list,
      sender_id_list,
      sent_date_lower_boundary,
      sent_date_upper_boundary,
      sort_list,
      status_id_list,
    } = input;

    const result = await smart_mailing_repository.searchCampaignsStatistics({
      id_list,
      limit,
      name,
      offset,
      organization_id: rootState.user.token_data.organization_id,
      owner_user_id_list,
      sender_email_list,
      sender_id_list,
      sent_date_lower_boundary,
      sent_date_upper_boundary,
      sort_list,
      status_id_list,
    });

    const new_statistics = result.statistics;

    Object
      .keys(new_statistics)
      .forEach((key) => {
        new_statistics[key] = (new_statistics[key] === null)
          ? 0
          : Number(new_statistics[key]);
      });

    const statistics = {
      ...new_statistics,
      campaign_count: result.campaign_count,
    };

    commit('set_campaign_statistics', statistics);

    return statistics;
  },
  async processSearchStatistics({ dispatch, state }) {
    const criteria = {
      sender_email_list: state.criteria_sender_email_list.length > 0
        ? state.criteria_sender_email_list
        : undefined,
      sender_id_list: state.criteria_sender_id_list.length > 0
        ? state.criteria_sender_id_list
        : undefined,
      sent_date_lower_boundary: state.criteria_date_lower_boundary === undefined
        ? undefined
        : moment(state.criteria_date_lower_boundary).startOf('day'),
      sent_date_upper_boundary: state.criteria_date_upper_boundary === undefined
        ? undefined
        : moment(state.criteria_date_upper_boundary).endOf('day'),
      status_id_list: [CAMPAIGN_STATUS.SENT.ID],
    };

    return await dispatch('searchCampaignsStatistics', criteria);
  },
  async checkImportTargets({ commit }, { campaign_id, contact_id_list }) {
    const check_import_targets = await smart_mailing_repository
      .checkImportTargets(campaign_id, {
        contact_id_list,
      });

    commit('set_check_import_targets', check_import_targets);
  },
  async importTargets(_, { campaign_id, contact_id_list }) {
    await smart_mailing_repository
      .importTargets(campaign_id, {
        contact_id_list,
      });
  },
  async getMetrics({ commit, state }) {
    const params = {
      limit: 0,
      metric_list: [
        'job_type_id_list',
        'company_size_id_list',
        'company_industry_class_id_list',
      ],
    };

    const target_query_id = state.campaign_data.target_query_id;
    const openers_query_id = state.campaign_data.openers_query_id;
    const clickers_query_id = state.campaign_data.clickers_query_id;

    const metric_list = await Promise
      .all([
        target_query_id !== null
          ? search_repository.getContactsActive({
            ...params,
            campaign_query_id: target_query_id,
          })
          : null,
        openers_query_id !== null
          ? search_repository.getContactsActive({
            ...params,
            campaign_query_id: openers_query_id,
          })
          : null,
        clickers_query_id !== null
          ? search_repository.getContactsActive({
            ...params,
            campaign_query_id: clickers_query_id,
          })
          : null,
      ])
      .then((data_list) => data_list.filter((data) => data !== null));

    let job_type_chart_data = {};
    let sector_chart_data = {};
    let company_size_chart_data = {};

    const buildMetrics = (final_metrics, data, metric_key, translation_key) => {
      if (Array.isArray(data)) {
        data.map((metric) => {
          if (final_metrics[metric.value] === undefined) {
            final_metrics[metric.value] = {
              clicked_email_count: 0,
              label: `${translation_key}_${metric.value}`,
              opened_email_count: 0,
              total_email_count: 0,
            };
          }

          final_metrics[metric.value][metric_key] = metric.count;
        });
      }

      return final_metrics;
    };

    metric_list.map((metric_item, index) => {
      if (metric_item === undefined) {
        return;
      }

      const metric_pack = metric_item.metric_list;

      let metric_key = null;

      if (index === 0) {
        metric_key = 'total_email_count';
      } else if (index === 1) {
        metric_key = 'opened_email_count';
      } else if (index === 2) {
        metric_key = 'clicked_email_count';
      } else {
        return;
      }

      job_type_chart_data = buildMetrics(job_type_chart_data, metric_pack.job_type_id_list, metric_key, 'job_type_id_list');
      sector_chart_data = buildMetrics(sector_chart_data, metric_pack.company_industry_class_id_list, metric_key, 'company_industry_class_id_list');
      company_size_chart_data = buildMetrics(company_size_chart_data, metric_pack.company_size_id_list, metric_key, 'company_size_id_list');
    });

    const formatChart = (data) => {
      const chart_data_formated = {
        datasets: [],
        labels: Object
          .values(data)
          .map((value) => value.label),
      };

      const values = Object.values(data);

      chart_data_formated.datasets
        .push(values.map((value) => value.opened_email_count));
      chart_data_formated.datasets
        .push(values.map((value) => value.clicked_email_count));

      return chart_data_formated;
    };

    const job_type_chart_data_formated = formatChart(job_type_chart_data);
    const sector_chart_data_formated = formatChart(sector_chart_data);
    const company_size_chart_data_formated = formatChart(company_size_chart_data);

    commit('set_job_type_chart_data', job_type_chart_data_formated);
    commit('set_sector_chart_data', sector_chart_data_formated);
    commit('set_company_size_chart_data', company_size_chart_data_formated);
  },
  async getUsedSenders({ commit}) {
    const result = await smart_mailing_repository.getUsedSenders();

    commit('set_used_sender_list', result.used_senders_list);
  },
};

const mutations = {
  set_campaign_count(state, campaign_count) {
    state.campaign_count = campaign_count;
  },
  set_campaign_data(state, campaign_data) {
    state.campaign_data = campaign_data;
  },
  set_campaign_list(state, campaign_list) {
    state.campaign_list = campaign_list;
  },
  set_campaign_statistics(state, campaign_statistics) {
    state.campaign_statistics = campaign_statistics;
  },
  set_company_size_chart_data(state, company_size_chart_data) {
    state.company_size_chart_data = company_size_chart_data;
  },
  set_domain_list(state, domain_list) {
    state.domain_list = domain_list;
  },
  set_job_type_chart_data(state, job_type_chart_data) {
    state.job_type_chart_data = job_type_chart_data;
  },
  set_sector_chart_data(state, sector_chart_data) {
    state.sector_chart_data = sector_chart_data;
  },
  set_sender_count(state, sender_count) {
    state.sender_count = sender_count;
  },
  set_sender_list(state, sender_list) {
    state.sender_list = sender_list;
  },
  set_settings(state, settings) {
    state.settings = settings;
  },
  set_used_sender_list(state, used_sender_list) {
    state.used_sender_list = used_sender_list;
  },

  set_criteria_campaign_name(state, criteria_campaign_name) {
    state.criteria_campaign_name = criteria_campaign_name;
  },
  set_criteria_date_lower_boundary(state, criteria_date_lower_boundary) {
    state.criteria_date_lower_boundary = criteria_date_lower_boundary;
  },
  set_criteria_date_upper_boundary(state, criteria_date_upper_boundary) {
    state.criteria_date_upper_boundary = criteria_date_upper_boundary;
  },
  set_criteria_sender_email_list(state, criteria_sender_email_list) {
    state.criteria_sender_email_list = criteria_sender_email_list;
  },
  set_criteria_sender_id_list(state, criteria_sender_id_list) {
    state.criteria_sender_id_list = criteria_sender_id_list;
  },

  set_limit(state, limit) {
    state.limit = limit;
  },
  set_offset(state, offset) {
    state.offset = offset;
  },
  set_page(state, page) {
    state.page = page;
  },
  set_page_type(state, page_type) {
    state.page_type = page_type;
  },

  set_check_import_targets(state, check_import_targets) {
    state.check_import_targets = check_import_targets;
  },
};

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
