export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        
      },
      "fr": {
        "press": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presse"])},
        "press_article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un article de presse"])},
        "published_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publié le"])},
        "regarding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["concernant"])},
        "see_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir son profil"])},
        "was_published_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a été publié par"])}
      }
    }
  })
}
