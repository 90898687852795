const DEFAULT_MULTI_LINE = true;
const DEFAULT_STYLE = 'success';
const DEFAULT_TIMEOUT = 3000;

const state = () => ({
  toast_active: false,
  toast_multi_line: DEFAULT_MULTI_LINE,
  toast_style: DEFAULT_STYLE,
  toast_text: '',
  toast_timeout: DEFAULT_TIMEOUT,
});

const getters = {
  toastActive: (state) => state.toast_active,
  toastMultiLine: (state) => state.toast_multi_line,
  toastStyle: (state) => state.toast_style,
  toastText: (state) => state.toast_text,
  toastTimeout: (state) => state.toast_timeout,
};

const actions = {
  triggerToast({ commit }, params) {
    commit('set_toast_multi_line', params.multi_line ? params.multi_line : DEFAULT_MULTI_LINE);
    commit('set_toast_style', params.style ? params.style : DEFAULT_STYLE);
    commit('set_toast_text', params.text);
    commit('set_toast_timeout', params.timeout ? params.timeout : DEFAULT_TIMEOUT);
    commit('set_toast_active', true);
  },
};

const mutations = {
  set_toast_active(state, toast_active) {
    state.toast_active = toast_active;
  },
  set_toast_multi_line(state, toast_multi_line) {
    state.toast_multi_line = toast_multi_line;
  },
  set_toast_style(state, toast_style) {
    state.toast_style = toast_style;
  },
  set_toast_text(state, toast_text) {
    state.toast_text = toast_text;
  },
  set_toast_timeout(state, toast_timeout) {
    state.toast_timeout = toast_timeout;
  },
};

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
