import {
  query_repository,
} from '@/repositories';

const state = () => ({
  query_list: [],
  query_list_light: [],
  criteria_organization_id_list: [],

});

const getters = {
  criteriaSequenceIdList: (state) => state.criteria_organization_id_list,
  queryList: (state) => state.query_list,
  queryListLight: (state) => state.query_list_light,
};

const actions = {
  getCriteria({ state }) {
    return {
      organization_id_list: state.criteria_organization_id_list.length > 0
        ? state.criteria_organization_id_list
        : undefined,
    };
  },

  async processSearch({ commit, dispatch }, criteria) {
    //const criteria = await dispatch('getCriteria');
    const query_list = await dispatch('searchUserQueryList', criteria);

    commit('set_query_list', query_list.query_list);
  },

  async processSearchLight({ commit, dispatch }, criteria) {
    const query_list_light = await dispatch('searchUserQueryListLight', criteria);

    commit('set_query_list_light', query_list_light.query_list);
  },

  async searchUserQueryList({ rootState }, criteria) {
    const updated_criteria = {
      limit: 1000,
      offset: 0,
      sort: ['-creation_datetime'],
      ...criteria,
    };

    const result = await query_repository.searchUserQueryList(
      rootState.user.token_data.user_id,
      updated_criteria
    );

    return result;
  },

  async searchUserQueryListLight({ rootState }, criteria) {
    const updated_criteria = {
      limit: 1000,
      offset: 0,
      sort: ['-creation_datetime'],
      ...criteria,
    };

    const result = await query_repository.searchUserQueryListLight(
      rootState.user.token_data.user_id,
      updated_criteria
    );

    return result;
  },

  async readQuery(_, query) {
    const result = await query_repository.readQuery(
      query.owner_user_id,
      query.query_id
    );

    return result;
  },

  async readQueryByIdList(_, query) {
    const result = await query_repository.readQueryByIdList(
      query.owner_user_id,
      query.query_id
    );

    return result.query_list;
  },

  createQuery(_, data) {
    return query_repository.createQuery({
      ...data,
      criteria: JSON.stringify(data.criteria),
    });
  },

  updateQuery(_, input) {
    const {
      query_id,
      data,
    } = input;

    return query_repository.updateQuery(
      query_id,
      {
        ...data,
        criteria: JSON.stringify(data.criteria),
      }
    );
  },
};

const mutations = {
  set_criteria_organization_id_list(state, criteria_organization_id_list) {
    state.criteria_organization_id_list = criteria_organization_id_list;
  },
  set_query_list(state, query_list) {
    state.query_list = query_list;
  },
  set_query_list_light(state, query_list_light) {
    state.query_list_light = query_list_light;
  },
};

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
