import {
  axios_nomination,
} from '@/plugins/axios';

import configuration from '@/configurations';

class CreditRepository {
  constructor() {
    this.calls_in_progress = {};
  }

  /**
   * @param {Object} criteria
   * @returns {Promise}
   */
  async getCreditSequenceUsers(criteria) {
    const {
      organization_id,
      subscription_last_date,
    } = criteria;

    const key = `getCreditSequenceUsers:${btoa(JSON.stringify(criteria))}`;

    if (this.calls_in_progress[key]) {
      return this.calls_in_progress[key];
    }

    this.calls_in_progress[key] = axios_nomination
      .get(
        `${configuration.api_host}/v1/credits/sequence/organization/${organization_id}/users`,
        {
          params: {
            subscription_last_date,
          },
        }
      )
      .then((response) => response.data)
      .finally(() => {
        delete this.calls_in_progress[key];
      });

    return this.calls_in_progress[key];
  }
}

const credit_repository = new CreditRepository();

export {
  credit_repository,
};
