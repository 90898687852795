<template>
  <div>
    <v-snackbar
      v-model="toast_active"
      class="toast"
      :class="toast_style"
      :timeout="toast_timeout"
    >
      {{ toast_text }}

      <template #action="{ attrs }">
        <v-btn
          icon
          v-bind="attrs"
          @click="toast_active = false"
        >
          <v-icon size="24">
            mdi-close
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'TaskElement',
  data: () => ({
    snackbar: false,
  }),
  computed: {
    ...mapGetters({
      store_toast_active: 'toast/toastActive',
      toast_style: 'toast/toastStyle',
      toast_text: 'toast/toastText',
      toast_timeout: 'toast/toastTimeout',
    }),
    toast_active: {
      get() {
        return this.store_toast_active;
      },
      set(value) {
        this.set_toast_active(value);
      },
    },
  },
  methods: {
    ...mapMutations({
      set_toast_active: 'toast/set_toast_active',
    }),
  },
};
</script>

<style lang="scss">
@import '@/constants/variables.module.scss';

.toast {
  z-index: map-get($zindex , toast);

  &.v-snackbar__wrapper {
    padding-right: 5px;
  }

  .v-snackbar__content {
    font-size: 14px;
    font-weight: 500;
  }

  .v-snackbar__btn {
    &:hover {
      border: 1px solid $white;
    }
  }

  &.success {
    .v-snackbar__wrapper {
      background-color: $jade;
    }
  }

  &.warning {
    .v-snackbar__wrapper {
      background-color: $koromiko;
    }
  }

  &.error {
    .v-snackbar__wrapper {
      background-color: $light-red;
    }
  }
}
</style>
