<template>
  <section class="quick-search">
    <div class="quick-search-container">
      <input-text-box-element
        v-model="quick_search_input"
        class="quick-search-input"
        :placeholder="$t('quick_search')"
        @change="debounce_quick_search_input(onQuickSearchInputChanged)"
        @click="hideQuickSearchResults()"
      />
      <button
        class="quick-search-icon"
        :class="{'quick-search-icon--results': show_quick_search_results}"
        @click="debounce_quick_search_input(onQuickSearchInputChanged)"
      >
        <i class="fa fa-search" />
      </button>
    </div>
  </section>

  <section
    v-show="show_quick_search_results"
    v-click-outside="hideQuickSearchResults"
    class="quick-search-results"
  >
    <v-progress-circular
      v-if="loading"
      color="#5e81f4"
      indeterminate
      size="15"
      width="2"
    />

    <div
      v-if="!loading"
      class="quick-search-results-container"
    >
      <div
        v-if="$can('read', PERMISSIONS.QUICK_SEARCH_COMPANIES)"
        class="quick-search-results-column"
      >
        <h3 class="quick-search-results-title">
          {{ $t('companies') }}
          ({{ $filters.humanizeNumber(company_list?.total) }})
        </h3>

        <div
          v-if="company_list?.total === 0"
          class="no-data"
        >
          {{ $t ('no_company') }}
        </div>

        <div
          v-if="company_list?.total > 0"
          class="cards"
        >
          <template
            v-for="(company, index) in company_list.data"
            :key="index"
          >
            <quick-search-company-card-element :company="company" />
          </template>
        </div>

        <a
          v-if="$can('read', PERMISSIONS.QUICK_SEARCH_ALL_RESULTS) && company_list?.total > company_list.data?.length"
          class="see-more-results"
          :href="getLinkToSeeAllCompanies()"
        >
          &rsaquo;
          {{ $t('see_more_companies') }}
          ({{ $filters.humanizeNumber(company_list?.total) }})
        </a>
      </div>

      <div
        v-if="$can('read', PERMISSIONS.QUICK_SEARCH_CONTACTS)"
        class="quick-search-results-column"
      >
        <h3 class="quick-search-results-title">
          {{ $t('contacts') }}
          ({{ $filters.humanizeNumber(contact_list?.total) }})
        </h3>

        <div
          v-if="contact_list?.total === 0"
          class="no-data"
        >
          {{ $t ('no_contact') }}
        </div>

        <div
          v-if="contact_list?.total > 0"
          class="cards"
        >
          <template
            v-for="(contact, index) in contact_list.data"
            :key="index"
          >
            <quick-search-contact-card-element :contact="contact" />
          </template>
        </div>

        <a
          v-if="$can('read', PERMISSIONS.QUICK_SEARCH_ALL_RESULTS) && contact_list?.total > contact_list.data?.length"
          class="see-more-results"
          :href="getLinkToSeeAllContacts()"
        >
          &rsaquo;
          {{ $t('see_more_contacts') }}
          ({{ $filters.humanizeNumber(contact_list?.total) }})
        </a>
      </div>

      <div
        v-if="$can('read', PERMISSIONS.QUICK_SEARCH_NEWS)"
        class="quick-search-results-column"
      >
        <h3 class="quick-search-results-title">
          {{ $t('news') }}
          ({{ $filters.humanizeNumber(news_list?.total) }})
        </h3>

        <div
          v-if="news_list?.total === 0"
          class="no-data"
        >
          {{ $t ('no_news') }}
        </div>

        <div
          v-if="news_list?.total > 0"
          class="cards"
        >
          <template
            v-for="(news_piece, index) in news_list.data"
            :key="index"
          >
            <quick-search-news-card-element :news_piece="news_piece" />
          </template>
        </div>

        <a
          v-if="$can('read', PERMISSIONS.QUICK_SEARCH_ALL_RESULTS) && news_list?.total > news_list.data?.length"
          class="see-more-results"
          :href="getLinkToSeeAllNews()"
        >
          &rsaquo;
          {{ $t('see_more_news') }}
          ({{ $filters.humanizeNumber(news_list?.total) }})
        </a>
      </div>

      <div
        v-if="$can('read', PERMISSIONS.QUICK_SEARCH_CONFERENCES)"
        class="quick-search-results-column"
      >
        <h3 class="quick-search-results-title">
          {{ $t('conferences') }}
          ({{ $filters.humanizeNumber(conference_list?.total) }})
        </h3>

        <div
          v-if="conference_list?.total === 0"
          class="no-data"
        >
          {{ $t ('no_conference') }}
        </div>

        <div
          v-if="conference_list?.total > 0"
          class="cards"
        >
          <template
            v-for="(conference, index) in conference_list.data"
            :key="index"
          >
            <quick-search-conference-card-element :conference="conference" />
          </template>
        </div>

        <a
          v-if="$can('read', PERMISSIONS.QUICK_SEARCH_ALL_RESULTS) && conference_list?.total > conference_list.data?.length"
          class="see-more-results"
          :href="getLinkToSeeAllConferences()"
        >
          &rsaquo;
          {{ $t('see_more_conferences') }}
          ({{ $filters.humanizeNumber(conference_list?.total) }})
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex';
import { PERMISSIONS } from '@/constants';
import configuration from '@/configurations';

import InputTextBoxElement from '@/components/elements/InputTextBoxElement.vue';
import QuickSearchCompanyCardElement from '@/components/elements/quick_search/cards/QuickSearchCompanyCardElement.vue';
import QuickSearchContactCardElement from '@/components/elements/quick_search/cards/QuickSearchContactCardElement.vue';
import QuickSearchNewsCardElement from '@/components/elements/quick_search/cards/QuickSearchNewsCardElement.vue';
import QuickSearchConferenceCardElement from '@/components/elements/quick_search/cards/QuickSearchConferenceCardElement.vue';

export default {
  name: 'QuickSearchElement',
  components: {
    InputTextBoxElement,
    QuickSearchConferenceCardElement,
    QuickSearchCompanyCardElement,
    QuickSearchContactCardElement,
    QuickSearchNewsCardElement,
  },
  data () {
    return {
      PERMISSIONS,
      company_list: [],
      conference_list: [],
      contact_list: [],
      debounce_quick_search_input: this.$filters.createDebounce(),
      loading: false,
      news_list: [],
      quick_search_input: '',
      show_quick_search_results: false,
    };
  },
  methods: {
    ...mapActions({
      getQuickSearchResults: 'quick_search/getQuickSearchResults',
      makeAQuickSearchForSkalin: 'skalin/makeAQuickSearchForSkalin',
      setShowingQuickSearchResults: 'app_layout/setShowingQuickSearchResults',
      triggerToast: 'toast/triggerToast',
    }),
    async onQuickSearchInputChanged() {
      let result = null;

      this.show_quick_search_results = false;
      this.setShowingQuickSearchResults(false);

      if (this.quick_search_input) {
        this.show_quick_search_results = true;
        this.setShowingQuickSearchResults(true);

        this.loading = true;
        result = await this.getQuickSearchResults(this.quick_search_input)
          .catch(() => 'error');
        this.makeAQuickSearchForSkalin();
      }

      if (result === 'error') {
        this.triggerToast({
          style: 'error',
          text: this.$t('quick_search_error'),
        });
      }

      this.company_list = result?.companies || [];
      this.contact_list = result?.personsActive || [];
      this.news_list = result?.news || [];
      this.conference_list = result?.conferences || [];
      this.loading = false;
    },
    getLinkToSeeAllCompanies() {
      return `${configuration.desk_v1_host}/quick-search?search=${this.quick_search_input}&view=companies`;
    },
    getLinkToSeeAllContacts() {
      return `${configuration.desk_v1_host}/quick-search?search=${this.quick_search_input}&view=active_contacts`;
    },
    getLinkToSeeAllNews() {
      return `${configuration.desk_v1_host}/business-alerts`;
    },
    getLinkToSeeAllConferences() {
      return `${configuration.desk_v1_host}/conferences`;
    },
    hideQuickSearchResults() {
      this.show_quick_search_results = false;
      this.setShowingQuickSearchResults(false);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/constants/variables.module.scss';

.card {
  margin-bottom: 8px;
}

.no-data {
  color: $light-taupe;
  font-size: 12px;
  margin-top: 7px;
}

.quick-search {
  background: $elephant;
  border-radius: 4px;
  display: flex;
  height: 30px;
  padding: 0 0 0 10px;
  margin: 0 12px;
  flex-grow: 0;
  flex-shrink: 0;
  width: 330px;
}

.quick-search-container {
  display: flex;
  width: 100%;
}

.quick-search-icon {
  flex: 0 0 30px;
  padding: 0;
  display: block;
  background: none;
  height: 30px;
  border: 0;
  text-align: center;
  color: #fff;
  outline: none;
  font-size: 14px;
  flex-shrink: 0;
  &--disabled{
      color: #ccc;
  }
  &--results {
      background: $azure-blue;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
  }
}

.quick-search-input{
  -webkit-appearance: none;
  background: none;
  border: 0;
  color: $white;
  display: block;
  flex: 0 1 100%;
  font-size: 13px;
  font-weight: 400;
  height: 30px;
  line-height: 30px;
  outline: none;
  padding: 0;

  &::placeholder {
      color: $light-gray-blue;
      font-size: 12px;
      font-style: italic;
  }
}

.quick-search-results {
  background: #fff;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  left: 0;
  padding: 30px;
  position: absolute;
  top: 50px;
  width: 100%;
}

.quick-search-results-container {
  display: flex;
  justify-content: flex-start;
}

.quick-search-results-column {
  margin: 0 10px;
  width: calc(25% - 20px);
  &:first-child {
      margin-left: 0;
  }
}

.quick-search-results-title {
  color: $gray-blue;
  font-family: 'GalanoGrotesque-SemiBold';
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  margin-bottom: 15px;
  padding: 0;
  text-transform: uppercase;
}

.see-more-results {
  color: $light-taupe;
  display: inline-block;
  font-size: 12px;
  margin-top: 7px;
  text-decoration: none;
}
</style>

<i18n src="@/locales/common.json"></i18n>

<i18n src="@/locales/components/elements/quick_search/quick-search-element.json"></i18n>
