<template>
  <v-app>
    <v-progress-linear
      :active="loading"
      :indeterminate="loading"
      absolute
      class="progress-linear"
      top
      color="primary"
      fixed
    />

    <v-main>
      <div class="main-container">
        <header-element />

        <div class="content">
          <slot />
        </div>

        <footer-element />
      </div>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';

import FooterElement from '@/components/elements/FooterElement';
import HeaderElement from '@/components/elements/HeaderElement';

export default {
  name: 'SignatureLayout',
  components: {
    FooterElement,
    HeaderElement,
  },
  computed: {
    ...mapGetters({
      loading: 'app_layout/isLoading',
    }),
  },
};
</script>

<style lang="scss" scoped>
  @import '@/constants/variables.module.scss';

.main-container {
  background-color: $whisper !important;
}
  .progress-linear {
    z-index: map-get($zindex, progress_linear);
  }

  .content {
    min-height: calc(100vh - 100px);
  }
</style>
