import {
  history_repository,
} from '@/repositories';

const actions = {
  async searchHistory(_, criteria) {
    const history_list = await history_repository.searchHistory(criteria);

    return history_list;
  },
};

export default {
  actions,
  namespaced: true,
};
