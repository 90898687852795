export default {
  api_host: process.env.VUE_APP_API_HOST,
  app_i18n_locale: process.env.VUE_APP_I18N_LOCALE,
  app_i18n_fallback_locale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
  app_version: process.env.VUE_APP_VERSION,
  base_url: process.env.VUE_APP_BASE_URL,
  cookie_domain: process.env.VUE_APP_COOKIE_DOMAIN,
  desk_v1_host: process.env.VUE_APP_DESK_V1_HOST,
  intercom_app_id: process.env.VUE_APP_INTERCOM_APP_ID,
  intercom_widget_authorized_url: [
    'app-v2.nomination.fr',
    'app.nomination.fr',
  ],
};
