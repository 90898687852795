'use strict';

import { GLOBAL } from './Global';
import permissionsTypes from '../PermissionsTypes';

const { CREATE, READ, UPDATE, DELETE } = permissionsTypes;

const CONNECTOR = {
  can: [
    ...GLOBAL.can,
    {
      action_list: [CREATE, READ, UPDATE, DELETE],
      element: 'Post',
    },
  ],
};

export {
  CONNECTOR,
};
