<template>
  <div id="footer">
    <div class="main">
      <help-popup>
        <template #trigger>
          <span class="link">{{ $t('contact_us') }}</span>
        </template>
      </help-popup>

      <a
        class="link copyright"
        href="https://www.nomination.fr/a-propos/"
        target="_blank"
      >
        Nomination © {{ current_year }}

        <div class="version">
          {{ version }}
        </div>
      </a>
    </div>

    <v-tooltip location="top">
      <template #activator="{ props }">
        <a
          class="help"
          href="http://help.nomination.fr/fr/"
          target="_blank"
          v-bind="props"
        >
          ?
        </a>
      </template>

      <span>{{ $t('help_center') }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import package_json from '@/../package.json';
import moment from 'moment';

import HelpPopup from '@/components/elements/HelpPopup.vue';

export default {
  name: 'FooterElement',
  components: {
    HelpPopup,
  },
  data: () => ({
    current_year: moment().year(),
    version: package_json.version,
  }),
};
</script>

<style lang="scss" scoped>
  @import '@/constants/variables.module.scss';

  #footer {
      align-items: center;
      background-color: $space-cadet;
      display: flex;
      position: relative;
      z-index:12;
  }

  .main {
    align-items: center;
    display: flex;
    height: 50px;
    gap: 30px;
    justify-content: flex-end;
    margin-left: auto;
    margin-right: auto;
    max-width: 1230px;
    width: 100%;
  }

  .link {
    color: $heather;
    cursor: pointer;
    font-size: 12px;
    font-weight: 400;
    text-decoration: none;
    transition: color .2s linear;

    &:hover {
      color: $white;
    }

    &.copyright {
      position: relative;

      .version {
        background-color: $white;
        border: 1px solid $space-cadet;
        border-radius: 5px;
        color: $big-stone;
        display: none;
        font-size: 12px;
        height: auto;
        left: 0;
        padding: 5px 10px;
        position: absolute;
        text-align: center;
        top: -35px;
        width: 100%;
      }

      &:hover .version {
        display: block;
      }
    }
  }

  .help {
    align-items: center;
    background-color: $affair;
    border-radius: 50%;
    bottom: 20px;
    color: $white;
    display: flex;
    font-size: 45px;
    height: 60px;
    justify-content: center;
    left: 20px;
    position: absolute;
    text-decoration: none;
    width: 60px;
  }
</style>

<i18n src="@/locales/components/elements/footer-element.json"></i18n>
