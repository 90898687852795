<template>
  <section
    v-if="!companyIsBlindedForUser(company)"
    class="quick-search-company-card"
  >
    <figure>
      <a :href="getCompanyUrl()">
        <quick-search-company-logo
          :company_id="company?.id"
        />
      </a>
    </figure>

    <div>
      <a
        :href="getCompanyUrl()"
        class="name"
      >
        {{ company?.name }}
        <span v-if="company?.group_company?.name">
          ({{ company.group_company.name }})
        </span>
        <span v-if="!company?.group_company?.name && company?.group_name">
          ({{ company.group_name }})
        </span>
      </a>

      <a
        :href="getCompanyUrl()"
        class="contacts"
      >
        {{ $filters.humanizeNumber(company?.nb_contacts) }}
        {{ $tc('contacts', { count: company?.nb_contacts }) }}
      </a>

      <div
        v-if="company?.city_name"
        class="city"
      >
        {{ company?.city_name }}
        <span v-if="isCompanyOfficeInFrance() && company?.zip_code">
          ({{ getFirstTwoDigitsOfZipCode() }})
        </span>
      </div>
    </div>
  </section>

  <section
    v-if="companyIsBlindedForUser(company)"
    class="quick-search-company-card"
  >
    <figure>
      <quick-search-company-logo
        :company_id="company?.id"
      />
    </figure>

    <div>
      <span class="name">
        {{ company?.name }}
        <span v-if="company?.group_company?.name">
          ({{ company?.group_company?.name }})
        </span>
        <span v-if="!company?.group_company?.name && company?.group_name">
          ({{ company?.group_name }})
        </span>
      </span>

      <span class="contacts">
        {{ $filters.humanizeNumber(company?.nb_contacts) }}
        {{ $tc('contacts', { count: company?.nb_contacts }) }}
      </span>

      <blinded-row-element />
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import configuration from '@/configurations';
import BlindedRowElement from '@/components/elements/BlindedRowElement.vue';
import QuickSearchCompanyLogo from '@/components/elements/quick_search/cards/images/QuickSearchCompanyLogo.vue';

const FRANCE_COUNTRY_ID = 72;

export default {
  name: 'QuickSearchCompanyCardElement',
  components: {
    BlindedRowElement,
    QuickSearchCompanyLogo,
  },
  props: {
    company: Object,
  },
  data() {
    return {
      configuration,
    };
  },
  computed: {
    ...mapGetters({
      user_has_license_sales_pme: 'user/userHasSalesPmeLicense',
    }),
  },
  methods: {
    companyIsBlindedForUser(company) {
      return this.user_has_license_sales_pme && !company?.is_visible_pme;
    },
    getCompanyUrl() {
      return `${this.configuration.desk_v1_host}/account/${this.company.id}`;
    },
    isCompanyOfficeInFrance() {
      return this.company?.country_id === FRANCE_COUNTRY_ID;
    },
    getFirstTwoDigitsOfZipCode() {
      return this.company?.zip_code?.slice(0, 2);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/constants/variables.module.scss';

.city {
  color: $light-gray-blue;
  font-size: 12px;
  font-style: italic;
  line-height: 12px;
  margin-top: 6px;
}

.contacts {
  color: $dark-blue;
  display: block;
  font-family: 'GalanoGrotesqueAlt-Light';
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 10px;
  margin-top: 6px;
  text-decoration: none;
}

.name {
  color: $azure-blue;
  display: block;
  font-family: 'GalanoGrotesqueAlt-Medium';
  font-size: 16px;
  line-height: 16px;
  text-decoration: none;
}

.quick-search-company-card {
  border: 1px solid $light-capuccino;
  border-radius: 3px;
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  padding: 15px;
  width: 100%;
}
</style>

<i18n src="@/locales/common.json"></i18n>

<i18n src="@/locales/components/elements/quick_search/quick-search-company-card-element.json"></i18n>
