<template>
  <v-tooltip>
    <template
      #activator="{ props }"
    >
      <div
        class="blinded-row"
        v-bind="props"
        :style="style"
      />
    </template>

    <div class="blinded-row-tooltip">
      <p>
        {{ $t('sentence_1') }}
      </p>

      <p>
        {{ $t('sentence_2') }}
      </p>

      <ul>
        <li>
          01 82 69 69 00
        </li>
        <li>
          serviceclient@nomination.fr
        </li>
      </ul>
    </div>
  </v-tooltip>
</template>

<script>
export default {
  name: 'BlindedRowElement',
  props: {
    width: {
      default: '100%',
      type: String,
    },
  },
  computed: {
    style() {
      let style = `width: ${this.width};`;

      return style;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/constants/variables.module.scss';

.blinded-row {
  background: $porcelain;
  background: linear-gradient(to right, $gallery 8%, $alto 18%, $gallery 33%);
  border-radius: 2px;
  height: 10px;
  margin-bottom: 10px;
  min-width: 50px;
}

.blinded-row-tooltip {
  width: 500px;
}
</style>

<i18n src="@/locales/common.json"></i18n>

<i18n src="@/locales/components/elements/blinded-row-element.json"></i18n>
