import {
  sequence_repository,
} from '@/repositories';

import {
  SEQUENCE_STATUS_CONSTANTS,
} from '@/constants';

const state = () => ({
  all_sequences_statistics: {},
  criteria_name: null,
  criteria_user_id_list: [],
  sequence_number: 0,
});

const getters = {
  allSequenceStatistics: (state) => state.all_sequences_statistics,
  criteriaName: (state) => state.criteria_name,
  criteriaUserIdList: (state) => state.criteria_user_id_list,
  sequenceNumber: (state) => state.sequence_number,
};

const actions = {
  async computeStatistics({ commit, dispatch, state, rootState }) {
    commit('set_all_sequences_statistics', {});

    const selected_status_list = [
      SEQUENCE_STATUS_CONSTANTS.ONGOING.ID,
      SEQUENCE_STATUS_CONSTANTS.PAUSED.ID,
      SEQUENCE_STATUS_CONSTANTS.FINISHED.ID,
    ];

    const user = rootState.user.token_data;

    const criteria = {
      limit: 1000,
      name: state ? state.criteria_name : undefined,
      organization_id: user.organization_id,
      owner_user_id_list: state.criteria_user_id_list.length > 0
        ? state.criteria_user_id_list
        : undefined,
      status_id_list: selected_status_list,
    };

    const sequences = await dispatch('search', criteria);

    const total = {
      mail_sent_count: 0,
      mail_clicked_count: 0,
      mail_opened_count: 0,
      mail_replied_count: 0,
      mail_bounced_count: 0,
      task_created_count: 0,
      task_done_count: 0,
      task_late_count: 0,
      task_delete_count: 0,
      mail_to_send_count: 0,
      task_to_create_count: 0,
      finished_sequences: 0,
      paused_and_ongoing_sequences: 0,
    };

    sequences.forEach((sequence) => {
      total.mail_sent_count += sequence.mail_sent_count;
      total.mail_clicked_count += sequence.mail_clicked_count;
      total.mail_opened_count += sequence.mail_opened_count;
      total.mail_replied_count += sequence.mail_replied_count;
      total.mail_bounced_count += sequence.mail_bounced_count;
      total.task_created_count += sequence.task_created_count;
      total.task_done_count += sequence.task_done_count;
      total.task_late_count += sequence.task_late_count;
      total.task_delete_count += sequence.task_delete_count;
      total.mail_to_send_count += sequence.mail_to_send_count;
      total.task_to_create_count += sequence.task_to_create_count;

      if (
        sequence.status_id === SEQUENCE_STATUS_CONSTANTS.ONGOING.ID
        || sequence.status_id === SEQUENCE_STATUS_CONSTANTS.PAUSED.ID
      ) {
        total.paused_and_ongoing_sequences += 1;
      }

      if (sequence.status_id === SEQUENCE_STATUS_CONSTANTS.FINISHED.ID) {
        total.finished_sequences += 1;
      }
    });

    total.finished_sequences_percentage = Math
      .round(
        total.finished_sequences / (
          total.finished_sequences + total.paused_and_ongoing_sequences
        ) * 100
      );

    total.paused_and_ongoing_sequences_percentage = 100 - total.finished_sequences_percentage;

    total.mail_sent_percentage = Math
      .round(total.mail_sent_count / (total.mail_sent_count + total.mail_to_send_count) * 100);

    total.mail_to_send_percentage = 100 - total.mail_sent_percentage;

    total.task_created_percentage = Math
      .round(total.task_created_count / (total.task_created_count + total.task_to_create_count)
      * 100);

    total.task_to_create_percentage = 100 - total.task_created_percentage;

    commit('set_all_sequences_statistics', total);
  },

  async search( { commit }, criteria) {
    const result = await sequence_repository.searchSequences(criteria);

    commit('set_sequence_number', result.sequence_count);

    return result.sequence_list;
  },
};

const mutations = {
  set_all_sequences_statistics(state, all_sequences_statistics) {
    state.all_sequences_statistics = all_sequences_statistics;
  },
  set_criteria_name(state, criteria_name) {
    state.criteria_name = criteria_name;
  },
  set_criteria_user_id_list(state, criteria_user_id_list) {
    state.criteria_user_id_list = criteria_user_id_list;
  },
  set_sequence_number(state, sequence_number) {
    state.sequence_number = sequence_number;
  },

};

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
