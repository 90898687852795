import {
  INQUIRY_CONSTANTS,
} from '@/constants';

import {
  customer_inquiry_repository,
  search_repository,
} from '@/repositories';

const state = () => ({
  customer_inquiry_count: 0,
  customer_inquiry_list: [],
  inquiry_type_id_list: [],
  page: 1,
  quick_search: '',
  status_id_list: [],
  user_has_never_submitted_any_inquiry: false,
});

const getters = {
  customerInquiryCount: (state) => state.customer_inquiry_count,
  customerInquiryList: (state) => state.customer_inquiry_list,
  inquiryTypeIdList: (state) => state.inquiry_type_id_list,
  page: (state) => state.page,
  quickSearch: (state) => state.quick_search,
  statusIdList: (state) => state.status_id_list,
  userHasNeverSubmittedAnyInquiry: (state) => state.user_has_never_submitted_any_inquiry,
};

const actions = {
  async searchCustomersInquiry({ commit, state }, { user_id }) {
    const criteria = {
      limit: INQUIRY_CONSTANTS.INQUIRY_PER_PAGE_COUNT,
      offset: (state.page * INQUIRY_CONSTANTS.INQUIRY_PER_PAGE_COUNT) - INQUIRY_CONSTANTS
        .INQUIRY_PER_PAGE_COUNT,
      sort_list: ['-creation_date'],
      user_id_list: [user_id],
    };

    if (state.inquiry_type_id_list.length > 0) {
      criteria.inquiry_type_id_list = state.inquiry_type_id_list;
    }

    if (state.quick_search.length > 0) {
      criteria.quick_search = state.quick_search;
    }

    if (state.status_id_list.length > 0) {
      criteria.status_id_list = [];

      if (state.status_id_list.includes(INQUIRY_CONSTANTS.STATUS_ID.TODO)) {
        criteria.status_id_list.push(INQUIRY_CONSTANTS.STATUS_ID.TODO);
        criteria.status_id_list.push(INQUIRY_CONSTANTS.STATUS_ID.DRAFT);
      }

      if (state.status_id_list.includes(INQUIRY_CONSTANTS.STATUS_ID.IN_PROGRESS)) {
        criteria.status_id_list.push(INQUIRY_CONSTANTS.STATUS_ID.IN_PROGRESS);
        criteria.status_id_list.push(INQUIRY_CONSTANTS.STATUS_ID.PRE_DONE);
      }

      if (state.status_id_list.includes(INQUIRY_CONSTANTS.STATUS_ID.DONE)) {
        criteria.status_id_list.push(INQUIRY_CONSTANTS.STATUS_ID.DONE);
      }

      if (state.status_id_list.includes(INQUIRY_CONSTANTS.STATUS_ID.PARTIALLY_DONE)) {
        criteria.status_id_list.push(INQUIRY_CONSTANTS.STATUS_ID.PARTIALLY_DONE);
      }

      if (state.status_id_list.includes(INQUIRY_CONSTANTS.STATUS_ID.FAIL)) {
        criteria.status_id_list.push(INQUIRY_CONSTANTS.STATUS_ID.FAIL);
      }
    }

    const response = await customer_inquiry_repository.searchCustomersInquiry(criteria);

    if (response.customer_inquiry_count === 0) {
      commit('set_customer_inquiry_count', response.customer_inquiry_count);
      commit('set_customer_inquiry_list', response.customer_inquiry_list);
      commit('set_page', 1);

      if (state.status_id_list.length === 0) {
        commit('set_user_has_never_submitted_any_inquiry', true);
      }

      return response;
    }

    const { account_list } = await search_repository.getAccountsAll({
      company_id_list: response.customer_inquiry_list
        .map((customer_inquiry) => customer_inquiry.company_id)
        .filter((company_id) => company_id !== null),
    });

    const { contact_list } = await search_repository.getContactsAll({
      contact_id_list: response.customer_inquiry_list
        .map((customer_inquiry) => customer_inquiry.contact_id)
        .filter((contact_id) => contact_id !== null),
    });

    const getCompanyData = (company_id) => {
      const company = account_list.find((contact) => contact.id === company_id);

      return (company === undefined)
        ? null
        : {
          description: (company.industry_type_list === null)
            ? ''
            : company.industry_type_list
              .find((industry_type) => industry_type.position === 1)
              .name,
          id: company_id,
          name: company.name,
        };
    };

    const getContactData = (contact_id) => {
      const contact = contact_list.find((contact) => contact.id === contact_id);

      if (contact_id == null) {
        return null;
      }

      return (contact === undefined)
        ? INQUIRY_CONSTANTS.OPTED_OUT
        : {
          first_name: contact.first_name,
          id: contact_id,
          civility_id: contact.civility.id,
          job_title: contact.job_list
            ? contact.job_list.find((job) => job.main && job.active)
              ?.name || null
            : null,
          last_name: contact.last_name,
        };
    };

    response.customer_inquiry_list = response.customer_inquiry_list.map((customer_inquiry) => ({
      ...customer_inquiry,
      data: {
        company: getCompanyData(customer_inquiry.company_id),
        contact: getContactData(customer_inquiry.contact_id),
        inquiry_type: {
          id: customer_inquiry.inquiry_type_id,
          sub_type_id: customer_inquiry.inquiry_sub_type_id,
        },
      },
    }));

    commit('set_customer_inquiry_count', response.customer_inquiry_count);
    commit('set_customer_inquiry_list', response.customer_inquiry_list);
    commit('set_page', 1);

    return response;
  },
};

const mutations = {
  set_customer_inquiry_count(state, customer_inquiry_count) {
    state.customer_inquiry_count = customer_inquiry_count;
  },
  set_customer_inquiry_list(state, customer_inquiry_list) {
    state.customer_inquiry_list = customer_inquiry_list;
  },
  set_inquiry_type_id_list(state, inquiry_type_id_list) {
    state.inquiry_type_id_list = inquiry_type_id_list;
  },
  set_page(state, page) {
    state.page = page;
  },
  set_quick_search(state, quick_search) {
    state.quick_search = quick_search;
  },
  set_status_id_list(state, status_id_list) {
    state.status_id_list = status_id_list;
  },
  set_user_has_never_submitted_any_inquiry(state, boolean) {
    state.user_has_never_submitted_any_inquiry = boolean;
  },
};

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
