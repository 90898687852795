'use strict';

const TASK = {
  TYPE : {
    PHONE: 1,
    LINKEDIN: 2,
    MAIL: 3,
    OTHER: 4,
  },
  DESCRIPTION_MAX_LENGTH: 255,
};

const TASK_CONSTANTS = {
  TASK,
};

export {
  TASK_CONSTANTS,
};
